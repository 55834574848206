import React from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import "./styles.css"

const Product = () => {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },

      {
        label: "Category",
        field: "category1",
        width: 150,
      },
      {
        label: "Code",
        field: "Code1",
        width: 150,
      },
      {
        label: "Parent Category",
        field: "category2",
        width: 150,
      },
      // {
      //   label: "Code",
      //   field: "Code2",
      //   width: 150,
      // },

      // {
      //   label: "Actions",
      //   field: "options",
      //   sort: "desc",
      //   width: 400,
      // },
    ],
    rows: [
      {
        id: "1",
        Code1: "1",
        Code2: "1",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        category1: "deposit",
        category2: "Saving Account",
        Name: "Saving General Account",

        Description: " smot docs ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            {/* <i
                        className="uil-edit-alt"
                        style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                        }}

                    ></i> */}
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        Code1: "1",
        Code2: "1",
        Name: "Yuva Yojana",
        category1: "deposit",
        category2: "Saving Account",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        Description: "smot tours ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        Code1: "1",
        Code2: "1",
        Name: "lok bachat youjana",
        category1: "deposit",
        category2: "Saving Account",
        Description: "smot study",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "Sham",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Product Category" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Category</Label>
                          <AvField
                            name="Domain"
                            className="form-control"
                            type="text"
                            placeholder="Category "
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Code</Label>
                          <AvField
                            name="Description"
                            className="form-control"
                            type="text"
                            placeholder="Code"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Parent Category</Label>
                          <Select
                            name="Domain"
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "15px" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="productcategoryid"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default Product
