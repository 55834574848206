import React from "react"
import { Navigate } from "react-router-dom"

// Dashboard
import MarketingDashboard from "../pages/Dashboard/index"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Users
import User from "../pages/User"
import UserDashboard from "../pages/User/UserDashboard"

// Manage
import Designation from "../pages/Manage/Designation/ManageDesignation"
import Department from "../pages/Manage/Department/Department"
import Template from "../pages/Manage/Template/Template"

// Master Settings
import Privilage from "../pages/MasterSettings/Privilage"
import Modules from "../pages/MasterSettings/Modules"
import Rules from "../pages/MasterSettings/Rules"
import Company from "../pages/MasterSettings/Company"
import Branch from "../pages/MasterSettings/Branch"

// Security
import BlockedIp from "../pages/Security/BlockedIp"
import UserActivity from "../pages/Security/UserActivity"

// Social Listening
import SocialListening from "../pages/Social Listening"

// GMB
import GMB from "../pages/GMB/Googlemybussiness"

// Reviews
import Reviews from "../pages/Social/Reviews/Reviews"
import Reviewreply from "../pages/Social/Reviews/Reviewreply"

// // Response
// import FollowUp from "../pages/Response/FollowUp/followUp"
// import NewResponse from "../pages/Response/NewResponse/NewResponse"
// import AllResponse from "../pages/Response/AllResponse/allResponse"

// Reports
import DashboardSummary from "../pages/Reports/dashboardSummary"
import FollowersSummary from "../pages/Reports/followersSummary"
import SentimentSummary from "../pages/Reports/sentimentSummary"
import UniqueUsersReport from "../pages/Reports/uniqueUsersReport"
import VolumeSummary from "../pages/Reports/volumeSummary"
import MediaPlatformFacebook from "../pages/Reports/MediaPlatformFacebook"
import MediaPlatformInstagram from "../pages/Reports/MediaPlatformInstagram"
import MediaPlatformTwitter from "../pages/Reports/MediaPlatformTwitter"

// Notification
import Notification from "../pages/Notifications"
import SocialConfig from "../pages/MasterSettings/Social-Config"

// Manage
import Branchmanage from "../pages/Manage/Branch/Branch"
import Zonemanage from "../pages/Manage/Zone/Zone"
import ProductCategory from "../pages/Manage/ProductCategory"
import Product from "../pages/Manage/Product"

// Responses
import AllResponse from "../pages/NewResponse/AllResponse"
import NewResponse from "../pages/NewResponse/NewResponse"
import PendingResponse from "../pages/NewResponse/PendingResponse"
import ClosedResponse from "../pages/NewResponse/ClosedResponse"

// Feedback from
import Latestfeedback from "../pages/FeedBack/feedbacks"
import FeedBackQuestions from "../pages/FeedBack/FeedBackQuestions"
import Graphicalreport from "../pages/FeedBack/FeedBack-Reports"

// Content
import Messages from "../pages/Content/Messages/Messages"
import Newpost from "../pages/Content/Posts/Newpost"
import Allpost from "../pages/Content/Posts/Allpost"
import Socialmonitor from "../pages/Content/Socialmonitor/Socialmonitor"
// Campiagn
import Campaign from "../pages/Campaign/Campaign"
import CampaignDashboard from "../pages/Campaign/CampaignDashboard/CampaignDashboard"
import TrackClicks from "../pages/Campaign/TrackClicks"
// Leads
import Leads1 from "../pages/Leads/Leads/Leads1"
import Newlead from "../pages/Leads/NewLead/Newlead"
import TrashedLeads from "../pages/Leads/TrashedLeads/TrashedLeads"
import Followup from "../pages/Leads/Followup/Followup"
import Survey from "../pages/Survey/Survey"
import AddSurvey from "../pages/Survey/Add-Survey"
import Surveyquestions from "../pages/Survey/Survey-questions"
import SurveyReports from "../pages/Survey/Survey-reports"
import Calender from "../pages/Calender"
//Own Pages
const userRoutes = [
  //dahshboard
  { path: "/dashboard", component: <MarketingDashboard /> },
  {
    path: "/social-dashboard",
    component: <MarketingDashboard name="Leads" />,
  },
  { path: "/hrms-dashboard", component: <MarketingDashboard name="HRMS" /> },
  {
    path: "/accounts-dashboard",
    component: <MarketingDashboard name="Accounts" />,
  },
  {
    path: "/feedback-dashboard",
    component: <MarketingDashboard name="Feedback" />,
  },
  //Utility
  { path: "/pages", component: <PagesStarter /> },

  // Users
  { path: "/user", component: <User /> },
  { path: "/user/:user_id", component: <UserDashboard /> },

  // Manage
  { path: "/manage_designation", component: <Designation /> },
  { path: "/manage_department", component: <Department /> },
  { path: "/manage_template", component: <Template /> },
  { path: "/manage_branch", component: <Branchmanage /> },
  { path: "/manage_zone", component: <Zonemanage /> },
  { path: "/manage_product_category", component: <ProductCategory /> },
  { path: "/manage_product", component: <Product /> },

  // notifications
  { path: "/notifications", component: <Notification /> },

  // Master Settings
  { path: "/privilage", component: <Privilage /> },
  { path: "/rules", component: <Rules /> },
  { path: "/modules", component: <Modules /> },
  { path: "/company", component: <Company /> },
  { path: "/branch", component: <Branch /> },
  { path: "/social-config", component: <SocialConfig /> },

  // Security
  { path: "/user-activity", component: <UserActivity /> },
  { path: "/blocked-ip", component: <BlockedIp /> },

  // Social Listening
  { path: "/social_listening", component: <SocialListening /> },

  // GMB
  { path: "/google_my_business", component: <GMB /> },

  // Feedbacks
  { path: "/latest_feedback", component: <Latestfeedback /> },
  { path: "/feedback_questions", component: <FeedBackQuestions /> },
  { path: "/feedback_reports", component: <Graphicalreport /> },

  // Content
  { path: "/message", component: <Messages /> },
  { path: "/newpost", component: <Newpost /> },
  { path: "/allpost", component: <Allpost /> },
  { path: "/monitor", component: <Socialmonitor /> },

  // Campaign
  { path: "/campaign", component: <Campaign /> },
  { path: "/campaign-dashboard", component: <CampaignDashboard /> },
  { path: "/track-clicks", component: <TrackClicks /> },


  // Leads
  { path: "/add-lead", component: <Leads1/> },
  { path: "/leads", component: <Newlead/> },
  { path: "/follow-up", component: <Followup/> },
  { path: "/trashed-leads", component: <TrashedLeads/> },

//Survey 
{ path: "/survey", component: <Survey/> },
{ path: "/add-survey", component: <AddSurvey/> },
{ path: "/survey-questions", component: <Surveyquestions/> },
{ path: "/survey-reports", component: <SurveyReports/> },

  // Reviews
  { path: "/reviews", component: <Reviews /> },
  { path: "/reviews/replyreview", component: <Reviewreply /> },

  // // Response
  // { path: "/response_followup", component: <FollowUp /> },
  // { path: "/new_response", component: <NewResponse /> },
  // { path: "/all_response", component: <AllResponse /> },

  // Reports
  { path: "/reports-summary", component: <DashboardSummary /> },
  { path: "/reports-followers", component: <FollowersSummary /> },
  { path: "/reports-sentiment", component: <SentimentSummary /> },
  { path: "/reports-unique-users", component: <UniqueUsersReport /> },
  { path: "/reports-volume", component: <VolumeSummary /> },


  { path: "/calender", component: <Calender /> },

  { path: "/all_response", component: <AllResponse /> },
  { path: "/new_response", component: <NewResponse /> },
  { path: "/pending_response", component: <PendingResponse /> },
  { path: "/closed_response", component: <ClosedResponse /> },
  {
    path: "/reports-media-platform-facebook",
    component: <MediaPlatformFacebook />,
  },
  {
    path: "/reports-media-platform-instagram",
    component: <MediaPlatformInstagram />,
  },
  {
    path: "/reports-media-platform-twitter",
    component: <MediaPlatformTwitter />,
  },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
  {
    path: "*",
    component: <Navigate to={sessionStorage.getItem("module_url")} />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
