import React from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "./style.scss"
function SocialConfig() {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "Time",
        width: 150,
      },
      {
        label: "Platform",
        field: "platform",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        Code: "SD4MOT",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        status: (
          <>
            <Button
              color="danger"
              style={{ padding: "5px 5px", cursor: "pointer" }}
              type="submit"
            >
              Disconnect
            </Button>
          </>
        ),
        Name: "SMOT DOCS",
        platform: "Facebook",
        Description: " smot docs ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            {/* <i
                        className="uil-edit-alt"
                        style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                        }}

                    ></i> */}
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        status: "",

        Code: "ST2MOT",
        Name: "SMOT TOURS",
        date: "24-10-2022",
        Time: "12:30pm",
        platform: "Instagram",
        status: (
          <>
            <Button
              color="success"
              style={{ padding: "5px 5px", cursor: "pointer" }}
              type="submit"
            >
              Connect
            </Button>
          </>
        ),
        User: "admin",
        Description: "smot tours ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        status: "",
        status: (
          <>
            <Button
              color="danger"
              style={{ padding: "5px 5px", cursor: "pointer" }}
              type="submit"
            >
              Disconnect
            </Button>
          </>
        ),
        Code: "SE5MOT",
        Name: "SMOT STUDY",
        Description: "smot study",
        platform: "twitter",

        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Configuration" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default SocialConfig
