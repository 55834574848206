import { takeEvery, put, call } from "redux-saga/effects"

import { GET_INTERACTIONS } from "./actionTypes"

import { getAllInteractionsSuccess, getAllInteractionsFail } from "./actions"

import { getAllInteractions } from "../../helpers/backend_helper"

function* fetchInteractions(data) {
  try {
    const response = yield call(getAllInteractions, data)
    yield put(getAllInteractionsSuccess(response))
  } catch (error) {
    yield put(getAllInteractionsFail(error))
  }
}

function* interactionSaga() {
  yield takeEvery(GET_INTERACTIONS, fetchInteractions)
}

export default interactionSaga
