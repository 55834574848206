import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MDBDataTable } from "mdbreact"

import axios from "axios"
import toastr from "toastr"

import SweetAlert from "react-bootstrap-sweetalert"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap"

import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import "./ManageDesignation.scss"
import { Grid } from "@mui/material"

import accessToken from "../../../helpers/jwt-token-access/accessToken"

import moment from "moment"

const Designation = () => {
  const [designationObject, setDesignationObject] = useState({})
  const [designationIdTobeUpdated, setDesignationIdToBeUpdated] = useState(null)
  const [designationIdToBeDeleted, setDesignationIdToBeDeleted] = useState(null)
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null)
  const [designationForTable, setDesignationForTable] = useState([])
  const [designationValue, setdesignationValue] = useState("")

  // const {
  //   districts,
  //   addingDesignation,
  //   // addDistrictResponse,
  //   // deleteDistrictResponse,
  //   // updateDistrictResponse,
  //   error,
  // } = useSelector(state => state.districts)

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
  const formRef = useRef()
  useEffect(() => {
    handleTableData()
  }, [])

  function handleTableData() {
    var url = `${API_URL}designation/list`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        var result = res.data.data
        let designationData = []

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop()
                  preUpdateDesignation(item)
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setDesignationIdToBeDeleted(item._id)
                  setConfirmDeleteAlert(true)
                }}
              ></i>
            </div>
          )
          item.id = index + 1
          // item.date = moment(item.designation_date).format("DD-MM-YYYY");
          //  item.time = moment(item.designation_time, "HHmmss").format("hh:mm a");
          if (item?.up_date) {
            item.date = moment(item.up_date).format("DD-MM-YYYY")
          } else {
            item.date = moment(item.date).format("DD-MM-YYYY")
          }

          if (item?.up_time) {
            item.time = moment(item.up_time, "HHmmss").format("hh:mm a")
          } else {
            item.time = moment(item.time, "HHmmss").format("hh:mm a")
          }

          // let values = item?.addedby || {};
          // if (values?.lastName)
          //   item.staff = values.firstName + " " + values.lastName;
          // else item.staff = values?.firstName;

          if (item?.updatedby) {
            let values = item?.updatedby || {}
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName
            else item.staff = values?.firstName
          } else {
            let values = item?.addedby || {}
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName
            else item.staff = values?.firstName
          }

          designationData.push(item)
        })
        setDesignationForTable(designationData)
      })
  }

  let preUpdateDesignation = item => {
    setdesignationValue(item?.name)

    setDesignationIdToBeUpdated(item._id)
    setDesignationObject(item)
  }

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Date",
      //   field: "date",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Designation Name",
        field: "desig_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Code",
        field: "shortCode",
        sort: "asc",
        width: 150,
      },
      {
        label: "Level",
        field: "level",
        sort: "asc",
        width: 150,
      },

      // {
      //   label: "Staff",
      //   field: "staff",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: designationForTable,
  }

  function handleChangeDesignation(e) {
    let name = e.target.name
    let value = e.target.value
    setdesignationValue(value)
    setDesignationObject({ ...designationObject, [name]: value })
  }

  const handleValidSubmit = () => {
    if (designationIdTobeUpdated) {
      // let bodydata = {
      //   id: designationIdTobeUpdated,
      //   name: designationObject.name,
      // };

      axios
        .put(`${API_URL}designation`, designationObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then(res => {
          if (res.data.code === 200) {
            toastr.success("Designation updated successfully")
            setdesignationValue("")
            formRef.current.reset()
            handleTableData()
            setDesignationObject({})
            setDesignationIdToBeUpdated(null)
          } else {
            toastr.error("Failed to update Designation")
            return
          }
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    } else {
      axios
        .post(`${API_URL}designation`, designationObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then(res => {
          if (res.data.code === 200) {
            toastr.success("Designation added succesfully")
            handleTableData()
            setdesignationValue("")
            formRef.current.reset()
            setDesignationObject({})
          } else {
            toastr.error("Failed to add Designation")
            return
          }
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          return
        })
    }
  }

  const customStyles = {
    lineHeight: "1.8",
  }

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(
                  `${API_URL}designation` + "/" + designationIdToBeDeleted,
                  {
                    headers: {
                      "x-access-token": accessToken,
                    },
                  }
                )
                .then(res => {
                  if (res.data.code === 200) {
                    toastr.success("Designation deleted successfully")
                    if (
                      designationObject &&
                      designationObject._id === designationIdToBeDeleted
                    ) {
                      setdesignationValue("")
                      formRef.current.reset()
                      setDesignationObject({})
                      setDesignationIdToBeUpdated(null)
                    }
                    handleTableData()
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Designation"
                    )
                    return
                  }
                })
              setConfirmDeleteAlert(false)
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Designation" />

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <Row>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Designation
                            </Label>
                            <AvField
                              style={customStyles}
                              name="desig_name"
                              placeholder="Designation name"
                              type="text"
                              errorMessage="Enter Designation Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={designationObject?.desig_name}
                              onChange={handleChangeDesignation}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Short Code
                            </Label>
                            <AvField
                              style={customStyles}
                              name="shortCode"
                              placeholder="Short Code"
                              type="text"
                              errorMessage="Enter Short Code"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={designationObject?.shortCode}
                              onChange={handleChangeDesignation}
                            />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="mb-2">
                            <Label htmlFor="validationCustom01">Level</Label>
                            <AvField
                              style={customStyles}
                              name="level"
                              placeholder="Level"
                              type="text"
                              errorMessage="Enter Level"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={designationObject?.level}
                              onChange={handleChangeDesignation}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-2" style={{ paddingTop: "25px" }}>
                            {designationIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                // disabled={addingDesignation ? true : false}
                              >
                                {/* {addingDesignation ? "Updating" : "Update"} */}
                                Update
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                style={{ marginRight: "5%" }}
                                // disabled={addingDesignation ? true : false}
                              >
                                {/* {addingDesignation ? "Adding" : "Submit"} */}
                                Submit
                              </Button>
                            )}
                            <Button color="danger" type="submit">
                              {"  "}
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                    {/* <CardTitle>Staff & their Calculated Salary</CardTitle>
                    <hr /> */}

                    {/* <Row>
                      <Col className="col-2" style={{ paddingTop: "4px" }}>
                        <div className="mb-2">
                          <Button
                            color="primary"
                            type="submit"
                            style={{ float: "right" }}
                          >
                            
                            {"  "}
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <MDBDataTable
                      id="designationTableId"
                      responsive
                      bordered
                      data={data}
                      searching={true}
                      info={true}
                      disableRetreatAfterSorting={true}
                      entries={20}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  )
}

export default Designation
