import React from "react"
import { Bar } from "react-chartjs-2"

const BarChart = props => {
  const data = {
    labels: ["BOM"],
    datasets: [
      {
        label: props.label,
        backgroundColor: props.color,
        borderColor: props.color,
        borderWidth: 1,
        hoverBackgroundColor: props.color,
        hoverBorderColor: "#3c4ccf",
        data: [65, 59, 81, 45, 56, 80, 50, 20],
      },
    ],
  }

  const option = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.4,
        },
      ],
    },
  }

  return <Bar width={474} height={300} data={data} options={option} />
}

export default BarChart
