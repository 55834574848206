import React from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
// import "./ManageZone.scss"
function Branchmanage() {
    const customStyles = {
        lineHeight: "1.8",
    };

const data = {
    columns: [
        {
            label: "#",
            field: "id",
            width: 150,
        },
        {
            label: "Branch Code",
            field: "BranchCode",
            width: 150,
        },

        {
            label: "Branch",
            field: "Branch",
            width: 150,
        },
        {
            label: "Zone",
            field: "Zone",
            width: 150,
        },
        {
            label: "State",
            field: "State",
            width: 150,
        },
       
        {
            label: "District",
            field: "District",
            width: 150,
        },
        // {
        //     label: "Link",
        //     field: "Link",
        //     sort: "desc",
        //     width: 400,
        // },

    ],
    rows: [
        {
            id: "1",
            BranchCode: "BOM103",
            Branch: "Kerala-kannur",
            State: "Kerala",
            District: "Kannur",
            Link:"http://Smt.smotprosocialmedia.com:90/b/kqfs",
            Zone:"Kannur",
           
           
        },
        {
            id: "2",
            BranchCode: "BOM103",
            Branch: "Kerala-kannur",
            State: "Kerala",
            District: "Kannur",
            Link:"http://Smt.smotprosocialmedia.com:90/b/kqfs",
            Zone:"Kannur",
           
        },
        {
            id: "3",
            BranchCode: "BOM103",
            Branch: "Kerala-kannur",
            State: "Kerala",
            District: "Kannur",
            Link:"http://Smt.smotprosocialmedia.com:90/b/kqfs",
            Zone:"Kannur",
           
        },
        {
            id: "4",
            BranchCode: "BOM103",
            Branch: "Kerala-kannur",
            State: "Kerala",
            District: "Kannur",
            Link:"http://Smt.smotprosocialmedia.com:90/b/kqfs",
            Zone:"Kannur",
           
        },
        {
            id: "5",
            BranchCode: "BOM103",
            Branch: "Kerala-kannur",
            State: "Kerala",
            District: "Kannur",
            Link:"http://smt.smotprosocialmedia.com:90/b/kqfs",
            Zone:"Kannur",
           
        },
        
    ],
};
return (
    <>
        <div className='page-content'>
            <div className='container-fluid'>
                <Breadcrumbs title="Home" breadcrumbItem="Branch List" />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <AvForm>
                                    <Row>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Label >Zone/Region</Label>
                                                <Select
                                                 style={customStyles}
                                                    name="feedbackform"
                                                    options={[
                                                        {
                                                            label: "Kerala",
                                                            value: 0,
                                                        },
                                                        {
                                                            label: "Karnataka",
                                                            value: 0,
                                                        },
                                                        
                                                    ]}
                                                    type="text"
                                                    placeholder=""
                                                    errorMessage="Enter Documents"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Label >State</Label>
                                                <Select
                                                    options={[
                                                        {
                                                            label: "kerala",
                                                            value: 0,
                                                        },
                                                        {
                                                            label: "karnataka",
                                                            value: 0,
                                                        },
                                                    ]}

                                                 

                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="mb-3">
                                                <Label >District</Label>
                                                <Select
                                                    options={[
                                                        {
                                                            label: "kannur",
                                                            value: 0,
                                                        },
                                                        {
                                                            label: "calicut",
                                                            value: 0,
                                                        },
                                                    ]}

                                                 

                                                    classNamePrefix="select2-selection"
                                                />
                                            </div>
                                        </Col>
                                        
                                        <Col md="3">
                                            <div className="mb-3">
                                                <Label >Search</Label>
                                                <AvField
                                                style={customStyles}
                                                    name="feedbacktitle"
                                                    className="form-control"
                                                    type="text"
                                                    placeholder=""
                                                    errorMessage="Enter Documents"
                                                />
                                            </div>
                                        </Col>
                                        
                                        

                                        <Col md="3" >
                                            <div className="mb-3" style={{ paddingTop: "28px" }}>
                                                <Button color="success" style={{ marginRight: "10px" ,padding:"8px 30px" }} type="submit">
                                                    Search
                                                </Button>

                                                <Button style={{padding:"8px 30px" }} color="danger" type="submit">
                                                    Reset
                                                </Button>

                                            </div>
                                        </Col>


                                    </Row>
                                  
                                </AvForm>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>


                <Row>
                    <Col className='col-12'>

                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    id="ManageZoneId"
                                    bordered
                                    responsive
                                    searching={true}
                                    entries={20}
                                    disableRetreatAfterSorting={true}
                                    data={data}

                                >

                                </MDBDataTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>



            </div>
        </div>
    </>
)
}

export default Branchmanage