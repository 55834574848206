import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function UniqueUsersReport() {
  const [uniqueUsersCount, setUniqueUsersCount] = useState();

  function fetchUniqueUsersCount() {
    let fromDate = moment(startDate).format("YYYY-MM-DD");
    let ToDate = moment(endDate).format("YYYY-MM-DD");
    var url = `${API_URL}report/v2/uniqueUsers/count?fromDate=${fromDate}&toDate=${ToDate}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res?.data?.data;
        setUniqueUsersCount(result);
      });
  }

  useEffect(() => {
    fetchUniqueUsersCount();
  }, []);

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };

    return new Date(date).toLocaleString("en-US", options);
  };

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [showFullMonth, setShowFullMonth] = useState(false);

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth);
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Unique users Report</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row>
            {uniqueUsersCount?.map((item) => (
              <Col md="2">
                <Card>
                  <CardBody>
                    <h2 className="usersTitle">
                      <i class={"fab"}></i>
                      {item.socialMedia}
                    </h2>{" "}
                    <br />
                    <h3 className="usersCount">{item.uniqueAuthorCount}</h3>
                  </CardBody>
                </Card>
              </Col>
            ))}

            <Col md="12">
              <div style={{ textAlign: "end" }}>
                <Button color="primary">Download</Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default UniqueUsersReport;
