import React from "react"
import MiniWidget from "./mini-widget"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Col, Container, Row } from "reactstrap"
import Chartapex from "./chartapex"
import { Card, CardBody, CardTitle } from "reactstrap"
import "./CampaignDashboard.scss"

function CampaignDashboard() {
  const series1 = [50]

  const options1 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [33]

  const options4 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Campaign Lead",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0%",
      color: "success",
      desc: "This Month",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Click",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0%",
      color: "success",
      desc: "This Month",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Customer",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0%",
      color: "danger",
      desc: "This Month",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total amount",
      value: 0,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0",
      color: "success",
      desc: "This Month",
      series: series4,
      options: options4,
    },
  ]
  return (
    <div>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Campaign Dashboard" />
          <div className="d-flex gap-2 pb-3">
            <div className="top_social top_socialcolor1">Facebook Ads</div>
            <div className="top_social top_socialcolor2">Instagram Ads</div>
            <div className="top_social top_socialcolor3">Google my business </div>
            <div className="top_social top_socialcolor4">QR Creative</div>
            <button style={{ border: "none", color: "green" }} className="fas fa-undo"></button>
          </div>
          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Row>
            <Col md="6" lg="6">
              <table className="table table-bordered campaign-dashboard ">
                <thead>
                  <tr>
                    <th className="campaign1stth" colspan="2">
                      <p>
                        <i className="fas fa-users"></i>{" "}
                      </p>
                    </th>
                    {/* <th className='campaign1stth'>

                      
                    </th> */}
                    <th className="headfont_size">Clicks</th>
                    <th className="headfont_size">Leads</th>
                    <th className="headfont_size">Customer</th>
                    <th className="headfont_size">Call</th>
                    <th className="headfont_size">Whatsapp</th>
                    <th className="headfont_size">Conversions</th>
                  </tr>
                </thead>

                <tbody className="headfontsize">
                  <tr>
                    <td className="rowtd" rowspan="5">
                      <p>Lead</p>
                    </td>
                    <th>Fb</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Insta</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Gmb</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Qr</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col md="6" lg="6">
              <table className="table table-bordered campaign-dashboard ">
                <thead>
                  <tr>
                    <th className="campaign2stth" colspan="2">
                      <p>
                        <i className="fas fa-users"></i>{" "}
                      </p>
                    </th>
                    {/* <th className='campaign1stth'>

                      
                    </th> */}
                    <th className="headfont_size">Clicks</th>
                    <th className="headfont_size">Leads</th>
                    <th className="headfont_size">Customer</th>
                    <th className="headfont_size">Call</th>
                    <th className="headfont_size">Whatsapp</th>
                    <th className="headfont_size">Conversions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="rowtd1" rowspan="5">
                      <p>Lead</p>
                    </td>
                    <th>Docs</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Tours</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Study</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <th>Jobs</th>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                    <td>0</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>

          <Row>
            <Col md="6" lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Campaign@BOM Social</CardTitle>
                  <Chartapex />
                </CardBody>
              </Card>
            </Col>
            <Col md="6" lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Lead & followup Statistics</CardTitle>
                  <Chartapex />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default CampaignDashboard
