import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import { GET_MODULE } from "./actionTypes"
import { getModuleSuccessful, getModuleFailed } from "./actions"

//Include Both Helper File with needed methods
import { getModule } from "../../helpers/backend_helper"

// Is user register successfull then direct plot user in redux.
function* fetchModule({ payload }) {
  try {
    const response = yield call(getModule, payload)
    yield put(getModuleSuccessful(response))
  } catch (error) {
    yield put(getModuleFailed(error))
  }
}
function* moduleSaga() {
  yield takeEvery(GET_MODULE, fetchModule)
}

export default moduleSaga
