import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"

import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import moment from "moment"
import MiniWidget from "./mini-widget"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function MediaPlatformTwitter() {
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() => new Date().setDate(new Date().getDate() - 30))
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  const fromDate = moment(startDate).format("YYYY-MM-DD")
  const toDate = moment(endDate).format("YYYY-MM-DD")

  const [sentimentCount, setSentimentCount] = useState()
  const [sentimentCountPercentage, setSentimentCountPercentage] = useState({
    series: [],
    labels: [],
  })
  const [metrics, setMetrics] = useState()

  function fetchSentimentCount() {
    var url = `${API_URL}report/v2/sentiment/countForSocialMedia?socialMedia=${"Twitter"}&fromDate=${fromDate}&toDate=${toDate}`

    axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
      let result = res?.data?.data
      setSentimentCount(result)
    })
  }

  function fetchSentimentCountPercentage() {
    var url = `${API_URL}report/v2/sentiment/countPercentageForSocialMedia?socialMedia=${"Twitter"}&fromDate=${fromDate}&toDate=${toDate}`

    axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
      let result = res?.data?.data
      setSentimentCountPercentage(result)
    })
  }

  // function fetchMetrics() {
  //   var url = `${API_URL}report/v2/mediaPlatform/twitter/metrics?fromDate=${fromDate}&toDate=${toDate}`

  //   axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
  //     let result = res?.data?.data
  //     setMetrics(result)
  //   })
  // }

  useEffect(() => {
    fetchSentimentCount()
    fetchSentimentCountPercentage()
    // fetchMetrics()
  }, [])

  // bar chart
  const series1 = sentimentCount || ""

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["KIAL"],
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
  }

  // pie chart
  const series2 = sentimentCountPercentage?.series

  const options2 = {
    labels: sentimentCountPercentage?.labels,
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }
  const series11 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
    },
  ]

  const options1 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const series22 = [70]

  const options22 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
    },
  ]

  const options4 = {
    fill: {
      colors: ["#f1b44c"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Followers",
      value: 32,
      prefix: "",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Tweets",
      value: 53,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Likes",
      value: 45254,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "6.24%",
      color: "danger",
      desc: "since last week",
      series: series3,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total Volume",
      value: 18,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "10.51%",
      color: "success",
      desc: "since last week",
      series: series4,
      options: options4,
    },
    // {
    //   id: 1,
    //   icon: "mdi mdi-arrow-up-bold",
    //   title: "Engagements",
    //   value: 342,
    //   prefix: "",
    //   suffix: "",
    //   badgeValue: "2.65%",
    //   decimal: 0,
    //   charttype: "bar",
    //   chartheight: 40,
    //   chartwidth: 70,
    //   color: "success",
    //   desc: "since last week",
    //   series: series1,
    //   options: options1,
    // },
    // {
    //   id: 2,
    //   icon: "mdi mdi-arrow-down-bold",
    //   title: " Engaged Users",
    //   value: 5643,
    //   decimal: 0,
    //   charttype: "radialBar",
    //   chartheight: 45,
    //   chartwidth: 45,
    //   prefix: "",
    //   suffix: "",
    //   badgeValue: "0.82%",
    //   color: "danger",
    //   desc: "since last week",
    //   series: series2,
    //   options: options2,
    // },
    // {
    //   id: 3,
    //   icon: "mdi mdi-arrow-down-bold",
    //   title: " Page Fans",
    //   value: 45254,
    //   decimal: 0,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "radialBar",
    //   chartheight: 45,
    //   chartwidth: 45,
    //   badgeValue: "6.24%",
    //   color: "danger",
    //   desc: "since last week",
    //   series: series3,
    //   options: options3,
    // },
    // {
    //   id: 4,
    //   icon: "mdi mdi-arrow-up-bold",
    //   title: " Total Volume",
    //   value: 12.58,
    //   decimal: 2,
    //   prefix: "",
    //   suffix: "",
    //   charttype: "bar",
    //   chartheight: 40,
    //   chartwidth: 70,
    //   badgeValue: "10.51%",
    //   color: "success",
    //   desc: "since last week",
    //   series: series4,
    //   options: options4,
    // },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Twitter Dashboard</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} - {formatDate(endDate, showFullMonth)}
            </h6>
          </div>
          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Row className="d-none">
            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{metrics?.followers.toLocaleString()}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Followers
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{metrics?.tweets.toLocaleString()}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Tweets
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{metrics?.likes.toLocaleString()}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Likes
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{metrics?.volume.toLocaleString()}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">
                      <i class={"fab icon-dashboard-2"}></i> Total Volume
                    </h4>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* <Row style={{ rowGap: "20px" }}>
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-reset"
                        id="dropdownMenuButton5"
                        caret
                        href="#"
                      >
                        <span className="fw-semibold">Sort By:</span>{" "}
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Monthly</DropdownItem>
                        <DropdownItem href="#">Yearly</DropdownItem>
                        <DropdownItem href="#">Weekly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <h5>Sentiment Breakup</h5>
                  <ReactApexChart
                    height="200"
                    options={option1}
                    series={series1}
                    type="bar"
                    className="apex-charts"
                  />
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Card>
                <CardBody>
                  <h5>Sentiment Percentage</h5>

                  <ReactApexChart
                    type="pie"
                    height="200"
                    className="apex-charts"
                    options={options2}
                    series={series2}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default MediaPlatformTwitter
