import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import ReactSelect from "react-select"

const AllResponse = () => {
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },
      {
        label: "Reference",
        field: "Reference",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        width: 150,
      },
      {
        label: "Priority",
        field: "Priority",
        width: 150,
      },
      {
        label: "Department",
        field: "Department",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        Code: "SD4MOT",
        Department: "Customer Service",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        Name: "Muhammad Thaha Kp",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "New",
        Description: " smot docs ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                setPopupView(true)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            {/* <i
                        className="uil-edit-alt"
                        style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                        }}

                    ></i> */}
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        Code: "ST2MOT",
        Name: "anumod.kanhileri",
        Department: "Customer Service",

        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "New",

        Description: "smot tours ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        Code: "SE5MOT",
        Name: "shajimenhal",
        Department: "Customer Service",

        Description: "smot study",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "Sham",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "New",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const viewdetail_data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Source",
        field: "source",
        width: 150,
      },

      {
        label: "Comments",
        field: "Department",
        width: 150,
      },
      {
        label: "User",
        field: "User",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        Code: "SD4MOT",
        Department: "Customer Service",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        Name: "Muhammad Thaha Kp",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "Closed",
        Description: " smot docs ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                setPopupView(true)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>
            {/* <i
                        className="uil-edit-alt"
                        style={{
                            fontSize: "1.2em",
                            cursor: "pointer",
                            marginLeft: "0.5rem",
                            marginRight: "0.5rem",
                        }}

                    ></i> */}
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        Code: "ST2MOT",
        Name: "anumod.kanhileri",
        Department: "Customer Service",

        date: "24-10-2022",
        Time: "12:30pm",
        User: "admin",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "Pending",

        Description: "smot tours ",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        Code: "SE5MOT",
        Name: "shajimenhal",
        Department: "Customer Service",

        Description: "smot study",
        date: "24-10-2022",
        Time: "12:30pm",
        User: "Sham",
        Priority: "High",
        Reference: "BOM2306RE00012",
        source: (
          <>
            <img
              src={`https://socialapi.kannurairport.aero/uploads/twitter/Instagram-comment.svg`}
              height="30"
              width="50"
            />
          </>
        ),
        status: "New",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Details</h5>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body pt-0">
          <Row>
            {/* <Col md={3}>
              <Nav pills className="flex-column">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames("mb-2", {
                    active: activeTabV === "15",
                  })}
                  onClick={() => {
                    toggleV("15")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i> 
                  </span>
                  <span className="d-none d-sm-block">Lead Info</span>
                </NavLink>

                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames("mb-2", {
                    active: activeTabV === "16",
                  })}
                  onClick={() => {
                    toggleV("16")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">Personal Details</span>
                </NavLink>
              </Nav>
            </Col> */}
            <Col md={12}>
              <Row>
                <Col md="12">
                  <table className="table table-borderd lead-info table-striped">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <th style={{ textAlign: "left" }}>
                          : Muhammad Thaha Kp
                        </th>
                        <td>Mobile</td>
                        <th style={{ textAlign: "left" }}>: 9876543467</th>
                        <td>Email</td>
                        <th style={{ textAlign: "left" }}>
                          : Thahakp@gmail.com
                        </th>
                        <td>District</td>
                        <th style={{ textAlign: "left" }}>: Kannur</th>
                      </tr>
                      <tr>
                        <td>Send To </td>
                        <th style={{ textAlign: "left" }}>: HR</th>
                        <td>Source</td>
                        <th style={{ textAlign: "left" }}>
                          :Facebook Comments
                        </th>
                        <td>Campaign</td>
                        <th style={{ textAlign: "left" }}>: Facebook Ads</th>
                        <td>Date</td>
                        <th style={{ textAlign: "left" }}>: 24-10-2022</th>
                      </tr>
                      <tr>
                        <td>priority </td>
                        <th style={{ textAlign: "left" }}>: Low</th>
                        <td>Type</td>
                        <th style={{ textAlign: "left" }}>: Informations</th>
                        <td>Sub Type</td>
                        <th style={{ textAlign: "left" }}>: Branch </th>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <th style={{ textAlign: "left" }}>: </th>
                        <td>Staff</td>
                        <th style={{ textAlign: "left" }}>: Admin</th>
                        <td>Comments </td>
                        <th style={{ textAlign: "left" }}>: </th>

                        <td></td>
                        <th></th>
                        <td></td>
                        <th></th>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <ReactSelect
                            name="description"
                            type="text"
                            options={[
                              {
                                label: "New",
                                value: 0,
                              },
                              {
                                label: "Pending",
                                value: 1,
                              },
                              {
                                label: "Closed",
                                value: 2,
                              },
                              {
                                label: "Responded",
                                value: 3,
                              },
                            ]}
                            errorMessage="Enter service"
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Upload</Label>
                          <AvField
                            name="Comments"
                            type="file"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="Comments"
                            rows={1}
                            type="textarea"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id=""
                    responsive
                    bordered
                    data={viewdetail_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Pending Response" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            name="Domain"
                            className="form-control"
                            type="date"
                            placeholder=" Name"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To date</Label>
                          <AvField
                            name="Description"
                            className="form-control"
                            type="date"
                            placeholder="Mobile No."
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Customer</Label>
                          <ReactSelect
                            name="Domain Code"
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Department</Label>
                          <ReactSelect
                            name="Domain Code"
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="Managetableid1"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AllResponse
