import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { connect, useDispatch } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import "toastr/build/toastr.min.css"
import toastr from "toastr"

// Import scss
import "./assets/scss/theme.scss"

// Import Fack Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import { accessToken } from "./store/actions"
import Cookies from "js-cookie"

// Firebase
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
import { setBrowserToken } from "./helpers/functions"

// Activating fake backend
fakeBackend()

toastr.options = {
  closeButton: true,
}

const App = props => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (Cookies.get("_token")) dispatch(accessToken(navigate, location.pathname))
    // eslint-disable-next-line
  }, [])

  // Firebase credentials are safe to be exposed to public
  const firebaseConfig = {
    apiKey: "AIzaSyB_rlT84kPPF8_OdsCpLLgYcVCf1hWd8Sg",
    authDomain: "bom-social.firebaseapp.com",
    projectId: "bom-social",
    storageBucket: "bom-social.appspot.com",
    messagingSenderId: "293848358636",
    appId: "1:293848358636:web:c05fc7913db7e96fc08fc9",
  }

  const requestPermission = () => {
    Notification.requestPermission().then(async permission => {
      if (permission === "granted") {
        const app = initializeApp(firebaseConfig)
        const messaging = getMessaging(app)

        getToken(messaging, { vapidKey: process.env.REACT_APP_NOTI_VAPIDKEY })
          .then(token => {
            setBrowserToken(token)
          })
          .catch(error => {
            console.error(error)
          })
      } else {
      }
    })
  }

  useEffect(() => {
    requestPermission()
  }, [])

  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <React.Fragment>
      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            isAuthProtected={false}
          />
        ))}

        {userRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware path={route.path}>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            isAuthProtected={true}
            exact
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
