import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"

// Redux
import { Link } from "react-router-dom"
import withRouter from "../../components/Common/withRouter"

import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
// Formik validation
// import * as Yup from "yup"
// import { useFormik } from "formik"

//Social Media Imports
// import { GoogleLogin } from "react-google-login"
// import TwitterLogin from "react-twitter-auth"
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

// actions
import { loginUser } from "../../store/actions"

// import images
import logo from "../../assets/images/logo-dark.svg"
// import logolight from "../../assets/images/logo-light.png"

import Cookies from "js-cookie"
import { APP_NAME } from "../../config"

//Import config
// import { facebook, google } from "../../config"

const Login = props => {
  document.title = `Login | ${APP_NAME} `

  const [master, setMaster] = useState({
    password: null,
    username: null,
    rememberMe: Boolean(Cookies.get("rememberMe")) || false,
  })

  const dispatch = useDispatch()

  // const validation = useFormik({
  //   // enableReinitialize : use this flag when initial values needs to be changed
  //   enableReinitialize: true,

  //   initialValues: {
  //     email: "admin@themesbrand.com" || "",
  //     password: "123456" || "",
  //   },
  //   validationSchema: Yup.object({
  //     email: Yup.string().required("Please Enter Your Email"),
  //     password: Yup.string().required("Please Enter Your Password"),
  //   }),
  //   onSubmit: values => {
  //     dispatch(loginUser(values, props.router.navigate))
  //   },
  // })

  const selectLoginState = state => state.Login
  const LoginProperties = createSelector(selectLoginState, login => ({
    error: login.error,
  }))

  const { error } = useSelector(LoginProperties)

  // const signIn = (res, type) => {

  //   if (type === "google" && res) {

  //     const postData = {
  //       name: res.profileObj.name,
  //       email: res.profileObj.email,
  //       token: res.tokenObj.access_token,
  //       idToken: res.tokenId,
  //     }
  //     dispatch(socialLogin(postData, props.router.navigate, type));
  //   } else if (type === "facebook" && res) {

  //     const postData = {
  //       name: res.name,
  //       email: res.email,
  //       token: res.accessToken,
  //       idToken: res.tokenId,
  //     }
  //     dispatch(socialLogin(postData, props.router.navigate, type))
  //   }
  // }

  //handleGoogleLoginResponse
  // const googleResponse = response => {
  //   signIn(response, "google")
  // }

  //handleFacebookLoginResponse
  // const facebookResponse = response => {
  //   signIn(response, "facebook")
  // }

  useEffect(() => {
    document.body.className = "authentication-bg"
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = ""
    }
  })

  const handleChange = e => {
    let { name, value, checked, type } = e.target
    value = type === "checkbox" ? JSON.parse(checked) : value
    setMaster(pre => ({ ...pre, [name]: value }))
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <Link to="/" className="mb-3 d-block auth-logo">
                          <img
                            src={logo}
                            alt=""
                            height="22"
                            className="logo logo-dark"
                          />
                          <img
                            src={logo}
                            alt=""
                            height="22"
                            className="logo logo-light"
                          />
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to {APP_NAME}.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={() => {
                        dispatch(loginUser(master, props.router.navigate))
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}

                      <div className="mb-3">
                        <AvField
                          name="username"
                          label="Username"
                          value={master.username}
                          className="form-control"
                          placeholder="Enter Username"
                          type="text"
                          required
                          onChange={handleChange}
                        />
                      </div>

                      <div className="mb-3">
                        {/* <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div> */}
                        <AvField
                          name="password"
                          label="Password"
                          value={master.password}
                          type="password"
                          required
                          placeholder="Enter Password"
                          onChange={handleChange}
                        />
                      </div>

                      <Row>
                        <Col xs={8}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              name="rememberMe"
                              onClick={handleChange}
                              defaultChecked={master.rememberMe}
                              value={!master.rememberMe}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="mt-3">
                            <button
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Col>
                      </Row>

                      {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={renderProps => (
                                <Link
                                to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          {google.CLIENT_ID !== '' &&
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={google.CLIENT_ID}
                              render={renderProps => (
                                <Link
                                to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={() => {}}
                            />
                          </li>
                          }
                        </ul>
                      </div> */}

                      {/* <div className="mt-4 text-center">
                        <p className="mb-0">
                          Don&apos;t have an account ?{" "}
                          <a
                            href="/register"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Signup now{" "}
                          </a>{" "}
                        </p>
                      </div> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-3 text-center">
                <p>
                  ©{new Date().getFullYear()} {APP_NAME}. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
}
