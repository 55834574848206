import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import toastr from "toastr"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
  TOKEN_REGENERATE,
} from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  Login,
  // postJwtLogin,
  postSocialLogin,
  Logout,
  AccessToken,
} from "../../../helpers/backend_helper"

import Cookies from "js-cookie"
import { getMenu } from "../../menu/actions"

const fireBaseBackend = getFirebaseBackend()

// function* loginUser({ payload: { user, history } }) {
//   try {
//     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
//       const response = yield call(
//         fireBaseBackend.loginUser,
//         user.email,
//         user.password
//       )
//       yield put(loginSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
//       const response = yield call(postJwtLogin, {
//         email: user.email,
//         password: user.password,
//       })
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
//       const response = yield call(Login, {
//         email: user.email,
//         password: user.password,
//       })
//       localStorage.setItem("authUser", JSON.stringify(response))
//       yield put(loginSuccess(response))
//     }
//     history("/dashboard")
//   } catch (error) {
//     yield put(apiError(error))
//   }
// }

// const encodedData = btoa("hello") // encode a string
// const decodedData = atob(encodedData)

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(Login, {
      username: user.username,
      password: user.password,
      rememberMe: user.rememberMe,
    })
    let redirect_url = "/dashboard"
    localStorage.setItem("authUser", JSON.stringify(response.data))
    if (user.rememberMe) {
      Cookies.set("rememberMe", true, { expires: 30 })
      Cookies.set("_token", response.refreshToken, { expires: 30 })
    } else {
      Cookies.set("_token", response.refreshToken)
      Cookies.remove("rememberMe")
      // sessionStorage.setItem("_token", response.refreshToken)
    }
    if (response.data.modules) {
      sessionStorage.setItem("module", response.data.modules.moduleName)
      sessionStorage.setItem("module_code", response.data.modules.shortCode)
      sessionStorage.setItem("module_id", response.data.modules._id)
      sessionStorage.setItem("module_url", response.data.modules.redirect_url)
      redirect_url = response.data.modules.redirect_url
    }
    yield put(loginSuccess(response))
    history(redirect_url)
  } catch (error) {
    yield put(apiError(error.response.data.message))
  }
}

function* logoutUser({ payload: { history, state } }) {
  try {
    localStorage.removeItem("authUser")
    // if (!Cookies.get("rememberMe")) {
    Cookies.remove("_token")
    Cookies.remove("rememberMe")
    sessionStorage.removeItem("module")
    sessionStorage.removeItem("module_code")
    sessionStorage.removeItem("module_id")
    sessionStorage.removeItem("module_url")
    // }
    if (!state) {
      yield call(Logout)
    }

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* accessTokenRegenarate({ payload }) {
  try {
    yield call(AccessToken, payload)
    if (
      !sessionStorage.getItem("module_url") &&
      !sessionStorage.getItem("module_id")
    ) {
      const data = JSON.parse(localStorage.getItem("authUser"))
      sessionStorage.setItem("module", data.modules.moduleName)
      sessionStorage.setItem("module_code", data.modules.shortCode)
      sessionStorage.setItem("module_id", data.modules._id)
      sessionStorage.setItem("module_url", data.modules.redirect_url)
      yield put(getMenu({ user: data._id, module: data.modules._id }))

      if (payload.pathname !== data.modules.redirect_url) {
        payload.history(payload.pathname)
      } else {
        payload.history(data.modules.redirect_url)
      }
    }
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
  } catch (error) {
    payload.history("/logout", { state: { refreshToken: true } })
    toastr.error("Token expired")
    // yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(TOKEN_REGENERATE, accessTokenRegenarate)
}

export default authSaga
