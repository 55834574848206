import React, { useState } from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, Modal ,CardTitle} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import Select from "react-select";
import "./Followup.scss"
function Followup() {

    const [popupView, setPopupView] = useState(false)
    const handleCloseModal = () => setPopupView(false);

    const [selectedStatus, setSelectedStatus] = useState(null)

    function handleSelectChange(selected, name) {
  
      switch (name) {
  
        case "status":
          setSelectedStatus(selected)
          break
  
        default: break
      }
    }


    const customStyles = {
        lineHeight: "1.8",
    };
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                width: 150,
            },
            {
                label: "Date",
                field: "Leads",
                width: 150,
            },
            {
                label: "Name",
                field: "Name",
                width: 150,
            },
            {
                label: "Mobile",
                field: "Mobile",
                width: 150,
            },

            {
                label: "Product",
                field: "Type",
                width: 150,
            },
            {
                label: "Priority",
                field: "Priority",
                width: 150,
            },

            {
                label: "Source",
                field: "Source",
                width: 150,
            },
            {
                label: "Assign To",
                field: "assign",
                width: 150,
            },
            {
                label: "Actions",
                field: "options",
                sort: "desc",
                width: 400,
            },

        ],

        rows: [
            {
                Source: "instagram",
                id: "1",
                Leads: "10-10-2017",
                Name: "Shaji",
                Type: "Visa",
                FollowUp: "-",
                Branch: "SPBL001",
                Priority: "2",
                Commitment: "21-01-2023",
                Mobile: "9876543201",
                assign: (<>
                    <select className="form-control form-select select_table_leads">
                        <option>Select Satff</option>
                        <option>admin</option>
                        <option>Ajay</option>
                        <option>Varun</option>
                    </select>
                </>),
                options: (
                    <div >

                        <i
                            className="fas fa-eye"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}
                            onClick={() => {
                                setPopupView(true)

                            }}
                        ></i>
                        <i
                            className="far fa-edit"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}

                        ></i>


                        <i
                            className="far fa-trash-alt"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                            }}

                        ></i>

                    </div>
                )
            },
            {
                Source: "instagram",
                id: "2",
                Leads: "10-10-2017",
                Name: "Shaji",
                Type: "Travel Document",
                FollowUp: "-",
                Branch: "SPBL002",
                Priority: "2",
                Commitment: "21-01-2023",
                Mobile: "9876543201",
                assign: (<>
                    <select className="form-control form-select select_table_leads">
                        <option>Select Satff</option>
                        <option>admin</option>
                        <option>Ajay</option>
                        <option>Varun</option>
                    </select>
                </>),
                options: (
                    <div >

                        <i
                            className="fas fa-eye"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}
                            onClick={() => {
                                setPopupView(true)

                            }}
                        ></i>
                        <i
                            className="far fa-edit"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}

                        ></i>


                        <i
                            className="far fa-trash-alt"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                            }}

                        ></i>

                    </div>
                )
            },
            {
                Source: "instagram",
                id: "3",
                Leads: "10-10-2017",
                Name: "Shaji",
                Type: "Passport",
                FollowUp: "-",
                Branch: "SPBL003",
                Priority: "1",
                Commitment: "21-01-2023",
                Mobile: "9876543201",
                assign: (<>
                    <select className="form-control form-select select_table_leads">
                        <option>Select Satff</option>
                        <option>admin</option>
                        <option>Ajay</option>
                        <option>Varun</option>
                    </select>
                </>),
                options: (
                    <div >

                        <i
                            className="fas fa-eye"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}
                            onClick={() => {
                                setPopupView(true)

                            }}
                        ></i>
                        <i
                            className="far fa-edit"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                                marginRight: "0.6em",
                            }}

                        ></i>


                        <i
                            className="far fa-trash-alt"
                            style={{
                                fontSize: "1em",
                                cursor: "pointer",

                            }}

                        ></i>

                    </div>
                )
            },
        ]
    }

    const viewdetail_data = {
        // eslint-disable-next-line 
        columns: [
          {
            label: "#",
            field: "id",
            width: 150,
          },
          {
            label: "Date",
            field: "code",
            width: 150,
          },
          {
            label: "Ref No.",
            field: "domain",
            width: 150,
          },
    
          {
            label: "Next Followup",
            field: "product_category",
            width: 150,
          },
    
    
          {
            label: "Comments",
            field: "description",
            width: 150,
          },
    
          {
            label: "Status",
            field: "User",
            sort: "desc",
            width: 400,
          },
          //   {
          //     label: "Actions",
          //     field: "options",
          //     sort: "desc",
          //     width: 400,
          //   },
        ],
        rows: [{
          options: (
            <div className="d-flex gap-2" >
              <i
                className="uil-edit-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
    
              ></i>
    
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
    
              ></i>
            </div>
          )
        }]
      }
    
    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Follow Up" />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <AvForm>
                                        <Row>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label > Name</Label>
                                                    <AvField
                                                        name="Domain"
                                                        style={customStyles}
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Name"
                                                        errorMessage="Enter Category"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Mobile No.</Label>
                                                    <AvField
                                                        style={customStyles}
                                                        name="Description"
                                                        className="form-control"
                                                        type="text"
                                                        placeholder="Mobile No."

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3 i2">
                                                <div className="mb-2 i1 " >
                                                    <Label >Priority</Label>
                                                    <input
                                                        style={customStyles}
                                                        type='Number'
                                                        placeholder='0'
                                                        className='form-control '
                                                    />

                                                </div>
                                                <div className="mb-2 i1 i3" >
                                                    <Select
                                                        style={customStyles}
                                                        placeholder='Select...'
                                                        options={[
                                                            {
                                                                label: "SEL",
                                                                value: 0,
                                                            },
                                                            {
                                                                label: "CP",
                                                                value: 1,
                                                            },
                                                            {
                                                                label: "DC",
                                                                value: 2,
                                                            },
                                                            {
                                                                label: "NB",
                                                                value: 3,
                                                            },
                                                            {
                                                                label: "NP",
                                                                value: 4,
                                                            },
                                                            {
                                                                label: "NR",
                                                                value: 5,
                                                            },
                                                            {
                                                                label: "NV",
                                                                value: 6,
                                                            },
                                                            {
                                                                label: "SW",
                                                                value: 7,
                                                            },
                                                        ]}
                                                    />

                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Branch</Label>
                                                    <Select
                                                        name="Domain Code"
                                                        style={customStyles}
                                                        type="text"
                                                        placeholder="Select..."
                                                        errorMessage="Enter Category"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >Service</Label>
                                                    <Select
                                                        name="Domain Code"
                                                        style={customStyles}
                                                        type="text"
                                                        placeholder="Select..."
                                                        errorMessage="Enter Category"
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >From</Label>
                                                    <AvField
                                                        style={customStyles}
                                                        name="Description"
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="Mobile No."

                                                    />
                                                </div>
                                            </Col>
                                            <Col md="3">
                                                <div className="mb-3">
                                                    <Label >To</Label>
                                                    <AvField
                                                        style={customStyles}
                                                        name="Description"
                                                        className="form-control"
                                                        type="date"
                                                        placeholder="Mobile No."

                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="mb-3" style={{ paddingTop: "25px" }}>
                                                    <Button color="danger" type="submit">
                                                        Reset
                                                    </Button>

                                                </div>
                                            </Col>
                                        </Row>
                                    </AvForm>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>


                    <Row>
                        <Col className='col-12'>
                            <Card>
                                <CardBody>


                                    <MDBDataTable
                                        id="followuupID"
                                        bordered
                                        responsive
                                        searching={true}
                                        entries={20}
                                        disableRetreatAfterSorting={true}
                                        data={data}

                                    >

                                    </MDBDataTable>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>



                </div>
            </div>
            <>
            <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}>
        <div className="modal-header">
          <div className="modal-title">


          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
              <CardBody>
                  <CardTitle>Personal Details</CardTitle>
                  <Row><Col md="3">
                    <p>Name
                      : <b>Shaji</b></p>
                  </Col>

                    <Col md="3">
                      <p>
                        Mobile
                        : <b>989757826</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Email ID
                        : <b>Shaji@gmail.com</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                      Place
                      : <b>Banglore</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        State
                        : <b>Karnataka</b>
                      </p>
                    </Col>

                    <Col md="4">
                      <p>

                        Address
                        : <b></b>
                      </p>
                    </Col>
                  </Row>


                  <p className='border_popup'></p>
                  <CardTitle>Lead Details</CardTitle>

                  <Row>
                    <Col md="3">
                    <p>Lead Id
                      : <b>SMOT01</b></p>
                  </Col>

                    <Col md="3">
                      <p>

                        Date
                        : <b>23-02-2023</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Branch
                        : <b>BLR01</b>
                      </p>
                    </Col>

                    <Col md="3">
                      <p>
                        Source
                        : <b>Instagram</b>
                      </p>
                    </Col>
                    <Col md="3"
                    >
                      <p>

                        Staff
                        : <b>admin</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Assign Date
                        :  <b>19-01-2024</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Status
                        :
                        <span className="badge bg-warning font-size-12 ms-2">New</span>

                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Priority
                        : <b>1/CL</b>
                      </p>
                    </Col>
                    <Col md="3">
                      <p>

                        Product Category
                        : <b>Travel Document</b>
                      </p>
                    </Col>
                    <Col md="4">
                      <p>

                        Product
                        : <b></b>
                      </p>
                    </Col>

                  </Row>

                  {/* 
                  <table className='viewdetailpage' id="followupmodalId">

                    <tr>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Name</b>
                      </td>
                      <td>:Shaji </td>

                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Mobile No.</b>
                      </td>
                      <td>:988654480 </td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Email</b>
                      </td>
                      <td>:shaji@gmail.com</td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Lead Type</b>
                      </td>
                      <td>:birth certificate</td>



                    </tr>
                    <tr>

                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Product Category</b>
                      </td>
                      <td>:Passport</td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Product </b>
                      </td>
                      <td>:Fresh Passport (Normal , 36 Pages)</td>

                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Priority</b>
                      </td>
                      <td>:1</td>

                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Sub priority</b>
                      </td>
                      <td>:CL</td>




                    </tr>
                    <tr>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Address</b>
                      </td>
                      <td>: sectetur adipis </td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Form</b>
                      </td>
                      <td>:Agent</td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Document</b>
                      </td>
                      <td>: Agent </td>
                      <td style={{ width: "115px", padding: "10px 0px" }}>
                        <b>Payment</b>
                      </td>
                      <td>: Agent</td>
                    </tr>




                  </table> */}
                </CardBody>
              </Card>
            </Col>
            <Col md="12" lg="12">

              <Card>
                <CardBody>
                  <AvForm>
                    <Row>

                    <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="description"
                            type="text"
                            onChange={(selected) => {
                              handleSelectChange(selected, "status")
                            }}
                            options={[
                              {
                                label: "Service",
                                value: 0,
                              },
                              {
                                label: "0",
                                value: 1,
                              },
                              {
                                label: "1",
                                value: 2,
                              },
                              {
                                label: "2",
                                value: 3,
                              },
                              {
                                label: "3",
                                value: 4,
                              },
                              {
                                label: "4",
                                value: 5,
                              },
                              {
                                label: "5",
                                value: 6,
                              },


                            ]}

                            errorMessage="Enter service"

                          />
                        </div>
                      </Col>

                      {selectedStatus?.value === 0 ? (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Service</Label>
                              <Select
                                name="description"
                                type="text"
                                options={[
                                  {
                                    label: "Passport",
                                    value: 0,
                                  },
                                  {
                                    label: "PAN Card",
                                    value: 1,
                                  },
                                  {
                                    label: "Aadhaar",
                                    value: 2,
                                  },
                                  {
                                    label: "Voter ID",
                                    value: 3,
                                  },
                                  {
                                    label: "OCI",
                                    value: 4,
                                  },
                                  {
                                    label: "Visa",
                                    value: 5,
                                  },
                                  {
                                    label: "Foreign Passport",
                                    value: 6,
                                  },

                                ]}
                                errorMessage="Enter service"

                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Service Type</Label>
                              <Select
                                name="description"
                                type="text"
                                options={[
                                  {
                                    label: "Fresh-Normal-36 Pages-35 to 40 days",
                                    value: 0,
                                  },
                                  {
                                    label: "Fresh-SuperFast-36 Pages-Below 20 days",
                                    value: 1,
                                  },
                                  {
                                    label: "Fresh-SuperFast-36 Pages-Below 10 days",
                                    value: 2,
                                  },
                                  {
                                    label: "Fresh-SuperFast-36 Pages-Below 5 days",
                                    value: 3,
                                  },
                                  {
                                    label: "Fresh-Tatkal-36 Pages-Below 10 days",
                                    value: 4,
                                  },
                                  {
                                    label: "Reissue-Normal-36 Pages-35 to 40 days",
                                    value: 5,
                                  },
                                  {
                                    label: "Reissue-Normal-36 Pages-35 to 40 days",
                                    value: 6,
                                  },
                                ]}
                                errorMessage="Enter service"
                              />
                            </div>
                          </Col>
                        </>

                      ) : null}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Next Followup </Label>
                          <AvField
                            name="description"
                            type="date"
                            placeholder="Service Code"
                            errorMessage="Enter Service Code"

                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="description"
                            rows={1}
                            type="textarea"
                            placeholder="Description"
                            errorMessage="Enter Description"

                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>


            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id=""
                    responsive
                    bordered
                    data={viewdetail_data}


                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
            </>
        </>
    )
}

export default Followup