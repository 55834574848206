import React, { useState, useRef, useEffect, useTransition } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import "./Feedbackquestions.scss"
import { get, post, del, put } from "../../../helpers/api_helper"
import toastr from "toastr"
import Swal from "sweetalert2"
import moment from "moment"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import {  jsonToExcel } from "../../../helpers/functions"

import { WEB_URL } from "../../../config"
import MuiCustomTablePagination from "../../../components/Common/TablePagination"
import SelectErrorMessage from "../../../components/Common/SelectErrorMessage"

const OPTIONS = {
  types: [
    { value: "1", label: "Retail Banking" },
    { value: "2", label: "Corporate Banking" },
    { value: "3", label: "Customer Service" }
  ]
};

const getTypeLabel = (typeValue) => {
  const type = OPTIONS.types.find(t => t.value == typeValue);
  return type ? type.label : '';
}

const initialMaster = {
  required: { name: false, mobile: false, email: false },
  questions: [{}],
  name: "",
  assignedFor: null,
  assigned: "",
}
const initaialSelected = {
  assignedFor: "",
  assigned: "",
}

const initialFilter = {
  assignedFor: "",
  assigned: "",
}

function Feedbackform() {
  const ratingOptions = [
    {
      label: "Yes/No",
      value: 0,
    },
    {
      label: "Rating",
      value: 1,
    },
    {
      label: "Emoji Rating",
      value: 2,
    },
  ]

  const AssignedForOptions = [
    {
      label: "Department",
      value: '0',
      type: '0'
    }
  ]

  // const toTop = () => {
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   })
  // }
  const formRef = useRef()
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const [selected, setSelected] = useState(initaialSelected)
  const [masterObj, setmasterObj] = useState(initialMaster)
  const [tableData, settableData] = useState([])
  const [popUpData, setpopUpData] = useState([])
  const [errorMessages, setErrorMessages] = useState([])

  const [isExportPending, startExport] = useTransition()
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20)
  const [count, setCount] = useState(0)
  



  const [state,setState] = useState(false)
  const [filterObj, setFilterObj] = useState(initialFilter)
  const [selectedFilter, setselectedFilter] = useState(initialFilter)
  
  let initialErr = {
    type: "",
    question: "",
  }
  const [Err, setErr] = useState([])

  useEffect(() => {
    fetchAllFeedback()
  }, [filterObj,state,page,pageSize])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Assigned For",
        field: "assignedFor",
        width: 150,
      },
      {
        label: "Type",
        field: "assigTo",
        width: 150,
      },
      {
        label: "Form Name",
        field: "name",
        width: 150,
      },

      {
        label: "Link",
        field: "link",
        width: 150,
      },

      {
        label: "Name",
        field: "name1",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Added By",
        field: "added",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: tableData,
  }

  function preupdateItem(item) {

    // const assignedForOption = AssignedForOptions.find(opt => opt.value === '0');
    // const assignedTypeOption = OPTIONS.types.find(t => t.value === item.assigned);

    setmasterObj({
      ...item,
      assignedFor: '0',
    questions: item.questions.map(q => ({
      question: q.question,
      type: q.type
    }))
    })
    setSelected({
      assignedFor: { label: "Department", value: 0 },
      assigned: OPTIONS.types.find(t => t.value == item.assigned) || null
    })
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`/feedback/form?id=${id}`)
          .then(res => {
            toastr.success(res.status)
            fetchAllFeedback()
          })
          .catch(err => {
            toastr.success(err?.response?.data?.message)
            fetchAllFeedback()
          })
      }
    })
  }
  const handleCheckboxChange = field => {
    setmasterObj(prevState => ({
      ...prevState,
      required: {
        ...prevState.required,
        [field]: !prevState.required[field], // Toggle the value of 'name'
      },
    }))
  }

  const handlechange = e => {
    setmasterObj(prev => ({
      ...prev,
      name: e.target.value,
    }))
  }

  function handlevalue(value, name, id) {
    const data = { ...masterObj }
    const prev = data.questions
    prev[id][name] = value
  }

  const handlevalueType = (selected, index) => {
    setmasterObj(prevState => {
      const updatedQuestions = [...prevState.questions];
      updatedQuestions[index] = { ...updatedQuestions[index], type: selected.value };
      return { ...prevState, questions: updatedQuestions };
    });
    setErr(prevState => {
      const errs = [...prevState]
      errs[index] = false
      return errs
    })
  };

  function handleSubmit(e) {
    e.preventDefault()

    if(masterObj?.assignedFor == null || masterObj.assignedFor == undefined){
     return toastr.error("Please Select Assigned For")
    }

    const result = masterObj?.questions?.map(item => 
      typeof item.type !== 'number'
  );

    setErr(result)
    if (result.includes(true)) {
      return
    }

    // Prepare the submission data
  const submitData = {
    name: masterObj.name,
    assignedFor: masterObj.assignedFor,
    assigned: masterObj.assigned,
    required: masterObj.required,
    questions: masterObj.questions.map(q => ({
      question: q.question,
      type: q.type
    }))
  }


    if (masterObj?._id) {

      submitData._id = masterObj._id

      put(`/feedback/form`, submitData).then(res => {
        toastr.success(res.message)
        resetAll()
      })
      .catch(err => {
        console.error("Update error:", err?.response?.data)
        toastr.error(err?.response?.data?.message || "Something went wrong in put")
      })
    } else {
      // let result = []
      // result = masterObj.questions.map(obj => Object.keys(obj).length !== 2)
      // if (result[0]) return toastr.error("Please select Type ")

      post(`/feedback/form`, submitData).then(res => {
        toastr.success(res.message)
        resetAll()
      })
      .catch(err => {
        console.error("Create error:", err?.response?.data)
        toastr.error(err?.response?.data?.message || "Something went wrong in post")
      })
    }
  }
  const [copied, setCopied] = useState(0) // State variable to track if URL is copied

  const handleCopyURL = url => {
    const tempInput = document.createElement("input")
    tempInput.value = url
    document.body.appendChild(tempInput)

    tempInput.select()
    tempInput.setSelectionRange(0, 99999)

    document.execCommand("copy")
    document.body.removeChild(tempInput)
    setCopied(true)

    toastr.success("URL copied to clipboard: " + url)
    console.log(copied, "copieed")
    setTimeout(() => {
      setCopied(false)
    }, 2000)
  }
  function fetchAllFeedback() {

    const params = new URLSearchParams();
    
    if (filterObj.assignedFor) {
      params.append('assignedFor', filterObj.assignedFor);
    }
    if (filterObj.assigned) {
      params.append('assigned', filterObj.assigned);
    }
    params.append('page', (page + 1).toString());
    params.append('limit', pageSize.toString());
    get(`/feedback/form?${params.toString()}`).then(res => {
      let result = res.data
      setCount(res.count)
      result &&
        result.map((item, index) => {
          item.id = index + 1 + (page * pageSize)
          item.date = moment(item?.date, "YYYY-MM-DD").format("DD-MM-YYYY")
          item.added = item.addedBy?.label
          // item.assign = "
          // item.assigTo = item.assignedFor || ""
          item.assignedFor = AssignedForOptions.find(subItem => item.assignedFor == subItem.value)?.label
          item.assigTo = getTypeLabel(item.assigned)
          item.active1 = (
            <>
              <div
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <input
                  type="checkbox"
                  disabled=""
                  checked={item.active}
                ></input>
              </div>
            </>
          )
          item.link = (
            <>
              <a
                style={{
                  marginRight: "5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                href={WEB_URL + "fbs/" + item.uniqueID}
                target="_blank"
              >
                {WEB_URL + "fbs/" + item.uniqueID}{" "}
              </a>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{"Copy URL"}</Tooltip>}
              >
                <i
                  className="far fa-copy"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleCopyURL(WEB_URL + "fbs/" + item.uniqueID)
                  }
                ></i>
              </OverlayTrigger>
            </>
          )
          if (item.required.name) {
            item.name1 = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-check"
                  ></i>
                </div>
              </>
            )
          } else if (!item.required.name) {
            item.name1 = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-times"
                  ></i>
                </div>
              </>
            )
          }
          if (item.required.mobile) {
            item.mobile = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-check"
                  ></i>
                </div>
              </>
            )
          } else if (!item.required.mobile) {
            item.mobile = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-times"
                  ></i>
                </div>
              </>
            )
          }
          if (item.required.email) {
            item.email = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-check"
                  ></i>
                </div>
              </>
            )
          } else if (!item.required.email) {
            item.email = (
              <>
                <div style={{ textAlign: "center" }}>
                  <i
                    style={{ color: "green", textAlign: "center" }}
                    class="fas fa-times"
                  ></i>
                </div>
              </>
            )
          }
          item.options = (
            <div>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",

                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  setPopupView(true)
                  setpopUpData(item)
                }}
              ></i>
              <i
                onClick={() => {
                  preupdateItem(item)
                }}
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginRight: "0.5rem",
                }}
              ></i>
              <i
                onClick={() => {
                  handleDelete(item._id)
                }}
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
              ></i>
            </div>
          )
          return item
        })
      settableData(result)
    }).catch(err => {
      console.error("Error fetching feedback:", err);
      toastr.error(err?.response?.data?.message || "Error fetching feedback data");
    });
  }
  function resetAll() {
    try {
      formRef.current?.reset();
      
      // Reset all state to initial values
      setmasterObj({
        ...initialMaster,
        questions: [{}] // Ensure we always have one empty question
      });
      
      setSelected(initaialSelected);
      setFilterObj(initialFilter);
      setselectedFilter(initialFilter);
      setErr([]);
      setErrorMessages([]);
      setPage(0);
      
      // Only fetch feedback after all states are reset
      setState(prev => !prev); // This will trigger useEffect to fetch data
    } catch (error) {
      console.error("Error in resetAll:", error);
      toastr.error("Error resetting form");
    }
  }


 

  const handleExportData = async () =>{
    try {

       // Build query parameters properly
       const queryParams = new URLSearchParams();
      
       // Only add parameters if they have values
       if (filterObj?.assignedFor) {
         queryParams.append('assignedFor', filterObj.assignedFor);
       }
       
       if (filterObj?.assigned) {
         queryParams.append('assigned', filterObj.assigned);
       }


      const response = await get(`/feedback/form${queryParams.toString() ? `?${queryParams.toString()}` : ''}`);
      const data = response.data
      const exportData = []

      if (data && Array.isArray(data) && data.length > 0) {
        data.map((item) => {
        const dataExport = {}

        // item.assign = "Department"

        dataExport.Date = moment(item?.date, "YYYY-MM-DD").format("DD-MM-YYYY")
        dataExport["Assigned For"] = "Department"
        dataExport.Type = getTypeLabel(item.assigned)
        dataExport.Assigned = item?.assignedDetails?.label
        dataExport["Form Name"] = item?.name
        dataExport.Link = WEB_URL + "fbs/" + item.uniqueID
        dataExport.Name = item.required.name ?"Yes":"No"
        dataExport.Mobile = item.required.mobile ?"Yes":"No"
        dataExport.Email = item.required.email ?"Yes":"No"
        dataExport['Added By'] = item.addedBy?.label

        exportData.push(dataExport)
      })

      jsonToExcel(exportData, `Feedback-list-${moment().format("DD-MM-YY")}`)

    }else{
      toastr.info(`There are No Feedback to export`)
    }
    } catch (error) {
      // More detailed error handling
      const errorMessage = error?.response?.data?.message || "Failed to export data";
      console.error("Export error:", error);
      toastr.error(errorMessage);
      
      // If the error is specifically about invalid assigned ID, show a more helpful message
      if (error?.response?.data?.message === 'Invalid assigned ID') {
        toastr.error("Please select valid filter options before exporting");
      }
    }
  }

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
  }

  const renderAssignedFields = () => {
    if (selected?.assignedFor?.value == 0) {
      return (
        <Col md="3">
          <div className="mb-3">
            <Label>Type</Label>
            <Select
              options={OPTIONS.types}
              classNamePrefix="select2-selection"
              onChange={item => {
                setmasterObj({
                  ...masterObj,
                  assigned: item?.value,
                })
                setSelected(prev => ({ ...prev, assigned: item }));
              }}
              value={selected?.assigned || null }
              placeholder="Select Type"
            />
          </div>
        </Col>
      )
    }

    return null
  }

  const renderFilterFields = () => {
    if (selectedFilter?.assignedFor?.value === 0) {
      return (
        <Col md="3">
          <div className="mb-3">
            <Label>Type</Label>
            <Select
              options={OPTIONS.types}
              classNamePrefix="select2-selection"
              onChange={item => {
                setFilterObj({
                  ...filterObj,
                  assigned: item?.value,
                })
                setselectedFilter({
                  ...selectedFilter,
                  assigned: item,
                })
              }}
              value={selectedFilter?.assigned}
              placeholder="Select Type"
            />
          </div>
        </Col>
      )
    }

    return null
  }

  useEffect(() => {console.log(masterObj)},[masterObj])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Feedback Form" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={handleSubmit} ref={formRef}>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Assigned For</Label>
                          <Select
                            options={AssignedForOptions}
                            classNamePrefix="select2-selection"
                            onChange={item => {
                              setmasterObj({
                                ...masterObj,
                                assignedFor: item?.value,
                              })
                              setSelected({ ...selected, assignedFor: item })
                            }}
                            value={selected?.assignedFor}
                            placeholder="Select Assigned For"
                          />
                        </div>    
                      </Col>
                      {renderAssignedFields()}
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Feedback Form Name</Label>
                          <AvField
                            name="name"
                            className="form-control"
                            type="text"
                            placeholder="Feedback Form Name"
                            errorMessage="Enter Feedback Form Name"
                            onChange={handlechange}
                            value={masterObj?.name}
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: selected?.assignedFor?.value !== 1 ? "35px" : "" }}>

                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox1"
                            checked={masterObj?.required.name}
                            onClick={() => handleCheckboxChange("name")}
                          />
                          <label className="myname" htmlFor="myCheckbox1">
                            Name
                          </label>
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: selected?.assignedFor?.value !== 1 ? "35px" : "" }}>

                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox2"
                            checked={masterObj?.required.mobile}
                            onClick={() => handleCheckboxChange("mobile")}
                          />
                          <label className="myname" htmlFor="myCheckbox2">
                            Mobile
                          </label>
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: selected?.assignedFor?.value !== 1 ? "35px" : "" }}>
                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox3"
                            checked={masterObj?.required.email}
                            onClick={() => handleCheckboxChange("email")}
                          />
                          <label className="myname" htmlFor="myCheckbox3">
                            Email
                          </label>
                        </div>
                      </Col>

                      {masterObj.questions.map((item, idx) => (
                        <Row key={idx}>
                          <>
                            <Col md="4">
                              <div className="mb-3">
                                <Label>Question</Label>
                                <AvField
                                  name={`feedbackname-${idx}`}
                                  className="form-control"
                                  rows={1}
                                  type="textarea"
                                  placeholder="Question"
                                  value={item.question || ""}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: "Enter Question",
                                    },
                                  }}
                                  // errorMessage="Enter Question"
                                  errorMessage={errorMessages[idx]}
                                  onChange={e => {
                                    handlevalue(e.target.value, "question", idx)
                                  }}
                                  onValid={() => {
                                    setErrorMessages(prevMessages => {
                                      const updatedMessages = [...prevMessages]
                                      updatedMessages[idx] = "" // Clear error message on valid input
                                      return updatedMessages
                                    })
                                  }}
                                  onInvalid={() => {
                                    setErrorMessages(prevMessages => {
                                      const updatedMessages = [...prevMessages]
                                      updatedMessages[idx] = "Enter Question" // Set error message on invalid input
                                      return updatedMessages
                                    })
                                  }}
                                />
                              </div>
                            </Col>
                            <Col md="3" key={idx}>
                              <div className="mb-3">
                                <Label>Type</Label>
                                <Select
                                  options={ratingOptions}
                                  classNamePrefix="select2-selection"
                                  onChange={selected => {
                                    handlevalueType(selected, idx)
                                  }}
                                  value={ratingOptions[item.type] || ""}
                                  placeholder="Select type"
                                />
                                 <SelectErrorMessage
                                  customStyle={{
                                    width: "100%",
                                    fontSize: "87.5%",
                                    color: "var(--bs-form-invalid-color)",
                                  }}
                                  message="Type is required"
                                  show={Err[idx]}
                                />
                              </div>
                            </Col>
                            <Col>
                              {" "}
                              {masterObj.questions.length > 1 && (
                                <div className="mt-4 d-flex justify-content-start">
                                  <span
                                    className="mr-1 p-2"
                                    style={{
                                      fontSize: "1.125rem",
                                      cursor: "pointer",
                                    }}
                                    // onClick={() => {
                                    //   const data = [...questions]
                                    //   data.splice(idx, 1)
                                    //   setQuestions(data)
                                    // }}
                                    onClick={() => {
                                      const data = { ...masterObj }
                                      data.questions.splice(idx, 1)
                                      setmasterObj(data)
                                    }}
                                  >
                                    <i className="trash-btn mdi mdi-delete"></i>
                                  </span>
                                </div>
                              )}{" "}
                            </Col>
                          </>
                        </Row>
                      ))}
                      <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            type="button"
                            // onClick={() => {
                            //   const data = [...questions]
                            //   data.push({})
                            //   setQuestions(data)
                            // }}
                            onClick={() => {
                              const newData = [...masterObj.questions, {}]
                              setmasterObj(prevState => ({
                                ...prevState,
                                questions: newData,
                              }))
                            }}
                            className="waves-effect btn btn-outline-light d-flex"
                            style={{ gap: "5px" }}
                          >
                            Add more{" "}
                            <i className="mdi mdi-plus-circle-outline"></i>
                          </button>
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "20px" }}>
                          <Button
                            color={masterObj?._id ? "warning" : "primary"}
                            style={{ marginRight: "10px" }}
                            type="submit"
                          >
                            {masterObj?._id ? "Update" : "Submit"}
                          </Button>
                          <Button
                            color="danger"
                            type="button"
                            onClick={resetAll}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Assigned For</Label>
                        <Select
                          options={AssignedForOptions}
                          classNamePrefix="select2-selection"
                          onChange={item => {
                            setFilterObj({
                              ...filterObj,
                              assignedFor: item?.type,
                              course: "",
                              assigned: "",
                            })
                            setselectedFilter({
                              ...selectedFilter,
                              assignedFor: item,
                              course: "",
                              assigned: "",
                            })
                          }}
                          value={selectedFilter?.assignedFor}
                          placeholder="Select Assigned For"
                        />
                      </div>
                    </Col>
                    {renderFilterFields()}
                    <Col md="3" style={{ marginTop: "1.7rem" }}>
                      <Button
                        color="danger"
                        type="button"
                        onClick={() => {
                          setFilterObj(initialFilter)
                          setselectedFilter(initialFilter)
                          setState(!state)
                        }}
                      >
                        Reset
                      </Button>
                      <Button
                        style={{ marginLeft: "14px" }}
                        color="warning"
                        type="button"
                        onClick={handleExportData}
                      >
                        Export{" "}
                      </Button>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="FeedbackformId"
                    bordered
                    responsive
                    searching={false}
                    paging={false}
                    info={false}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                  {tableData.length > 0 && count > 10 && (
                    <MuiCustomTablePagination
                      count={count}
                      page={page}
                      rowsPerPage={pageSize}
                      handleChangePage={handlePageChange}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6 className="mx-2 my-2">Feedback Details</h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Col md="12">
            <ul className="lead-details">
              <li>
                Date:<Label>{popUpData?.date}</Label>
              </li>
              <li>
                Feedback Form Name: <Label>{popUpData?.name}</Label>
              </li>
              <li>
                Created By: <Label>{popUpData?.added}</Label>
              </li>
              <li>
                Link : <Label>{popUpData?.link}</Label>
              </li>
              <li>
                Name : <Label>{popUpData?.name1}</Label>
              </li>
              <li>
                Mobile : <Label>{popUpData?.mobile}</Label>
              </li>
              <li>
                Email : <Label>{popUpData?.email}</Label>
              </li>
            </ul>
          </Col>
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "120px", textAlign: "left" }}>
                    Question
                  </th>
                  <th style={{ width: "320px", textAlign: "left" }}>Type</th>
                </tr>
              </thead>
              <tbody>
                {popUpData &&
                  popUpData?.questions?.map((item, index) => (
                    <tr>
                      <td style={{ textAlign: "left" }}>{item.question}</td>
                      <td style={{ textAlign: "left" }}>
                        {item.type == 0
                          ? "Yes/No"
                          : item?.type == 1
                          ? "Rating"
                          : item.type == 2
                          ? "Emoji Rating"
                          : ""}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Feedbackform
