import React, { useEffect, useRef, useState } from "react"
import { Button, Card, CardBody, Col, Container, Label, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import {
  getBranches,
  getModules,
  getPrivileges,
} from "../../store/options/actions"
import { del, get, post, put } from "../../helpers/api_helper"
import toastr from "toastr"
import axios from "axios"
import moment from "moment"
import Swal from "sweetalert2"
import { API_URL, APP_NAME } from "../../config"
import { useNavigate } from "react-router-dom"
import "./styles.scss"

const UserD = props => {
  document.title = `Users | ${APP_NAME}`

  const options = props.options

  console.log(options)

  const formRef = useRef()
  const navigate = useNavigate()

  const [userObject, setUserObject] = useState({})
  const [selectedPrivilege, setSelectedPrivilege] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [tableData, setTableData] = useState([])

  const [selectedBranch, setSelectedBranch] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getPrivileges())
    dispatch(getModules())
    dispatch(getBranches())
  }, [dispatch])

  useEffect(() => {
    handleTableData()
    // eslint-disable-next-line
  }, [options])

  const { privileges, modules, branches } = useSelector(state => state.Options)

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  const uploadImage = e => {
    const fd = new FormData()
    fd.append("user_image", e.target.files[0])
    axios
      .post(`${API_URL}user/image`, fd, {
        // onUploadProgress: data => {
        //   // setUploadProgress(Math.round((100 * data.loaded) / data.total));
        // },
      })
      .then(response => {
        if (response.data.status === 200) {
          setUserObject({
            ...userObject,
            user_image: response.data.data.new_filename,
          })
        } else {
          toastr.error(response.data.message)
        }
      })
  }

  function handleChange(e) {
    let { name, value } = e.target
    setUserObject(pre => ({ ...pre, [name]: value }))
  }

  function handleSubmit() {
    if (userObject.id) {
      put("user", userObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
          console.log(err)
        })
    } else {
      post("user", userObject)
        .then(res => {
          toastr.success(res.message)
          reset()
        })
        .catch(err => {
          toastr.error(err.response.data.message)
        })
    }
  }

  function reset() {
    setUserObject({})
    formRef.current.reset()
    setSelectedPrivilege(null)
    setSelectedModule(null)
    setSelectedBranch(null)
  }

  function handleDelete(id) {
    return Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(result => {
      if (result.isConfirmed) {
        del(`user/${id}`)
          .then(res => {
            toastr.success(res.message)
            handleTableData()
          })
          .catch(err => {
            toastr.success(err.response.data.message)
          })
      }
    })
  }

  function handleTableData() {
    get("user")
      .then(res => {
        const data = res.user
        data.map((item, index) => {
          item.id = index + 1

          item.name1 = `${item?.firstName || ""} ${item?.lastName || ""}`

          item.privilage1 = item.privilage && item.privilage.privilege_name
          item.date = moment(item.date).format("DD-MM-YYYY")
          if (item?.updated_by) {
            let updatedBy = item?.updated_by || {}
            if (updatedBy?.lastName)
              item.staff = updatedBy?.firstName + " " + updatedBy?.lastName
            else item.staff = updatedBy?.firstName
          } else {
            let addedBy = item?.created_by || {}
            if (addedBy?.lastName)
              item.staff = addedBy?.firstName + " " + addedBy?.lastName
            else item.staff = addedBy?.firstName
          }

          if (item?.status === 0) {
            item.block_status = (
              <>
                <i
                  onClick={() => handleStatus(item._id)}
                  style={{
                    fontSize: "20px",
                    color: "green",
                    cursor: "pointer",
                  }}
                  className="uil-lock-alt"
                ></i>
              </>
            )
          } else if (item?.status === 2) {
            item.block_status = (
              <>
                <i
                  onClick={() => handleStatus(item._id)}
                  style={{ fontSize: "20px", color: "red", cursor: "pointer" }}
                  className="uil-lock-open-alt"
                ></i>
              </>
            )
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <i
                className="fas fa-key"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  // setShowModal(true);
                  // setUserIdToBeUpdated(item._id);
                }}
              ></i> */}
              {options.view && (
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    navigate(`/user/${item.userId}`, {
                      state: { id: item._id },
                    })
                  }}
                ></i>
              )}

              {options.edit && (
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.5em",
                  }}
                  onClick={() => {
                    toTop()
                    handleUpdate(item._id)
                  }}
                ></i>
              )}
              {options.delete && (
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "1em", cursor: "pointer" }}
                  onClick={() => {
                    handleDelete(item._id)
                  }}
                ></i>
              )}
            </div>
          )

          return item
        })
        setTableData(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleUpdate(id) {
    get(`user/single?user_id=${id}`)
      .then(res => {
        const data = res.user
        setSelectedModule({
          value: data?.modules?._id || null,
          label: data?.modules?.moduleName || null,
        })

        setSelectedPrivilege({
          value: data?.privilage?._id,
          label: data?.privilage?.privilege_name,
        })

        setSelectedBranch({
          value: data?.branch?._id,
          label: data?.branch?.name,
        })

        data.id = data?._id
        data.privilage = data?.privilage?._id
        data.modules = data?.modules?._id
        data.branch = data?.branch?._id
        delete data.createdAt
        delete data.date
        delete data.updatedAt
        delete data.__v
        setUserObject(data)
      })
      .catch(err => {
        console.log(err)
      })
  }

  function handleStatus(user_id) {
    put(`user/status?user_id=${user_id}`)
      .then(res => {
        handleTableData()
        toastr.success(res.message)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name1",
        sort: "asc",
        width: 400,
      },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 100,
      },
      {
        label: "Privilege",
        field: "privilage1",
        sort: "asc",
        width: 150,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 100,
      },
      {
        label: "Status",
        field: "block_status",
        sort: "asc",
        width: 50,
      },

      {
        label: "Action",
        field: "action",
        width: 300,
      },
    ],
    rows: tableData,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Users" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleSubmit()
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">First name</Label>
                          <AvField
                            name="firstName"
                            placeholder="First name"
                            type="text"
                            errorMessage="Enter First Name"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom01"
                            value={userObject.firstName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Last name</Label>
                          <AvField
                            name="lastName"
                            placeholder="Last name"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            value={userObject.lastName || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Email</Label>
                          <AvField
                            name="email"
                            placeholder="Email"
                            type="email"
                            errorMessage="Enter valid Email"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Username</Label>
                          <AvField
                            name="username"
                            placeholder="Username"
                            type="text"
                            errorMessage="Enter valid Username"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom03"
                            value={userObject.username || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom04">Mobile</Label>
                          <AvField
                            name="mobile"
                            placeholder="Mobile"
                            type="number"
                            errorMessage="Please provide a valid mobile."
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom04"
                            value={userObject.mobile || ""}
                            onChange={handleChange}
                            minLength={10}
                            maxLength={10}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">DOB</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="user_dob"
                            name="user_dob"
                            value={userObject?.user_dob || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            name="branche"
                            value={selectedBranch}
                            onChange={value => {
                              setSelectedBranch(value)
                              setUserObject(pre => ({
                                ...pre,
                                branch: value.value,
                              }))
                            }}
                            options={branches}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Privilege</Label>
                          <Select
                            name="privilage"
                            value={selectedPrivilege}
                            onChange={value => {
                              setSelectedPrivilege(value)
                              setUserObject(pre => ({
                                ...pre,
                                privilage: value.value,
                              }))
                            }}
                            options={privileges}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>

                      {!userObject.id && (
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom05">Password</Label>
                            <AvField
                              name="password"
                              placeholder="Password"
                              type="password"
                              errorMessage=" Please provide a valid password"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom05"
                              value={userObject.password || ""}
                              onChange={handleChange}
                            />
                          </div>
                        </Col>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Address</Label>
                          <AvField
                            name="address"
                            type="textarea"
                            className="form-control"
                            id="validationCustom03"
                            rows={1}
                            value={userObject.address || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom03">Image</Label>
                          {userObject?.user_image ? (
                            <div div className="img-wraps d-flex flex-column">
                              {userObject.user_image &&
                              userObject.user_image.startsWith("http") ? (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${userObject.user_image}`}
                                />
                              ) : (
                                <img
                                  alt=""
                                  width="150"
                                  height="150"
                                  src={`${API_URL}${userObject.user_image}`}
                                />
                              )}
                              <button
                                className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                onClick={() => {
                                  setUserObject(pre => ({
                                    ...pre,
                                    user_image: null,
                                  }))
                                }}
                                style={{ width: "150px" }}
                                type="button"
                              >
                                Delete
                              </button>
                            </div>
                          ) : (
                            <AvField
                              name="user_image"
                              type="file"
                              className="form-control"
                              id="validationCustom03"
                              onChange={uploadImage}
                              rows="1"
                            />
                          )}
                        </div>
                      </Col>

                      <Col md={3}>
                        <div className="mb-3">
                          <Label>Default Module</Label>
                          <Select
                            value={selectedModule}
                            onChange={value => {
                              setSelectedModule(value)
                              setUserObject(pre => ({
                                ...pre,
                                modules: value.value,
                              }))
                            }}
                            options={modules}
                            classNamePrefix="select2-selection"
                          />
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          ></p>
                        </div>
                      </Col>

                      <Col
                        md="1"
                        className="mt-4"
                        style={{ paddingTop: "5px" }}
                      >
                        <Button
                          color={`${userObject.id ? "warning" : "primary"}`}
                          type="submit"
                        >
                          {userObject.id ? "Update" : "Submit"}
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="user-table"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserD
