import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"
// import "./FeedbackChoice.scss"
function Feedbackform() {
  // const customStyles = {
  //   lineHeight: "1.8",
  // }
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)
  // const customStyles1 = {
  //   // borderColor: "#e5e5e5",
  //   height: "auto",
  //   border: "1px solid #ced4da",
  // }

  const [Choice, setChoice] = useState([
    { question: "", options: [""], correctAnswer: "" },
  ])

  const handleRemove = indexToRemove => {
    const newValues = [
      ...Choice.slice(0, indexToRemove),
      ...Choice.slice(indexToRemove + 1),
    ]

    setChoice(newValues)
  }

  const addMore = () => {
    setChoice([...Choice, { question: "", options: [""], correctAnswer: "" }])
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        width: 150,
      },

      {
        label: "Type",
        field: "Type",
        width: 150,
      },
      {
        label: "Active",
        field: "active",
        width: 150,
      },
      {
        label: "Created By",
        field: "Createdby",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        name: (
          <div style={{ textAlign: "center" }}>
            <i
              style={{ color: "green", textAlign: "center" }}
              class="fas fa-check"
            ></i>
          </div>
        ),
        mobile: (
          <div style={{ textAlign: "center" }}>
            <i
              style={{ color: "green", textAlign: "center" }}
              class="fas fa-check"
            ></i>
          </div>
        ),
        email: (
          <div style={{ textAlign: "center" }}>
            <i
              style={{ color: "green", textAlign: "center" }}
              class="fas fa-check"
            ></i>
          </div>
        ),

        id: "1",
        Name: "We'd love to hear from you!",
        Title: "We'd love to hear from you",
        Type: "Yes/No",
        Active: "admin",
        Createdby: "N/A",
        Createddate: "01-Jan-2023",
        Modifyby: "Admin",
        Modifydate: "01-march-2023",
        active: (
          <>
            <input type="checkbox" disabled="" checked="checked"></input>
          </>
        ),

        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.5rem",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="uil-edit-alt"
              style={{
                fontSize: "1.2em",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            ></i>
            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const [questionType, setQuestionType] = useState([])

  const HandleSelectChange = (selectedType, idx) => {
    const updatedQuestionType = [...questionType]
    updatedQuestionType[idx] = selectedType
    setQuestionType(updatedQuestionType)
  }

  const [questions, setQuestions] = useState([{}])

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Survey Form" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Survey Name</Label>
                          <AvField
                            name="feedbackname"
                            className="form-control"
                            type="text"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Sub Title</Label>
                          <AvField
                            name="feedbackname"
                            className="form-control"
                            type="text"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>

                      <Col md="1" style={{ paddingTop: "35px" }}>
                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox1"
                          />
                          <label className="myname" htmlFor="myCheckbox1">
                            Name
                          </label>
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "35px" }}>
                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox2"
                          />
                          <label className="myname" htmlFor="myCheckbox2">
                            Mobile
                          </label>
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "35px" }}>
                        <div
                          className="form-check pt"
                          style={{ display: "flex", gap: "3px" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input mycheckbox"
                            id="myCheckbox3"
                          />
                          <label className="myname" htmlFor="myCheckbox3">
                            Email
                          </label>
                        </div>
                      </Col>

                      {questions.map((row, idx) => (
                        <Row key={idx}>
                          <>
                            <Col md="4">
                              <div className="mb-3">
                                <Label>Question {idx + 1}</Label>
                                <AvField
                                  name="feedbackname"
                                  className="form-control"
                                  rows={1}
                                  type="textarea"
                                  placeholder=""
                                  errorMessage=""
                                />
                              </div>
                            </Col>
                            <Col md="3">
                              <div className="mb-3">
                                <Label>Type</Label>
                                <Select
                                  onChange={selected => {
                                    const selectedType = selected.value // Assuming 'value' holds the selected type
                                    HandleSelectChange(selectedType, idx) // Passing selected type and idx to the function
                                  }}
                                  options={[
                                    { label: "Yes/No", value: 0 },
                                    { label: "Rating", value: 1 },
                                    { label: "Emoji Rating", value: 2 },
                                    { label: "Multiple Choices", value: 3 },
                                    { label: "Fill in the Blanks ", value: 4 },
                                  ]}
                                  classNamePrefix="select2-selection"
                                />
                              </div>
                            </Col>

                            {questionType[idx] === 3 ? (
                              <>
                                <Row>
                                  {[...Array(8)].map((_, optionIndex) => (
                                    <Col md={3}>
                                      <Label for={`option-${optionIndex}`}>
                                        Option {optionIndex + 1}
                                      </Label>
                                      <AvField
                                        key={optionIndex}
                                        type="text"
                                        name={`option-${idx}-${optionIndex}`}
                                        id={`option-${idx}-${optionIndex}`}
                                        placeholder={`Option ${
                                          optionIndex + 1
                                        }`}
                                        className="option-input"
                                      />
                                    </Col>
                                  ))}
                                </Row>
                              </>
                            ) : null}

                            <Col>
                              {questions.length > 1 && (
                                <div className="mt-4 d-flex justify-content-start">
                                  <span
                                    className="mr-1 p-2"
                                    style={{
                                      fontSize: "1.125rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      const data = [...questions]
                                      data.splice(idx, 1)
                                      setQuestions(data)
                                    }}
                                  >
                                    <i className="trash-btn mdi mdi-delete"></i>
                                  </span>
                                </div>
                              )}
                            </Col>
                          </>
                        </Row>
                      ))}
                      <Col md={12}>
                        <div className="d-flex justify-content-between align-items-center">
                          <button
                            onClick={() => {
                              const data = [...questions]
                              data.push({})
                              setQuestions(data)
                            }}
                            className="waves-effect btn btn-outline-light d-flex"
                            style={{ gap: "5px" }}
                          >
                            Add more{" "}
                            <i className="mdi mdi-plus-circle-outline"></i>
                          </button>
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "20px" }}>
                          <Button
                            color="primary"
                            style={{ marginRight: "10px" }}
                            type="submit"
                          >
                            Submit
                          </Button>

                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="FeedbackformId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6>Survey Details</h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                  <th style={{ width: "320px", textAlign: "left" }}>
                    Question
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>Rating</td>
                  <td style={{ textAlign: "left" }}>
                    How do you rate the overall experience
                  </td>
                </tr>

                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>
                    How likely do you rate our staff
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>
                    how do you feel about the class
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Yes/No</td>
                  <td style={{ textAlign: "left" }}>
                    would you recommend ASAP to your friends
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Fill in the blank</td>
                  <td style={{ textAlign: "left" }}>
                    Please provide your sugges tions
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Feedbackform
