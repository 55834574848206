import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
} from "reactstrap"
import ReactApexChart from "react-apexcharts"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import moment from "moment"
import MiniWidget from "./mini-widget"
import BarChart from "./barchart"

import "./sentimentSummary.scss"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function SentimentSummary() {
  const [sentimentCount, setSentimentCount] = useState()
  const [sentimentCountGrouped, setSentimentCountGrouped] = useState()
  const [sentimentCountPercentageGrouped, setSentimentCountPercentageGrouped] =
    useState()

  function fetchSentimentCount() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/sentiment/count?fromDate=${fromDate}&toDate=${ToDate}`

    axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
      let result = res?.data?.data
      setSentimentCount(result)
    })
  }

  function fetchSentimentCountGrouped() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/sentiment/countGrouped?fromDate=${fromDate}&toDate=${ToDate}`

    axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
      let result = res?.data?.data
      setSentimentCountGrouped(result)
    })
  }

  function fetchSentimentCountPercentageGrouped() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/sentiment/countPercentageGrouped?fromDate=${fromDate}&toDate=${ToDate}`

    axios.get(url, { headers: { "x-access-token": accessToken } }).then(res => {
      let result = res?.data
      setSentimentCountPercentageGrouped(result)
    })
  }

  useEffect(() => {
    fetchSentimentCount()
    fetchSentimentCountGrouped()
    fetchSentimentCountPercentageGrouped()
  }, [])

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  // bar chart1
  const series = sentimentCount || ""

  const option1 = {
    chart: {
      type: "bar",
      height: 600,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: ["Kial"],
    },
    colors: ["#34c38f", "#f46a6a", "#FFCD69"],
  }

  // bar chart2
  const series2 = sentimentCountGrouped?.series || ""

  const option2 = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },

    colors: ["#34c38f", "#f46a6a", "#FFCD69"],

    grid: {
      borderColor: "#f1f1f1",
    },
    xaxis: {
      categories: sentimentCountGrouped?.categories,
    },
  }

  const series5 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
    },
  ]

  const options5 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const series6 = [70]

  const options6 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series7 = [55]

  const options7 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series8 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
    },
  ]

  const options8 = {
    fill: {
      colors: ["#f1b44c"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }
  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total ",
      value:
        sentimentCount?.[0]?.data[0] +
        sentimentCount?.[1]?.data[0] +
        sentimentCount?.[2]?.data[0],
      prefix: "",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series5,
      options: options5,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Positive",
      value: sentimentCount?.[0]?.data[0],
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series6,
      options: options6,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Negative",
      value: sentimentCount?.[1]?.data[0],
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "6.24%",
      color: "danger",
      desc: "since last week",
      series: series7,
      options: options7,
    },
    {
      id: 4,
      icon: "mdi mdi-arrow-up-bold",
      title: "Neutral",
      value: sentimentCount?.[2]?.data[0],
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "10.51%",
      color: "success",
      desc: "since last week",
      series: series8,
      options: options8,
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Sentiment Report</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row>
            <MiniWidget reports={reports} />

            {/* <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">
                      {sentimentCount?.[0]?.data[0] +
                        sentimentCount?.[1]?.data[0] +
                        sentimentCount?.[2]?.data[0]}
                    </span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Total</h4>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[0]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Positive</h4>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[1]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Negative</h4>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* <Col md="3">
              <Card>
                <CardBody>
                  <div class="contbox">
                    <span class="mainval">{sentimentCount?.[2]?.data[0]}</span>
                  </div>
                  <div class="hdbox">
                    <h4 class="title1">Neutral</h4>
                  </div>
                </CardBody>
              </Card>
            </Col> */}

            {/* bar 1*/}

            {/* <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Bar Chart</h4>
                  <BarChart />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <h4 className="card-title mb-4">Bar Chart</h4>
                  <BarChart />
                </CardBody>
              </Card>
            </Col> */}

            <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-reset"
                        id="dropdownMenuButton5"
                        caret
                        href="#"
                      >
                        <span className="fw-semibold">Sort By:</span>{" "}
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Monthly</DropdownItem>
                        <DropdownItem href="#">Yearly</DropdownItem>
                        <DropdownItem href="#">Weekly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <h5>Sentiment Breakup</h5>
                  {/* <div style={{ height: "400", width: "600" }}> */}
                  <ReactApexChart
                    height="260"
                    options={option1}
                    series={series}
                    type="bar"
                    className="apex-charts"
                  />
                  {/* </div> */}
                </CardBody>
              </Card>
            </Col>

            {/* bar 2*/}
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-reset"
                        id="dropdownMenuButton5"
                        caret
                        href="#"
                      >
                        <span className="fw-semibold">Sort By:</span>{" "}
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Monthly</DropdownItem>
                        <DropdownItem href="#">Yearly</DropdownItem>
                        <DropdownItem href="#">Weekly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  <h5>Sentiment Breakup Platform Wise</h5>
                  <ReactApexChart
                    options={option2}
                    series={series2}
                    type="bar"
                    className="apex-charts"
                    height="260"
                  />
                </CardBody>
              </Card>
            </Col>

            {/* pie chart1*/}

            {sentimentCountPercentageGrouped?.data?.map((item, index) => {
              return (
                <Col md="6">
                  <Card>
                    <CardBody>
                      <div className="float-end">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="text-reset"
                            id="dropdownMenuButton5"
                            caret
                            href="#"
                          >
                            <span className="fw-semibold">Sort By:</span>{" "}
                            <span className="text-muted">
                              Yearly
                              <i className="mdi mdi-chevron-down ms-1"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#">Monthly</DropdownItem>
                            <DropdownItem href="#">Yearly</DropdownItem>
                            <DropdownItem href="#">Weekly</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <h5>{item.socialMedia}</h5>
                      <ReactApexChart
                        type="pie"
                        height="200"
                        className="apex-charts"
                        options={{
                          labels: item.labels,
                          colors: ["#34c38f", "#f46a6a", "#FFCD69"],

                          legend: {
                            show: !0,
                            position: "right",
                            horizontalAlign: "center",
                            verticalAlign: "middle",
                            floating: !1,
                            fontSize: "14px",
                            offsetX: 0,
                          },
                          responsive: [
                            {
                              breakpoint: 600,
                              options: {
                                chart: {
                                  height: 400,
                                },
                                legend: {
                                  show: !1,
                                },
                              },
                            },
                          ],
                        }}
                        series={item.data}
                      />
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SentimentSummary
