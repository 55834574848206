import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap"
import ReactEcharts from "echarts-for-react"
import { Bar, Line } from "react-chartjs-2"
import "./dashboardSummary.scss"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import ReactApexChart from "react-apexcharts"
import LineApexChart from "./chartapex.js"
import moment from "moment"
import MiniWidget from "./mini-widget"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function DashboardSummary() {
  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  const [summaryCardData, setSummaryCardData] = useState()
  const [sentimentData, setSentimentData] = useState()
  const [volumeCountTotal, setVolumeCountTotal] = useState()

  const fromDate = moment(startDate).format("YYYY-MM-DD")
  const ToDate = moment(endDate).format("YYYY-MM-DD")

  function fetchSummaryCardData() {
    var url = `${API_URL}report/v2/summary/cards?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setSummaryCardData(result)
      })
  }

  function fetchSentimentData() {
    var url = `${API_URL}report/v2/sentiment/countGrouped?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setSentimentData(result)
      })
  }

  function fetchVolumeCountTotal() {
    var url = `${API_URL}report/v2/volume/countTotal?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data
        setVolumeCountTotal(result)
      })
  }

  useEffect(() => {
    fetchSummaryCardData()
    fetchSentimentData()
    fetchVolumeCountTotal()
  }, [])

  // gauge
  const getOption = () => {
    return {
      tooltip: {
        formatter: "{a} <br/>{b} : {c}%",
      },
      toolbox: {
        feature: {
          restore: {},
          saveAsImage: {},
        },
      },
      series: [
        {
          name: "Tonality",
          type: "gauge",
          detail: { formatter: "{value}%" },
          data: [{ value: 0.47, name: "Neutral" }],
          axisLine: {
            lineStyle: {
              color: [
                [0.4, "rgba(254, 45, 45)"],
                [0.7, "rgb(254,248,76)"],
                [1, "rgb(113,206,126)"],
              ],
              width: 20,
            },
          },
          axisLabel: {
            show: true,
            formatter: function (value) {
              // Custom formatting for the axis label
              return (value / 100).toFixed(1)
            },
          },
        },
      ],
    }
  }

  // bar chart1
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  const backgroundColors = ["#13b74ae3", "#ec4561", "#FFCD69"]

  const data1 = {
    labels: sentimentData?.categories,

    datasets: sentimentData?.series.map((item, index) => ({
      label: item.name,
      data: item.data,
      backgroundColor: backgroundColors[index],
      stack: "Stack 0",
    })),
  }

  // Line chart4
  const lineData1 = {
    labels: volumeCountTotal?.labels,
    datasets: [
      {
        label: "Kial",
        data: volumeCountTotal?.data,
        fill: false,
        backgroundColor: "rgba(91, 140, 232, 0.2)",
        borderColor: "#5b73e8",
        borderCapStyle: "butt",
      },
    ],
  }

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 80, // Set the minimum value for the y-axis
            // max: 180,
            stepSize: 20, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }
  const series1 = [50]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const reports = summaryCardData?.map(item => {
    return {
      id: 1,
      icon:
        item.difference < 0
          ? "mdi mdi-arrow-down-bold"
          : "mdi mdi-arrow-up-bold",
      icon2: item.iconClassName,
      title: item.metricName,
      value: item.latestValue,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 60,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: Math.abs(item.percentageDifference) + "%",
      color: item.percentageDifference < 0 ? "danger" : "success",
      desc: "since last month ",
      series: series1,
      options: options2,
    }
  })


   const series10 = [
    {
      name: "Facebook",
      data: [24, 114, 68, 109, 53, 22],
    },
    {
      name: "Twitter",
      data: [0, 0, 0, 28, 136, 39],
    },
    {
      name: "Instagram",
      data: [23, 51, 18, 65, 33, 10],
    },
  ]

  const options10 = {
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    // series: series,
    colors: ["#5b73e8", "#f1b44c", "#98d8a9"],
    xaxis: {
      type: "yearmonth",
      // categories: [year + "-01", year + "-02", year + "-03", year + "-04", year + "-05", year + "-06", year + "-07", year + "-08", year + "-09", year + "-10", year + "-11", year + "-12"],
      categories: [
        "2023-09",
        "2023-10",
        "2023-11",
        "2023-12",
        "2024-01",
        "2024-02",
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "yyyy-mm",
      },
    },
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Dashboard Summary</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>
          <Row>
            {/* <MiniWidget reports={reports} /> */}
            {summaryCardData != null && <MiniWidget reports={reports} />}
          </Row>
          {/* <Row style={{ rowGap: "20px" }}>
            {summaryCardData?.map(item => (
              <Col md="3">
                <Card>
                  <CardBody>
                    <div class="contbox">
                      <span class="mainval">
                        <i
                          class={`fa ${
                            item.difference < 0
                              ? "fa-angle-down"
                              : "fa-angle-up"
                          }`}
                        ></i>
                        {Math.abs(item.difference)}{" "}
                      </span>
                      <span>({item.percentageDifference}%)</span>
                    </div>
                    <div class="hdbox">
                      <h4 class="title1">
                        <i
                          class={`fab ${item.iconClassName} icon-dashboard-2`}
                        ></i>{" "}
                        {item.metricName}
                      </h4>
                      <h4
                        class="title1"
                        style={{ textAlign: "end", marginTop: "-24px" }}
                      >
                        {item.latestValue}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row> */}

          <Row style={{ rowGap: "20px" }}>
            {/* Gauge Chart */}
            {/* <Col md="4">
              <Card>
                <CardBody>
                  <h5>Tonality</h5>
                  <ReactEcharts height={200} option={getOption()} />
                </CardBody>
              </Card>
            </Col> */}
           <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    {/* <h4 className="card-title mb-4">DAily Followers Count</h4> */}
                    <LineApexChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>
         
            <Col md="12" className="d-none">
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h5>Daily Total Volume</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData1}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>
            {/* bar chart1 */}
            <Col md="8">
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h5>Sentiment Breakup By Media</h5>
                  <Bar
                    width={474}
                    height={200}
                    options={options1}
                    data={data1}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart2 */}
            <Col md="4">
              <Card>
                <CardBody>
                  <h5>News</h5>
                  <Bar width={474} height={430} />
                </CardBody>
              </Card>
            </Col>

            {/* line chart1 */}
          </Row>
        </div>
      </div>
    </>
  )
}

export default DashboardSummary
