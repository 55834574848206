import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import { MDBDataTable } from "mdbreact"

import "./Campaign.scss"

function Campaign() {
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const followup_data = {
    columns: [
      {
        label: "Product Name",
        field: "Name",
        sort: "asc",
        width: 150,
      },
      {
        label: "URL",
        field: "url",
        sort: "asc",
        width: 150,
      },
      {
        label: "Click",
        field: "clicks",
        sort: "asc",
        width: 150,
      },
      {
        label: "Lead",
        field: "lead",
        sort: "asc",
        width: 150,
      },
      {
        label: "Convension",
        field: "convensions",
        sort: "asc",
        width: 150,
      },
    ],
    rows: [
      {
        clicks: "16",
        Name: "product 1",
        lead: "4",
        convensions: "4",
        url: (
          <>
            <a href="/">https://new.example.com/docs/travel-documents</a>
          </>
        ),
      },
      {
        clicks: "16",
        Name: "product 2",
        lead: "4",
        convensions: "4",
        url: (
          <>
            <a href="/">https://new.example.com/docs/travel-documents</a>
          </>
        ),
      },
    ],
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Campaign",
        field: "track_variable",
        width: 150,
      },
      {
        label: "Short Code",
        field: "short_code",
        width: 150,
      },

      {
        label: "Clicks",
        field: "Clicks",
        width: 150,
      },
      {
        label: "Leads",
        field: "Leads",
        width: 150,
      },
      {
        label: "Budget",
        field: "Budget",
        width: 150,
      },
      {
        label: "Added by",
        field: "User",
        width: 150,
      },

      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],
    rows: [
      {
        id: "1",
        date: "15-03-2024",
        track_variable: "Maha Swagatam Scheme",
        short_code: "maha-swagatam-scheme",
        Clicks: "56",
        Leads: "7",
        Budget: "7500",
        User: "admin",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                // marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                // setPopupView(true)
                // setSelectedItem(item)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                // marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                // preUpdatename(item)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                // handleDelete(item._id)
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        date: "24-10-2023",
        track_variable: "Dream Home",
        short_code: "dream-home",
        Clicks: "46",
        Leads: "10",
        Budget: "5000",
        User: "admin",
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                // marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                // setPopupView(true)
                // setSelectedItem(item)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",
                // marginLeft: "0.5rem",
                marginRight: "0.5rem",
              }}
              onClick={() => {
                // preUpdatename(item)
              }}
            ></i>
            <i
              className="far fa-trash-alt"
              style={{ fontSize: "1em", cursor: "pointer" }}
              onClick={() => {
                // handleDelete(item._id)
              }}
            ></i>
          </div>
        ),
      },
    ],
  }
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage Campaign" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Campaign Name</Label>
                          <AvField
                            name="track_variable"
                            className="form-control"
                            type="text"
                            placeholder="Campaign Name"
                            errorMessage="Enter Campaign "
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Short Code</Label>
                          <AvField
                            name="short_code"
                            className="form-control"
                            type="text"
                            placeholder="Short Code"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Budget</Label>
                          <AvField
                            name="budget"
                            className="form-control"
                            type="text"
                            placeholder="Budget"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            className="mx-2"
                            color="primary"
                            type="submit"
                            // disabled={addingcountry ? true : false}
                          >
                            {"Submit"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="CampaignId"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>Facebook Campaign Details</h5>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        {/* {selectedItem && ( */}
        <div className="modal-body">
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <table className="table tbl-u-boarderd" id="followupmodalId">
                    {/* <tr>
                                                <td style={{ padding: "10px 0px" }}>
                                                    <b>Added Date</b>
                                                </td>
                                                <td>:  </td>
                                                <td>
                                                    <b>Track Variable</b>
                                                </td>
                                                <td>: </td>
                                                <td>
                                                    <b>Short code</b>
                                                </td>
                                                <td>: </td>


                                            </tr>
                                            <tr>
                                                <td>
                                                    <b>Budget</b>
                                                </td>
                                                <td>: </td>
                                                <td>
                                                    <b>Added By</b>
                                                </td>
                                                <td>: admin</td>
                                            </tr> */}

                    <tbody>
                      <tr>
                        <th>Added Date</th>
                        <th>:</th>
                        <td>24-01-2023</td>

                        <th>Track Variable</th>
                        <th>:</th>
                        <td>Facebook</td>

                        <th>Short Code</th>
                        <th>:</th>
                        <td>fb</td>
                      </tr>

                      <tr>
                        <th>Budget</th>
                        <th>:</th>
                        <td>5000</td>

                        <th>Added By</th>
                        <th>:</th>
                        <td>admin</td>
                      </tr>
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
            <Col md="12" lg="12">
              <div className="table-responsive">
                <h5 class="font-size-16 mb-3">Track Click Details</h5>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Clicks</th>
                      <th>Leads</th>
                      <th>Customer</th>
                      <th>Cost per Click</th>
                      <th>Cost per lead</th>
                      <th>Cost per Customer</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Total</th>
                      <td>66</td>
                      <td>0</td>
                      <td>0</td>
                      <td>18.80</td>
                      <td>0.00</td>
                      <td>0.00</td>
                    </tr>
                    <tr>
                      <th>Kollam</th>
                      <td>26</td>
                      <td>0</td>
                      <td>0</td>
                      <td>18.80</td>
                      <td>0.00</td>
                      <td>0.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id="followup_Id"
                    responsive
                    bordered
                    data={followup_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        {/* )} */}
      </Modal>
    </>
  )
}

export default Campaign
