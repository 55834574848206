import React from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
import { Row, Col, } from "reactstrap";
import instagram from '../../../assets/fotos/62372-computer-neon-instagram-icons-hd-image-free-png.png'
import twitter from '../../../assets/fotos/twitter-transparent-logo-social-media.png'
import srv from '../../../assets/fotos/srv.dde4e690.jpg'
import { Link } from 'react-router-dom';

import "./Socialmonitor.scss"
function Socialmonitor() {
  return (
    <div>

        <div className='page-content'>
            <div className='container-fluid'>
                <Breadcrumbs title="Home" breadcrumbItem="Social Monitors" />

                <Row >
                    <Col className='' md="4" xl="4" lg="4" sm="6">

                        <div className='monithead'>

                            <div >
                                <img src="https://1.bp.blogspot.com/-S8HTBQqmfcs/XN0ACIRD9PI/AAAAAAAAAlo/FLhccuLdMfIFLhocRjWqsr9cVGdTN_8sgCPcBGAYYCw/s1600/f_logo_RGB-Blue_1024.png" alt="#" />

                                <h5>Mentions</h5>

                            </div>

                            <div className='moniticons'>
                                <Link to=""><i className='fas fa-trash'></i></Link>
                                <Link to=""><i className='fas fa-redo'></i></Link>
                            </div>

                        </div>

                        <div className='monitors'>
                            {/*  */}


                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img className='image_content' src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>


                            {/*  */}
                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>

                        </div>


                    </Col>



                    {/*  */}

                    <Col className='' md="4" xl="4" lg="4" sm="6">
                        <div className='monithead'>

                            <div >
                                <img src={instagram} alt="#" />

                                <h5>Mentions</h5>

                            </div>

                            <div className='moniticons'>
                                <Link to=""><i className='fas fa-trash'></i></Link>
                                <Link to=""><i className='fas fa-redo'></i></Link>
                            </div>

                        </div>

                        <div className='monitors'>
                            {/*  */}

                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>


                            {/*  */}
                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>



                        </div>


                    </Col>

                    <Col className='' md="4" xl="4" lg="4" sm="6">


                        <div className='monithead'>

                            <div >
                                <img src={twitter} alt="#" />

                                <h5>Mentions</h5>

                            </div>

                            <div className='moniticons'>
                                <Link to=""><i className='fas fa-trash'></i></Link>
                                <Link to=""><i className='fas fa-redo'></i></Link>
                            </div>

                        </div>
                        <div className='monitors'>

                            {/*  */}

                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>


                            {/*  */}
                            <div className='monitbody'>
                                <div className='profile'>
                                    <span class="fab ">V</span>
                                    {/* <i ><img src="" alt="" /></i> */}
                                    {/* <i className='fab fa-facebook'></i> */}
                                </div>

                                <div>

                                    <ul >
                                        <li className='monitname'><h5>Vikram</h5> <span style={{ fontStyle: "italic", paddingLeft: "2px" }}>@srvinfotech</span> </li>
                                        <li><span>09 May 2020 05:35PM</span></li>
                                    </ul>



                                </div>

                            </div>
                            <p>#NewsUpdate | The Chennai-headquartered lender said the MCLR for the benchmark one-year tenor loan has been cut by 0.10 per cent to 8.15 per cent, with effect from May 10. State-owned Indian Overseas Bank and Bank of Maharashtra on Wednesday announced a reduction in their marginal cost of funds based lending rate (MCLR). "Our Bank has revised the MCLR w.e.f 10.05.2020 until further review," Indian Overseas Bank said in a regulatory filing.</p>
                            <img src={srv} alt="" />


                            <div className='monitdownbar'>
                                <div><span style={{ color: "#1111aa" }} className='far fa-thumbs-up'>26</span></div>
                                <div ><span>26 Comments</span> <span>8 Shares</span></div>
                            </div>


                            <div className='monitfooter'>

                                <Link to=""><i className='fas fa-thumbs-up '> Like</i></Link>

                                <Link to=""><i className='fas fa-comment-dots '> Comment</i></Link>



                            </div>



                        </div>


                    </Col>
                </Row>





            </div>
        </div>



    </div>
)
}

export default Socialmonitor