import {
  GET_MODULE,
  GET_MODULE_SUCCESSFUL,
  GET_MODULE_FAILED,
} from "./actionTypes"

export const getModule = data => {
  return {
    type: GET_MODULE,
    payload: data,
  }
}

export const getModuleSuccessful = data => {
  return {
    type: GET_MODULE_SUCCESSFUL,
    payload: data.modules,
  }
}

export const getModuleFailed = data => {
  return {
    type: GET_MODULE_FAILED,
    payload: data,
  }
}
