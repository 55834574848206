import React, { useState } from 'react'
import Breadcrumbs from '../../../components/Common/Breadcrumb'
// import Reviewbar from './Reviewbar'






import stars from '../../../assets/fotos/Frame 8.svg'
import calender from '../../../assets/fotos/Group 44.svg'
import srv1 from '../../../assets/fotos/Rectangle 7.svg'
import srv2 from '../../../assets/fotos/Rectangle 8.svg'
import srv3 from '../../../assets/fotos/Rectangle 9.svg'
import playstore from '../../../assets/fotos/Group.svg'
import google from '../../../assets/fotos/Group 8.svg'
import apple from '../../../assets/fotos/_Group_3.svg'
import business from '../../../assets/fotos/Group 31.svg'
import bing from '../../../assets/fotos/Group 35.svg'
import comment from '../../../assets/fotos/Group 36.svg'
import star from '../../../assets/fotos/Group 37.svg'

import { Button } from "reactstrap";
import { Link } from 'react-router-dom' 
import Dropdown from 'react-bootstrap/Dropdown';

function Reviewreply() {

    const customStyles1 = {
        // borderColor: "#e5e5e5",
        height: "auto",
        border: "1px solid #ced4da"
    };

    const [open, setOpen] = useState(1)
    function openClick(num) {
        setOpen(num);
       
    }

    const [replyText, setReplyText] = useState('');

    function handleClick(event) {
        setReplyText(event.target.textContent);
    }
    return (
        <div>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Reviews" />

                    <div className='reviews'>
                            <ul className='platforms' >

                                <li onClick={() => openClick(1)} className={`${open === 1 ? "active" : ""}`}>
                                    <div>
                                        <span className='fa fa-globe'></span>
                                        <span className='mainfont'>All</span>

                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li onClick={() => openClick(2)} className={`${open === 2 ? "active" : ""}`}>
                                    <div >


                                        <span className='fa'><img style={{ height: "20px" }} src={google} alt="" /></span>
                                        <span className='mainfont'>Google Reviews</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(3)} className={`${open === 3 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={playstore} alt="" /></span>
                                        <span className='mainfont'>Play Store</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(4)} className={`${open === 4 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={apple} alt="" /></span>
                                        <span className='mainfont'>App Store</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(5)} className={`${open === 5 ? "active" : ""}`}>
                                    <div >
                                        <span className='fa'><img style={{ height: "20px" }} src={business} alt="" /></span>
                                        <span className='mainfont'>Google Business</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                                <li onClick={() => openClick(6)} className={`${open === 6 ? "active" : ""}`}>
                                    <div>
                                        <span className='fa'><img style={{ height: "20px" }} src={bing} alt="" /></span>
                                        <span className='mainfont'>Bing</span>


                                        <ul className='ikon'>

                                            <li>
                                                <img className='subikon' src={comment} alt="" /> 371
                                            </li>
                                            <li><img className='subikon' src={star} alt="" /> 4.9
                                            </li>
                                        </ul>


                                    </div>
                                </li>

                            </ul>



                            <div className='reviewbar '>

                                <Dropdown className='dropdown0'>
                                    <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                        <span class="fa fa-angle-down"></span> Recent First
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <div class="input-group" style={{ width: "auto" }}>
                                    <input type="text" className='form-control' placeholder='Search For Reviews.' />
                                    <Button color="info" type="submit" style={{ backgroundColor: "#1a71b5" }}>
                                        Search
                                    </Button>
                                </div>




                                <div className='revnextpage' >

                                    <div class="pagination">
                                        <Link to="">&laquo;</Link>
                                        <Link to="" href="#">1</Link>
                                        <Link to="" class="nextpage2" href="#">2</Link>
                                        <Link to="" href="#" className='nextcontinue'>....</Link>
                                        <Link to="" href="#">20</Link>
                                        <Link to="" href="#">&raquo;</Link>
                                    </div>


                                </div>



                                <div className='revpagecontent'>
                                    page <div>0</div> of  20
                                </div>

                                <div className='revpagelist' >
                                    <div>Show items</div>

                                    <Dropdown className=''>
                                        <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                            <span class="fa fa-angle-down"></span> 0
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>


                                </div>


                            </div>







                        </div>

                    <div className='reviewbox'>
                        <div className='revlist revlist1'>
                            <div className='revhead'>

                                <div className='captions'>
                                    <div className='profile'>
                                        <span class="fab ">V</span>
                                        <i ><img src={google} alt="" /></i>
                                    </div>

                                    <div className='revname'>

                                        <div className='revtitle'>Virat Kohli</div>
                                        <span>20 reviews  3 photos</span>
                                        <div><img src={stars} alt="" /></div>
                                    </div>

                                </div>

                                <div className='date'>
                                    <span class="fa "> <img src={calender} alt="" /> </span>
                                    30/04/2020, 05:56

                                    <div style={{ marginTop: "10px" }}>
                                        <Dropdown  >
                                            <Dropdown.Toggle className='butn1 dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                                Positive
                                                <span class="fa fa-angle-down"></span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item href="#/action-1">Neutral</Dropdown.Item>
                                                <Dropdown.Item href="#/action-2">Positive</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Negative</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    </div>

                                </div> 



                            </div>
                            <div className='revcontent'>

                                <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Similique minima dolores tempora culpa facere officiis impedit quibusdam necessitatibus perspiciatis eveniet quasi alias totam quam odio recusandae, nam esse sint nihil. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolorem illum, vitae, repudiandae odio fugit aliquid aperiam quisquam fugiat officia ea fuga. Magni reprehenderit maiores ut voluptatem perspiciatis veritatis blanditiis. Sapiente.</div>

                                <div className='revimage'>
                                    <img className='revimages' src={srv1} alt="" />
                                    <img className='revimages' src={srv2} alt="" />
                                    <img className='revimages' src={srv3} alt="" />

                                </div>
                            </div>

                            <div className='revfooter revfooter1'>
                                <div className='revtool'>

                                    <div> <span className='far fa-thumbs-up'></span> Like</div>                                    
                                    <div><span className='far fa-edit'></span> Edit</div>

                                </div>

                                <div className='revtool'>
                                    <div><span className='fas fa-plus-circle'></span> Lead</div>
                                    <div><span className='fas fa-plus-circle'></span> Response</div>
                                </div>
                            </div>
                        </div>

                        {/*  */}
                        <div className='revreplyarea'>


                            <textarea value={replyText} onChange={(e) => setReplyText(e.target.value)} style={customStyles1} className='form-control' placeholder='Reply..' name="" id="" ></textarea>
                            <div style={{ color: "#666666bd", marginTop: "5px" }} >Respond to review with a suggested reply. You can edit your reply before sending.</div>



                            <ul className='revreplycontent' >
                                <li >
                                    <button onClick={(e) => handleClick(e)} className='replycontent'>Thank you for your valuable review..</button>
                                </li>
                                <li >
                                    <button onClick={(e) => handleClick(e)}  className='replycontent'>We are sorry to know hear this from you. We’ll solve your issue soon.</button>
                                </li>
                                <li >
                                    <button onClick={(e) => handleClick(e)}  className='replycontent'>We’re glad to hear this from you. Thank you!!</button>
                                </li>
                                <li >
                                    <button onClick={(e) => handleClick(e)}  className='replycontent'>Feeling great. Thank you!!</button>
                                </li>
                                <li >
                                    <button onClick={(e) => handleClick(e)}  className='replycontent'>Thank you!.. We’ll be always here for your support..</button>
                                </li>
                                <li >
                                    <button onClick={(e) => handleClick(e)}  className='replycontent'>Sorry!.. Please contact us and share the details.</button>
                                </li>
                            </ul>

                            <div className='replybutton'>

                                <button className='replybutn'>Discard</button>
                                <button className='replybutn1'>Publish Reply</button>

                            </div>




                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Reviewreply