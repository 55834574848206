import React from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Row } from "reactstrap"
import { MDBDataTable } from "mdbreact"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"

function TrackClicks() {
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Time",
        field: "time",
        width: 150,
      },
      {
        label: "Campaign URL",
        field: "Campaign",
        width: 150,
      },
      {
        label: "Campaign",
        field: "Name",
        width: 150,
      },
      {
        label: "Shortcode",
        field: "Shortcode",
        width: 150,
      },
    ],
    rows: [
      {
        id: "1",
        Campaign: (
          <>
            <a href="/">https://bankofmaharashtra.in/maha-swagatam-scheme</a>
          </>
        ),
        date: "15-03-2024",
        time: "8:03pm",
        Shortcode: "maha-swagatam-scheme",
        Name: "Maha Swagatam Scheme",
      },
      {
        id: "2",
        Campaign: (
          <>
            <a href="/">https://bankofmaharashtra.in/dream-home</a>
          </>
        ),
        date: "24-10-2023",
        time: "2:30pm",
        Shortcode: "dream-home",
        Name: "Dream Home",
      },
    ],
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Track Clicks/Leads" />

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <AvField
                            name="Domain"
                            className="form-control"
                            type="date"
                            placeholder="Domain Name"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <AvField
                            name="Description"
                            className="form-control"
                            type="date"
                            placeholder="Description"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Select name="Description" placeholder="Campaign" />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "" }}>
                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="Campaign1Id"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default TrackClicks
