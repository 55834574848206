import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { MDBDataTable } from "mdbreact"
import toastr from "toastr"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import SweetAlert from "react-bootstrap-sweetalert"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./index.scss"
import Dropdown from "react-bootstrap/Dropdown"
import Select from "react-select"

import { getAllInteractions } from "../../store/actions"
import { getDate, getFirstday } from "../../helpers/functions"

import axios from "axios"
import moment from "moment"
import io from "socket.io-client"

import accessToken from "../../helpers/jwt-token-access/accessToken"
import Cookies from "js-cookie"

const SocialListening = props => {
  const token = Cookies.get("token")

  const [filterObject, setFilterObject] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  })

  const [interactionIdToBeIgnored, setInteractionIdToBeIgnored] = useState(null)
  const [confirmIgnoreAlert, setConfirmIgnoreAlert] = useState(null)

  const [tableRows, setTableRows] = useState([])

  const { interactions } = useSelector(state => state.interactions)

  const dispatch = useDispatch()
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

  const [sourceOptions, setSourceOptions] = useState([])
  const [selectedSourceOption, setSelectedSourceOption] = useState(null)

  const [typeOptions, setTypeOptions] = useState([])
  const [selectedTypeOption, setSelectedTypeOption] = useState(null)

  const [selectedStatusOption, setSelectedStatusOption] = useState(null)

  const [selectedSentimentOption, setSelectedSentimentOption] = useState(null)

  const [departmentOptions, setDepartmentOptions] = useState([])
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null)

  // Socket.io logic
  useEffect(() => {
    const sockets = io(`${API_URL}?token=${token}`)

    sockets.on("interaction", () => {
      dispatch(getAllInteractions(filterObject))
    })

    return () => sockets.disconnect()
  }, [])

  useEffect(() => {
    getSourceOptions()
    getTypeOptions()
    getDepartmentOptions()
  }, [])

  useEffect(() => {
    dispatch(getAllInteractions(filterObject))
  }, [filterObject])

  const updateSentiment = (sentiment, item) => {
    const data = {
      id: item?._id,
      sentiment: sentiment,
    }

    axios
      .post(`${API_URL}twitter/updateSentiment`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        if (res.status === 200) {
          toastr.success(res.data.message)
          dispatch(getAllInteractions(filterObject))
        } else {
          toastr.error(res.data.message)
          return
        }
      })
  }

  const getSourceOptions = () => {
    axios.get(`${API_URL}listening/option/source`).then(res => {
      setSourceOptions(res?.data?.data || [])
    })
  }

  const getTypeOptions = () => {
    axios.get(`${API_URL}listening/option/type`).then(res => {
      setTypeOptions(res?.data?.data || [])
    })
  }

  const getDepartmentOptions = () => {
    axios.get(`${API_URL}listening/option/department`).then(res => {
      setDepartmentOptions(res?.data?.data || [])
    })
  }

  const resetFilter = () => {
    setFilterObject({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    })

    setSelectedSourceOption(null)
    setSelectedTypeOption(null)
    setSelectedStatusOption(null)
    setSelectedSentimentOption(null)
    setSelectedDepartmentOption(null)
  }

  const handleFilterInput = e => {
    const { name, value } = e.target

    setFilterObject(preVal => {
      return {
        ...preVal,
        [name]: value,
      }
    })
  }

  const handleTableData = () => {
    let tableRows = []

    interactions?.forEach((interaction, index) => {
      let tableRow = {}

      tableRow.id = <span className="table-col-id">{index + 1}</span>

      tableRow.date = (
        <p className="table-col-date">
          {moment(interaction.date).format("MMM DD")}
          <br></br>
          {moment(interaction.time, "HH:mm:ss").format("hh:mm a")}
        </p>
      )

      tableRow.source = (
        <div className="table-col-source">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                {interaction.sourceMainObj.displayName}
              </Tooltip>
            }
          >
            <img
              style={{ cursor: "pointer" }}
              src={`${API_URL}sourceMain/${interaction.sourceMainObj.iconFileName}`}
              height="20"
              alt={interaction.sourceMainObj.displayName}
            />
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                {interaction.sourceSubObj.displayName}
              </Tooltip>
            }
          >
            <img
              style={{ cursor: "pointer" }}
              src={`${API_URL}sourceSub/${interaction.sourceSubObj.iconFileName}`}
              height="20"
              alt={interaction.sourceSubObj.displayName}
            />
          </OverlayTrigger>
        </div>
      )

      tableRow.name = <span className="table-col-name">{interaction.authorName}</span>

      tableRow.text = (
        <div className="table-col-text">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                {interaction.text}
              </Tooltip>
            }
          >
            <div>
              {interaction.text.length > 40
                ? interaction.text.substring(0, 40) + "..."
                : interaction.text}
            </div>
          </OverlayTrigger>
        </div>
      )

      tableRow.sentiment = (
        <div className="table-col-sentiment">
          <Dropdown>
            <Dropdown.Toggle
              className="butn1 dropdown1"
              style={{
                backgroundColor: getColorForSentiment(interaction.sentiment),
                color: "white",
                border: "none", // Remove default border
              }}
              variant="info"
              id="dropdown-basic"
            >
              {interaction.sentiment}
              <span class="fa fa-angle-down"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => updateSentiment("Positive", interaction)}>
                Positive
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSentiment("Neutral", interaction)}>
                Neutral
              </Dropdown.Item>
              <Dropdown.Item onClick={() => updateSentiment("Negative", interaction)}>
                Negative
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )

      tableRow.status = (
        <p className="table-col-status">{getStatusNameFromStatus(interaction.status)}</p>
      )

      var globe_cursor = "not-allowed"
      var reply_cursor = "not-allowed"
      var hide_cursor = "not-allowed"
      var hide_class
      var hide_label
      var delete_cursor = "not-allowed"
      var ignore_cursor = "pointer"

      var isDeleted = interaction.infoOnDelete.isDeleted

      if (interaction.url) {
        globe_cursor = "pointer"
      }

      if (interaction.sourceActionObj.canReply) {
        reply_cursor = "pointer"
      }

      if (interaction.sourceActionObj.canHide) {
        hide_cursor = "pointer"

        if (interaction.infoOnHide.isHidden) {
          hide_class = "fas fa-eye"
          hide_label = "Unhide"
        } else {
          hide_class = "fas fa-eye-slash"
          hide_label = "Hide"
        }
      }

      if (interaction.sourceActionObj.canDelete) {
        delete_cursor = "pointer"
      }

      tableRow.action = (
        <div className="table-col-action">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {reply_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Reply
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-comment-medical"
                  style={{
                    fontSize: "1em",
                    cursor: reply_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    // if (reply_cursor === "pointer") {
                    //   handleClickopenForm(interaction)
                    // }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            {hide_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    {hide_label}
                  </Tooltip>
                }
              >
                <i
                  className={hide_class}
                  style={{
                    fontSize: "1em",
                    cursor: hide_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    if (hide_cursor === "pointer") {
                      handleHideUnhide(interaction._id, interaction.infoOnHide.isHidden)
                    }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            {delete_cursor !== "not-allowed" && isDeleted === false ? (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Delete
                  </Tooltip>
                }
              >
                <i
                  className="fa fa-trash"
                  style={{
                    fontSize: "1em",
                    cursor: delete_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    if (delete_cursor === "pointer") {
                      handleDelete(interaction._id)
                    }
                  }}
                ></i>
              </OverlayTrigger>
            ) : (
              ""
            )}

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                  Ignore
                </Tooltip>
              }
            >
              <i
                className="fas fa-ban"
                style={{
                  fontSize: "1em",
                  cursor: ignore_cursor,
                  marginLeft: "0.2rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  if (ignore_cursor === "pointer") {
                    setInteractionIdToBeIgnored(interaction._id)
                    setConfirmIgnoreAlert(true)
                  }
                }}
              ></i>
            </OverlayTrigger>

            {globe_cursor !== "not-allowed" && isDeleted === false && (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="button-tooltip-2" style={{ position: "absolute", top: "-30px" }}>
                    Open
                  </Tooltip>
                }
              >
                <i
                  className="fas fa-globe"
                  style={{
                    fontSize: "1em",
                    cursor: globe_cursor,
                    marginLeft: "0.2rem",
                    marginRight: "0.2rem",
                  }}
                  onClick={() => {
                    window.open(interaction.url)
                  }}
                ></i>
              </OverlayTrigger>
            )}
          </div>
        </div>
      )

      tableRows.push(tableRow)
    })

    setTableRows(tableRows)
  }

  const handleHideUnhide = (interactionId, isHidden) => {
    const toHide = !isHidden

    axios
      .put(
        `${API_URL}listening/hideInteraction`,
        {
          interactionId,
          toHide,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to hide/unhide interaction")
      })
  }

  const handleIgnore = interactionId => {
    axios
      .put(
        `${API_URL}listening/ignoreInteraction/`,
        {
          interactionId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))

        setInteractionIdToBeIgnored(null)
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to ignore interaction")
      })
  }

  const handleDelete = interactionId => {
    axios
      .put(
        `${API_URL}listening/deleteInteraction`,
        {
          interactionId,
        },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then(res => {
        toastr.success(res.data.message)

        dispatch(getAllInteractions(filterObject))
      })
      .catch(error => {
        toastr.error(error.response.data.message, "Failed to delete interaction")
      })
  }

  useEffect(() => {
    handleTableData()
  }, [interactions])

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
      },
      {
        label: "Source",
        field: "source",
        sort: "asc",
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
      },
      {
        label: "Message",
        field: "text",
        sort: "asc",
      },
      {
        label: "Sentiment",
        field: "sentiment",
        sort: "asc",
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
      },
    ],
    rows: tableRows,
  }

  const getColorForSentiment = sentiment => {
    switch (sentiment) {
      case "Positive":
        return "#34c38f" // Green
      case "Neutral":
        return "#f1b44b" // Yellow
      case "Negative":
        return "#f46a6a" // Red
      default:
        return "#FFFFFF" // Default: White (Not used)
    }
  }

  const getStatusNameFromStatus = status => {
    switch (status) {
      case 0:
        return "New"
      case 3:
        return "Hid"
      case 4:
        return "Deleted"
      default:
        return "default_val"
    }
  }

  return (
    <React.Fragment>
      {confirmIgnoreAlert ? (
        <SweetAlert
          title=""
          confirmButtonText="Ignore"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            handleIgnore(interactionIdToBeIgnored)

            setConfirmIgnoreAlert(false)
          }}
          onCancel={() => setConfirmIgnoreAlert(false)}
        >
          Are you sure you want to ignore it?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Social Listening" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* Filters */}
                  <Row>
                    <Col md="10">
                      <Row>
                        <Col md="2" className="mb-2">
                          <Label>From</Label>
                          <input
                            name="from_date"
                            type="date"
                            className="form-control"
                            onChange={handleFilterInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.from_date}
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>To</Label>
                          <input
                            name="to_date"
                            type="date"
                            className="form-control"
                            onChange={handleFilterInput}
                            validate={{ required: { value: true } }}
                            value={filterObject?.to_date}
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Source</Label>
                          <Select
                            placeholder="Select"
                            value={selectedSourceOption}
                            validate={{ required: { value: true } }}
                            onChange={selected => {
                              console.log("selected Source :", selected)
                              setSelectedSourceOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  source: selected.value,
                                }
                              })
                            }}
                            options={sourceOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Type</Label>
                          <Select
                            placeholder="Select"
                            value={selectedTypeOption}
                            validate={{ required: { value: true } }}
                            onChange={selected => {
                              console.log("selected Type :", selected)
                              setSelectedTypeOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  type: selected.value,
                                }
                              })
                            }}
                            options={typeOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Status</Label>
                          <Select
                            placeholder="Select"
                            value={selectedStatusOption}
                            onChange={selected => {
                              setSelectedStatusOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  status: selected.value,
                                }
                              })
                            }}
                            options={[
                              { label: "New", value: 0 },
                              { label: "Hid", value: 3 },
                              { label: "Deleted", value: 4 },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        <Col md="2" className="mb-2">
                          <Label>Sentiment</Label>
                          <Select
                            placeholder="Select"
                            value={selectedSentimentOption}
                            onChange={selected => {
                              setSelectedSentimentOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  sentiment: selected.value,
                                }
                              })
                            }}
                            options={[
                              { label: "Positive", value: "positive" },
                              { label: "Neutral", value: "neutral" },
                              { label: "Negative", value: "negative" },
                            ]}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col>

                        {/* <Col md="2" className="mb-2">
                          <Label>Department</Label>
                          <Select
                            placeholder="Select"
                            value={selectedDepartmentOption}
                            onChange={selected => {
                              setSelectedDepartmentOption(selected)

                              setFilterObject(preVal => {
                                return {
                                  ...preVal,
                                  department: selected.value,
                                }
                              })
                            }}
                            options={departmentOptions}
                            classNamePrefix="select2-selection"
                            menuPosition="auto"
                          />
                        </Col> */}
                      </Row>
                    </Col>

                    <Col md="2" style={{ marginTop: "26px" }}>
                      <div>
                        <Button className="mx-2" color="danger" type="reset" onClick={resetFilter}>
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  {/* Table */}
                  <MDBDataTable
                    key={JSON.stringify(data)}
                    id="interactionsTable"
                    responsive
                    bordered
                    data={data}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default SocialListening
