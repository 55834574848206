import React, { useState, useEffect } from "react"
import { Container } from "reactstrap"
import CountUp from "react-countup"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./style.scss"
import ReactApexChart from "react-apexcharts"
import moment from "moment"
import { useNavigate } from "react-router-dom"
// import { Navigate } from "react-router-dom"
import { Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Progress } from "reactstrap"
import LineApexChart from "./chartapex.js"
import TopProduct from "./topselling-product"

//Import Image

const Dashboard = () => {
  const navigate = useNavigate()
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
  const [master, setMaster] = useState({
    total_count: 0,
    new: 0,
    pending_count: 0,
    closed_count: 0,
    responded: 0,
    cust_info: 0,
    pending_more: 0,
    dept_change: 0,
  })
  // const [series, setSeries] = useState([]);
  const [graphData, setGraphData] = useState([])
  const year = moment().format("YYYY")

  // const series = graphData?.map((item) => item.series);
  const series = [
    {
      name: "Facebook",
      data: [24, 114, 68, 109, 53, 22],
    },
    {
      name: "Twitter",
      data: [0, 0, 0, 28, 136, 39],
    },
    {
      name: "Instagram",
      data: [23, 51, 18, 65, 33, 10],
    },
  ]

  const options = {
    dataLabels: {
      enabled: !1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    // series: series,
    colors: ["#5b73e8", "#f1b44c", "#98d8a9"],
    xaxis: {
      type: "yearmonth",
      // categories: [year + "-01", year + "-02", year + "-03", year + "-04", year + "-05", year + "-06", year + "-07", year + "-08", year + "-09", year + "-10", year + "-11", year + "-12"],
      categories: [
        "2023-09",
        "2023-10",
        "2023-11",
        "2023-12",
        "2024-01",
        "2024-02",
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: "yyyy-mm",
      },
    },
  }

  function fetchData() {
    axios
      .get(`${API_URL}dashboard/count`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        setMaster(res.data.data)
      })
  }

  function fetchGraph() {
    axios
      .get(`${API_URL}dashboard/graph`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        // setSeries(res.data);
        setGraphData(res?.data.data)
      })
  }

  useEffect(() => {
    fetchData()
    fetchGraph()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="BOM Social" breadcrumbItem="Dashboard" />
          <Row>
            <Col
              md={6}
              xl={3}
              key={1}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-envelope icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.total_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"TOTAL"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response?status=new")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={"fas fa-comment-dots icon-dashboard-1"}
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.new}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"NEW"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response?status=pending")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={
                            "fas fa-exclamation-triangle icon-dashboard-1"
                          }
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.pending_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">{"PENDING"}</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response?status=responded")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-reply-all icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.responded}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">RESPONDED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response?status=closed")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i className={"fas fa-check icon-dashboard-1"}></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.closed_count}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">CLOSED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
            <Col
              md={6}
              xl={3}
              key={2}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/all_response?status=dept-changed")
              }}
            >
              <Card>
                <CardBody>
                  <div className="float-end mt-2">
                    <p className="text-muted mb-0">
                      <span>
                        <i
                          className={"fas fa-share-square icon-dashboard-1"}
                        ></i>
                      </span>
                    </p>
                  </div>
                  <div>
                    <h4 className="mb-1 mt-1">
                      <span>
                        <CountUp
                          end={master?.dept_change}
                          separator=","
                          prefix={""}
                          suffix={""}
                          decimals={0}
                        />
                      </span>
                    </h4>
                    <p className="text-muted mb-0">DEPT. CHANGED</p>
                  </div>
                  {/* <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
              </p> */}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div className="float-end">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="text-reset" id="dropdownMenuButton5" caret href="#">
                                <span className="fw-semibold">Sort By:</span> <span className="text-muted">Yearly<i className="mdi mdi-chevron-down ms-1"></i></span>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem href="#">Monthly</DropdownItem>
                                <DropdownItem href="#">Yearly</DropdownItem>
                                <DropdownItem href="#">Weekly</DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-2">Social Listening @BOM</h4>

                  <LineApexChart />
                </CardBody>
              </Card>
            </Col>
            {/* <Col xl={4}>
    
            <TopProduct />
            </Col> */}

            <Col className="d-none" xl={12}>
              <Card>
                <ReactApexChart
                  options={options}
                  series={series}
                  type="area"
                  height="350"
                  className="apex-charts "
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
