import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Line, Bar } from "react-chartjs-2"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import moment from "moment"
import BarChart from "./barchart"
import LineApexChart from "./chartapex"
import MiniWidget from "./mini-widget"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"
function FollowersSummary() {
  const [fetchLike, setFetchLike] = useState()
  const [fetchFbFanPage, setFetchFbFanPage] = useState()
  const [instagramFollowerCount, setInstagramFollowerCount] = useState()
  const [instagramFollowerCountIncrement, setInstagramFollowerCountIncrement] =
    useState()
  const [twitterFollowerCount, setTwitterFollowerCount] = useState()
  const [twitterFollowerCountIncrement, setTwitterFollowerCountIncrement] =
    useState()

  function fetchLikeCountData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/facebook/page/likes?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setFetchLike(result)
      })
  }

  function fetchFbPageData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/facebook/page/likesIncrement?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setFetchFbFanPage(result)
      })
  }

  function fetchInstagramFollowersData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/instagram/account/followers?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setInstagramFollowerCount(result)
      })
  }

  function fetchInstagramFollowersIncrementData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/instagram/account/followersIncrement?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setInstagramFollowerCountIncrement(result)
      })
  }

  function fetchTwitterFollowersData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/twitter/account/followers?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setTwitterFollowerCount(result)
      })
  }

  function fetchTwitterFollowersIncrementData() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/twitter/account/followersIncrement?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setTwitterFollowerCountIncrement(result)
      })
  }

  useEffect(() => {
    fetchLikeCountData()
    fetchFbPageData()
    fetchInstagramFollowersData()
    fetchInstagramFollowersIncrementData()
    fetchTwitterFollowersData()
    fetchTwitterFollowersIncrementData()
  }, [])

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  const series1 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
    },
  ]

  const options1 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const series2 = [70]

  const options2 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series3 = [55]

  const options3 = {
    fill: {
      colors: ["#5b73e8"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series4 = [
    {
      data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
    },
  ]

  const options4 = {
    fill: {
      colors: ["#f1b44c"],
    },
    chart: {
      width: 70,
      sparkline: {
        enabled: !0,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    xaxis: {
      crosshairs: {
        width: 1,
      },
    },
    tooltip: {
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (seriesName) {
            return ""
          },
        },
      },
      marker: {
        show: !1,
      },
    },
  }

  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Facebook",
      value: 342,
      prefix: "",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series1,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Instagram",
      value: 63,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series2,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-arrow-down-bold",
      title: "Twitter",
      value: 454,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: "6.24%",
      color: "danger",
      desc: "since last week",
      series: series3,
      options: options3,
    },
  ]
  // bar chart1
  const data1 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Likes Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [fetchFbFanPage?.increment],
      },
    ],
  }

  const option1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: -420, // Set the minimum value for the y-axis
            // max: -370,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Likes Increment",
          },
        },
      ],
    },
  }

  // bar chart2
  const barData = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Likes Change%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [fetchFbFanPage?.incrementPercentage],
      },
    ],
  }

  const barOption = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: -10, // Set the minimum value for the y-axis
            // max: 50,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Likes Change%",
          },
        },
      ],
    },
  }

  // Line chart
  const lineData = {
    labels: fetchLike?.labels,
    datasets: [
      {
        label: "Kial",
        data: fetchLike?.data,
        fill: false,
        borderColor: "#5b73e8",
      },
    ],
  }

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 3340300, // Set the minimum value for the y-axis
            // max: 3340800,
            stepSize: 100, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  // bar chart5
  const data5 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [twitterFollowerCountIncrement?.increment],
      },
    ],
  }

  const option5 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 20, // Set the minimum value for the y-axis
            // max: 80,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment",
          },
        },
      ],
    },
  }

  // bar chart6
  const data6 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [twitterFollowerCountIncrement?.incrementPercentage],
      },
    ],
  }

  const option6 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            // max: 60,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment%",
          },
        },
      ],
    },
  }

  // Line chart3
  const lineData3 = {
    labels: twitterFollowerCount?.labels,
    datasets: [
      {
        label: "Kial",
        data: twitterFollowerCount?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  }

  const lineOption3 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 119350, // Set the minimum value for the y-axis
            // max: 119410,
            stepSize: 10, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  // bar chart7
  const data7 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [instagramFollowerCountIncrement?.increment],
      },
    ],
  }

  const option7 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 20, // Set the minimum value for the y-axis
            // max: 80,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment",
          },
        },
      ],
    },
  }

  // bar chart8
  const data8 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "Followers Increment%",
        backgroundColor: "#e83e8c",
        borderColor: "#e83e8c",
        borderWidth: 1,
        hoverBackgroundColor: "#e83e8c",
        hoverBorderColor: "#e83e8c",
        data: [instagramFollowerCountIncrement?.incrementPercentage],
      },
    ],
  }

  const option8 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at 0
            // max: 60,
            stepSize: 10, // Set the interval between y-axis ticks
          },
          scaleLabel: {
            display: true,
            labelString: "Followers Increment%",
          },
        },
      ],
    },
  }

  // Line chart4
  const lineData4 = {
    labels: instagramFollowerCount?.labels,
    datasets: [
      {
        label: "Kial",
        data: instagramFollowerCount?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  }

  const lineOption4 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false, // Start the y-axis at -500
            // min: 143800, // Set the minimum value for the y-axis
            // max: 144600,
            stepSize: 200, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Fans Report</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>

          <Row>
            <MiniWidget reports={reports} />

            <Row>
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    {/* <h4 className="card-title mb-4">DAily Followers Count</h4> */}
                    <LineApexChart />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                  Facebook Followers Increment
                  </h4>
                  <BarChart color='#5b73e8' label="Likes Increment" />
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                  Facebook Followers Increment
                  </h4>
                  <BarChart color='#5b73e8' label="Likes Change %" />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart1 */}
            <Col className="d-none" md="6">
              <Card>
                <CardBody>
                  <h5>FB Fan Page Likes Increment</h5>
                  
                  <Bar
                    width={474}
                    height={200}
                    data={data1}
                    options={option1}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart2 */}
            <Col className="d-none" md="6">
              <Card>
                <CardBody>
                  <h5>FB Fan Page Likes Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={barData}
                    options={barOption}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart1 */}
            <Col md="12" className="d-none">
              <Card>
                <CardBody>
                  <h5>Likes Count Daily Trend</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                    Twitter Followers Increment
                  </h4>
                  <BarChart color='#f1b44c' label="Followers Increment" />
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                    Twitter Followers Increment Percent
                  </h4>
                  <BarChart color='#f1b44c' label="Followers Change %" />
                </CardBody>
              </Card>
            </Col>
            {/* bar chart5 */}
            <Col md="6" className="d-none">
              <Card>
                <CardBody>
                  <h5>Twitter Followers Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data5}
                    options={option5}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart6 */}
            <Col md="6" className="d-none">
              <Card>
                <CardBody>
                  <h5>Twitter Followers Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data6}
                    options={option6}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart3 */}
            <Col md="12" className="d-none">
              <Card>
                <CardBody>
                  <h5>Followers Count Daily Trend</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData3}
                    options={lineOption3}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                    Instagram Followers Increment
                  </h4>
                  <BarChart color='#f46a6a' label="Followers Increment" />
                </CardBody>
              </Card>
            </Col>

            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id=""
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h4 className="card-title mb-4">
                    Instagram Followers Increment Percent
                  </h4>
                  <BarChart color='#f46a6a' label="Followers Change %" />
                </CardBody>
              </Card>
            </Col>
            {/* bar chart7 */}
            <Col md="6" className="d-none">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Increment</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data7}
                    options={option7}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* bar chart8 */}
            <Col md="6" className="d-none">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Increment Percent</h5>
                  <Bar
                    width={474}
                    height={200}
                    data={data8}
                    options={option8}
                  />
                </CardBody>
              </Card>
            </Col>

            {/* line chart4 */}
            <Col md="12" className="d-none">
              <Card>
                <CardBody>
                  <h5>Instagram Followers Daily </h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData4}
                    options={lineOption4}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FollowersSummary
