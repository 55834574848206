import React from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import PieChart from "./PieChart"
import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"

function Graphicalreport() {
  const customStyles = {
    lineHeight: "1.8",
  }

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Question wise report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label>Feedback Form</Label>
                          <Select
                            style={customStyles}
                            name="feedbackform"
                            options={[
                              {
                                label: "We'd love to hear from you!",
                                value: 0,
                              },
                            ]}
                            type="text"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            style={customStyles}
                            name="feedbacktitle"
                            className="form-control"
                            type="date"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            style={customStyles}
                            name="feedbacktitle"
                            className="form-control"
                            type="date"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      {/* <Col md="4">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Zone</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "28px" }}>
                          <Button
                            color="primary"
                            style={{ marginRight: "10px", padding: "8px 30px" }}
                            type="submit"
                          >
                            Filter
                          </Button>

                          <Button style={{ padding: "8px 30px" }} color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <h6>Feedback Form: We'd love to hear from you!</h6>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>How do you rate the overall experience?</p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>6357 Votes (64%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>1993 Votes (20%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>901 Votes (9%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>473 Votes (5%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>211 Votes (2%)</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart answers={[6357, 1993, 901, 473, 211]} type={1} />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>
                          How likely do you rate our staff behaviour?
                        </p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={grinningface} alt="" />
                              </td>
                              <td>6357 Votes (64%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={slightlysmile} alt="" />
                              </td>
                              <td>1993 Votes (20%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={Neutralface} alt="" />
                              </td>
                              <td>901 Votes (9%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={confusedface} alt="" />
                              </td>
                              <td>473 Votes (5%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={cryingface} alt="" />
                              </td>
                              <td>211 Votes (2%)</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart answers={[6357, 1993, 901, 473, 211]} />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>
                          How do you feel about the cleanliness of the branch/ATM?
                        </p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={grinningface} alt="" />
                              </td>
                              <td>6357 Votes (64%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={slightlysmile} alt="" />
                              </td>
                              <td>1993 Votes (20%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={Neutralface} alt="" />
                              </td>
                              <td>901 Votes (9%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={confusedface} alt="" />
                              </td>
                              <td>473 Votes (5%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={cryingface} alt="" />
                              </td>
                              <td>211 Votes (2%)</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart answers={[6357, 1993, 901, 473, 211]} />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>
                          Would you recommend Bank of Maharashtra to your friends or relatives?
                        </p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>Yes</td>
                              <td>6357 Votes (64%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>No</td>
                              <td>1993 Votes (20%)</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart answers={[6357, 1993]} type={0} />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Graphicalreport
