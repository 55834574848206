import {
  GET_MODULE,
  GET_MODULE_SUCCESSFUL,
  GET_MODULE_FAILED,
} from "./actionTypes"

const initialState = {
  message: null,
  loading: false,
  data: null,
}

const Module = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODULE:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_MODULE_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        data: action.payload.alloted_modules,
      }
      break
    case GET_MODULE_FAILED:
      state = {
        ...state,
        data: null,
        loading: false,
        registrationError: action.payload,
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default Module
