import React from "react"
import { Container, Row, Col } from "reactstrap"
import { APP_NAME } from "../../config"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>
              ©{new Date().getFullYear()} {APP_NAME}. All Rights Reserved
            </Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                <a className="text-dark" href="https://srvinfotech.com/">
                  Powered by SRV InfoTech
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
