import React from 'react'
import { AvField, AvForm } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Select from "react-select";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { MDBDataTable } from 'mdbreact';
import "./TrashedLeads.scss"
function TrashedLeads() {

  const leadSource = [
    { label: "Just Dial", value: 1 },
    { label: "Website", value: 2 },
    { label: "Office Visit", value: 3 },
    { label: "IVR Calls", value: 4 },
    { label: "Referrals", value: 5 },
    { label: "WATI", value: 6 },
    { label: "Google My Business", value: 7 },
    { label: "Direct Calls", value: 8 },
    { label: "Facebook", value: 9 },
    { label: "Instagram", value: 10 },
    { label: "Other", value: 11 },
  ]
  const staff = [
    { label: "admin", value: 1 },
    { label: "Ajay ", value: 2 },
    { label: "Varun", value: 3 },

  ]

  // const [popupView, setPopupView] = useState(false)
  // const handleCloseModal = () => setPopupView(false);
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "Leads",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "Mobile",
        width: 150,
      },

      {
        label: "Product",
        field: "Type",
        width: 150,
      },
      {
        label: "Priority",
        field: "Priority",
        width: 150,
      },

      {
        label: "Source",
        field: "Source",
        width: 150,
      },
      {
        label: "Staff",
        field: "assign",
        width: 150,
      },


    ],

    rows: [
      {
        Source: "instagram",
        id: "1",
        Leads: "10-10-2017",
        Name: "Shaji",
        Type: "Visa",
        FollowUp: "-",
        Branch: "SPBL001",
        Priority: "2",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: "admin",

      },
      {
        Source: "instagram",
        id: "2",
        Leads: "10-10-2017",
        Name: "Shaji",
        Type: "Travel Document",
        FollowUp: "-",
        Branch: "SPBL002",
        Priority: "2",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: "admin",

      },
      {
        Source: "instagram",
        id: "3",
        Leads: "10-10-2017",
        Name: "Shaji",
        Type: "Passport",
        FollowUp: "-",
        Branch: "SPBL003",
        Priority: "1",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: "admin",

      },
    ]
  }
  return (
    <>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumbs title="Home" breadcrumbItem="Trashed Leads" />
          <Row>
            <Col className='col-12'>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label >Date</Label>
                          <AvField
                            name="Search"
                            type="date"

                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label >Priority</Label>
                          <Select
                            name="Search"
                            type="text"

                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label >Product</Label>
                          <Select
                            name="Search"

                            type="text"

                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label >Source</Label>
                          <Select
                            name="Search"
                            options={leadSource}



                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label >Staff</Label>
                          <Select
                            name="Search"
                            options={staff}



                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label > From</Label>
                          <AvField
                            name="Search"
                            className="form-control"
                            type="date"
                            placeholder="From"

                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label >To</Label>
                          <AvField
                            name="Search"
                            className="form-control"
                            type="date"
                            placeholder="To"

                          />
                        </div>
                      </Col>

                      <Col md="1" className='d-flex'>
                        <div className="mt-4 mb-3" style={{ paddingTop: "5px", paddingRight: "10px" }}>
                          <Button
                            color="danger"
                            type="reset"

                          >
                            Reset
                          </Button>
                        </div>
                        <div className="mt-4 mb-3" style={{ paddingTop: "5px" }}>
                          <Button
                            color="warning"
                            type="reset"

                          >
                            export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="trashedID"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}


                  >

                  </MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>



        </div>
      </div>
    </>
  )
}

export default TrashedLeads