import React from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody,} from "reactstrap";
import { MDBDataTable } from 'mdbreact';
// import "./ManageZone.scss"
function Zonemanage() {
   
const data = {
    columns: [
        {
            label: "#",
            field: "id",
            width: 150,
        },
        {
            label: "Code",
            field: "Code",
            width: 150,
        },

        {
            label: "Name",
            field: "Name",
            width: 150,
        },
        {
            label: "Branch Code",
            field: "branchcode",
            width: 150,
        },
        {
            label: "No. Of Branch",
            field: "Branch",
            width: 150,
        },
       

    ],
    rows: [
        {
            id: "1",
            Code: "16",
            Name: "Kerala-kannur",
            Branch: "3",
            branchcode:"BOM590",
           
           
        },
        {
            id: "2",
            Code: "16",
            Name: "Kerala-kannur",
            Branch: "3",
            branchcode:"BOM590",
           
           
        },
        {
            id: "3",
            Code: "16",
            Name: "Kerala-kannur",
            Branch: "3",
            branchcode:"BOM590",
           
           
        },
        {
            id: "4",
            Code: "16",
            Name: "Kerala-kannur",
            Branch: "3",
            branchcode:"BOM590",
           
           
        },
        {
            id: "5",
            Code: "16",
            Name: "Kerala-kannur",
            Branch: "3",
            branchcode:"BOM590",
           
           
        },
        
    ],
};
return (
    <>
        <div className='page-content'>
            <div className='container-fluid'>
                <Breadcrumbs title="Home" breadcrumbItem="Zone List" />
                


                <Row>
                    <Col className='col-12'>
                        
                        <Card>
                            <CardBody>
                                <MDBDataTable
                                    id="ManageZone1Id"
                                    bordered
                                    responsive
                                    searching={true}
                                    entries={20}
                                    disableRetreatAfterSorting={true}
                                    data={data}

                                >

                                </MDBDataTable>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>



            </div>
        </div>
    </>
)
}

export default Zonemanage