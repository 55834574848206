import {
  GET_INTERACTIONS,
  GET_INTERACTIONS_FAIL,
  GET_INTERACTIONS_SUCCESS,
} from "./actionTypes"

export const getAllInteractions = data => ({
  type: GET_INTERACTIONS,
  payload: data,
})

export const getAllInteractionsSuccess = interactions => ({
  type: GET_INTERACTIONS_SUCCESS,
  payload: interactions,
})

export const getAllInteractionsFail = error => ({
  type: GET_INTERACTIONS_FAIL,
  payload: error,
})
