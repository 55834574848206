import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Modal,
  NavLink,
  Nav,
  NavItem,
  TabContent,
  TabPane,
} from "reactstrap"
import { MDBDataTable } from "mdbreact"
import Select from "react-select"

import classnames from "classnames"

import "./Leads1.scss"
function Leads1() {
  const customStyles = {
    lineHeight: "1.8",
  }

  // const leadTypes = [
  //     { label: "API", value: 1 },
  //     { label: "CRM", value: 2 },
  //     { label: "WEB", value: 3 },
  //   ]

  const [masterObject, setMasterObject] = useState({
    firstname: "Muhammad Thaha ",
    lastname: " Kp",
    mobile: "9876543467",
    email: "Thahakp@gmail.com",
    place: "kannur",
    state: "karnataka",
    address: "dhfdhfgdhghfsd",
  })

  // function handleClick(event) {
  //   let name = event.target.name
  //   let value = event.target.value
  //   setMasterObject({ ...masterObject, [name]: value });
  // }
  const leadSource = [
    { label: "Just Dial", value: 1 },
    { label: "Website", value: 2 },
    { label: "Office Visit", value: 3 },
    { label: "IVR Calls", value: 4 },
    { label: "Referrals", value: 5 },
    { label: "WATI", value: 6 },
    { label: "Google My Business", value: 7 },
    { label: "Direct Calls", value: 8 },
    { label: "Facebook", value: 9 },
    { label: "Instagram", value: 10 },
    { label: "Other", value: 11 },
  ]
  const staff = [
    { label: "admin", value: 1 },
    { label: "Ajay ", value: 2 },
    { label: "Varun", value: 3 },
  ]

  const options = [
    {
      label: "p",
      value: 0,
    },
    {
      label: "0",
      value: 1,
    },
    {
      label: "1",
      value: 2,
    },
    {
      label: "2",
      value: 3,
    },
    {
      label: "3",
      value: 4,
    },
    {
      label: "4",
      value: 5,
    },
    {
      label: "5",
      value: 6,
    },
  ]
  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const [selectedStatus, setSelectedStatus] = useState(null)

  const [selectedPriority, setSelectedPriority] = useState(options[1])
  // eslint-disable-next-line
  const [selectedType, setSelectedType] = useState(null)

  function handleSelectChange(selected, name) {
    switch (name) {
      case "status":
        setSelectedStatus(selected)
        break

      case "priority":
        setSelectedPriority(selected)
        break

      case "servicetype":
        setSelectedType(selected)
        console.log(selected)

        if (selected.value === 0) {
          setMasterObject({ ...masterObject, rate: "5000" })
        }

        break

      default:
        break
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "Leads",
        width: 150,
      },
      {
        label: "Name",
        field: "Name",
        width: 150,
      },
      {
        label: "Mobile",
        field: "Mobile",
        width: 150,
      },

      {
        label: "Product",
        field: "Type",
        width: 150,
      },
      {
        label: "Priority",
        field: "Priority",
        width: 150,
      },

      {
        label: "Source",
        field: "Source",
        width: 150,
      },
      {
        label: "Assign To",
        field: "assign",
        width: 150,
      },
      {
        label: "Campaign",
        field: "campaign",
        width: 150,
      },
      {
        label: "Actions",
        field: "options",
        sort: "desc",
        width: 400,
      },
    ],

    rows: [
      {
        campaign: "Instagram Ads",
        Source: "instagram",
        id: "1",
        Leads: "10-10-2017",
        Name: " Muhammad Thaha Kp",
        Type: "Visa",
        FollowUp: "-",
        Branch: "SPBL001",
        Priority: "2/CP",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: (
          <>
            <Select
              options={[
                { label: "agent 1", value: "0" },
                { label: "agent 2", value: "0" },
              ]}
            />

            {/* <select className="form-control form-select select_table_leads">
            <option>Select Staff</option>
            <option>admin</option>
            <option>Ajay</option>
            <option>Varun</option>
          </select> */}
          </>
        ),
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
            ></i>

            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
      {
        campaign: "Instagram Ads",

        Source: "instagram",
        id: "2",
        Leads: "10-10-2017",
        Name: "Mohammad Shammas",
        Type: "Travel Document",
        FollowUp: "-",
        Branch: "SPBL002",
        Priority: "2",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: (
          <>
            <Select
              options={[
                { label: "agent 1", value: "0" },
                { label: "agent 2", value: "0" },
              ]}
            />
          </>
        ),
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
            ></i>

            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
      {
        campaign: "Instagram Ads",

        Source: "instagram",
        id: "3",
        Leads: "10-10-2017",
        Name: "Sravya Ratheesh",
        Type: "Passport",
        FollowUp: "-",
        Branch: "SPBL003",
        Priority: "1",
        Commitment: "21-01-2023",
        Mobile: "9876543201",
        assign: (
          <>
            <Select
              options={[
                { label: "agent 1", value: "0" },
                { label: "agent 2", value: "0" },
              ]}
            />
          </>
        ),
        options: (
          <div>
            <i
              className="fas fa-eye"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
              onClick={() => {
                setPopupView(true)
              }}
            ></i>
            <i
              className="far fa-edit"
              style={{
                fontSize: "1em",
                cursor: "pointer",

                marginRight: "0.6em",
              }}
            ></i>

            <i
              className="far fa-trash-alt"
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
    ],
  }
  const viewdetail_data = {
    // eslint-disable-next-line
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },

      {
        label: "Next Followup",
        field: "product_category",
        width: 150,
      },

      {
        label: "Comments",
        field: "description",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 400,
      },
      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 400,
      },
      //   {
      //     label: "Actions",
      //     field: "options",
      //     sort: "desc",
      //     width: 400,
      //   },
    ],
    rows: [
      {
        id: "1",
        date: "19-01-2024",
        staff: "admin",
        status: (
          <div>
            <span className="badge bg-warning font-size-12 ms-2">New</span>
          </div>
        ),

        options: (
          <div className="d-flex gap-2">
            <i
              className="uil-edit-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>

            <i
              className="uil-trash-alt"
              style={{ fontSize: "1.2em", cursor: "pointer" }}
            ></i>
          </div>
        ),
      },
    ],
  }

  const [activeTabJustify, setactiveTabJustify] = useState("1")

  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Leads" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>
                            Mobile No.<span style={{ color: "red" }}>*</span>
                          </Label>
                          <AvField
                            name="Domain"
                            type="text"
                            errorMessage="Enter Mobile Number"
                            className="form-control"
                            placeholder="Enter Mobile No."
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Name</Label>
                          <AvField
                            name="Domain Code"
                            type="text"
                            placeholder="Enter Name"
                            errorMessage="Enter "
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Email</Label>
                          <AvField
                            name="Domain Code"
                            className="form-control"
                            type="text"
                            placeholder="Enter Email ID"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Lead Source</Label>
                          <Select
                            name="Domain Code"
                            type="text"
                            options={leadSource}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Product category</Label>
                          <Select
                            name="Domain Code"
                            type="text"
                            options={[
                              {
                                label: "Passport",
                                value: 0,
                              },
                              {
                                label: "PAN Card",
                                value: 1,
                              },
                              {
                                label: "Aadhaar",
                                value: 2,
                              },
                              {
                                label: "Voter ID",
                                value: 3,
                              },
                              {
                                label: "OCI",
                                value: 4,
                              },
                              {
                                label: "Visa",
                                value: 5,
                              },
                              {
                                label: "Foreign Passport",
                                value: 6,
                              },
                            ]}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Products</Label>
                          <Select
                            name="Domain Code"
                            type="text"
                            options={[
                              {
                                label: "Fresh-Normal-36 Pages-35 to 40 days",
                                value: 0,
                              },
                              {
                                label: "Fresh-SuperFast-36 Pages-Below 20 days",
                                value: 1,
                              },
                              {
                                label: "Fresh-SuperFast-36 Pages-Below 10 days",
                                value: 2,
                              },
                              {
                                label: "Fresh-SuperFast-36 Pages-Below 5 days",
                                value: 3,
                              },
                              {
                                label: "Fresh-Tatkal-36 Pages-Below 10 days",
                                value: 4,
                              },
                              {
                                label: "Reissue-Normal-36 Pages-35 to 40 days",
                                value: 5,
                              },
                              {
                                label: "Reissue-Normal-36 Pages-35 to 40 days",
                                value: 6,
                              },
                            ]}
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="3 i2">
                        <div className="mb-2 i1 ">
                          <Label>Priority</Label>
                          <Select
                            onChange={selected => {
                              handleSelectChange(selected, "priority")
                            }}
                            style={customStyles}
                            type="Number"
                            options={options}
                            defaultValue={options[1]}
                          />
                        </div>
                        {selectedPriority?.value === 1 ? (
                          <div className="mb-2 i1 i3">
                            <Select
                              style={customStyles}
                              placeholder=""
                              options={[
                                {
                                  label: "SEL",
                                  value: 0,
                                },
                                {
                                  label: "CP",
                                  value: 1,
                                },
                                {
                                  label: "DC",
                                  value: 2,
                                },
                                {
                                  label: "NB",
                                  value: 3,
                                },
                                {
                                  label: "NP",
                                  value: 4,
                                },
                                {
                                  label: "NR",
                                  value: 5,
                                },
                                {
                                  label: "NV",
                                  value: 6,
                                },
                                {
                                  label: "SW",
                                  value: 7,
                                },
                              ]}
                            />
                          </div>
                        ) : null}
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Address</Label>
                          <AvField
                            name="comment"
                            placeholder="Address"
                            rows="1"
                            type="textarea"
                            className="form-control"
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Follow Up Date
                          </Label>
                          <AvField
                            name="comment"
                            placeholder="Comments"
                            type="date"
                            className="form-control"
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">
                            Follow Up Time
                          </Label>
                          <AvField
                            name="comment"
                            placeholder="Time"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom01">Comments</Label>
                          <AvField
                            name="comment"
                            placeholder="Comments"
                            rows="1"
                            type="textarea"
                            className="form-control"
                            id="validationCustom01"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button color="primary" type="submit">
                            Submit
                          </Button>
                          <Button
                            style={{ marginLeft: "18px" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Date</Label>
                          <AvField name="Search" type="date" />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Priority</Label>
                          <Select
                            options={[
                              {
                                label: "SEL",
                                value: 0,
                              },
                              {
                                label: "CP",
                                value: 1,
                              },
                              {
                                label: "DC",
                                value: 2,
                              },
                              {
                                label: "NB",
                                value: 3,
                              },
                              {
                                label: "NP",
                                value: 4,
                              },
                              {
                                label: "NR",
                                value: 5,
                              },
                              {
                                label: "NV",
                                value: 6,
                              },
                              {
                                label: "SW",
                                value: 7,
                              },
                            ]}
                            name="Search"
                            type="text"
                          />
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Product</Label>
                          <Select
                            name="Search"
                            type="text"
                            errorMessage="Enter Category"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Source</Label>
                          <Select name="Search" options={leadSource} />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Staff</Label>
                          <Select name="Search" options={staff} />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> From</Label>
                          <AvField
                            name="Search"
                            className="form-control"
                            type="date"
                            placeholder="From"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To</Label>
                          <AvField
                            name="Search"
                            className="form-control"
                            type="date"
                            placeholder="To"
                          />
                        </div>
                      </Col>

                      <Col md="1" className="d-flex">
                        <div
                          className="mt-4 mb-3"
                          style={{ paddingTop: "5px", paddingRight: "10px" }}
                        >
                          <Button color="danger" type="reset">
                            Reset
                          </Button>
                        </div>
                        <div
                          className="mt-4 mb-3"
                          style={{ paddingTop: "5px" }}
                        >
                          <Button color="warning" type="reset">
                            export
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>

                  <MDBDataTable
                    id="Leads1ID"
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}
      >
        <div className="modal-header">
          <div className="modal-title">
            <h5 style={{ marginBottom: "0px" }}>
              Details of Muhammad Thaha Kp
            </h5>
          </div>
          {/* <h3 className="modal-title mt-0">Lead Details - {leadDetails?.lead_unique_id}</h3> */}
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body pt-0">
          <Row>
            {/* <Col md={3}>
              <Nav pills className="flex-column">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames("mb-2", {
                    active: activeTabV === "15",
                  })}
                  onClick={() => {
                    toggleV("15")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="fas fa-home"></i> 
                  </span>
                  <span className="d-none d-sm-block">Lead Info</span>
                </NavLink>

                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames("mb-2", {
                    active: activeTabV === "16",
                  })}
                  onClick={() => {
                    toggleV("16")
                  }}
                >
                  <span className="d-block d-sm-none">
                    <i className="far fa-user"></i>
                  </span>
                  <span className="d-none d-sm-block">Personal Details</span>
                </NavLink>
              </Nav>
            </Col> */}
            <Col md={12}>
              <Nav tabs className="nav-tabs-custom nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify === "1",
                    })}
                    onClick={() => {
                      toggleCustomJustified("1")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">Lead Info</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTabJustify === "2",
                    })}
                    onClick={() => {
                      toggleCustomJustified("2")
                    }}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i> 
                    </span> 
                    <span className="d-none d-sm-block">Update Details</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTabJustify}>
                <TabPane tabId="1" className="p-3">
                  <Row>
                    <Col md="12">
                      <table className="table table-borderd lead-info table-striped">
                        <tbody>
                          <tr>
                            <td>Name</td>
                            <th>: Muhammad Thaha Kp</th>
                            <td>Mobile</td>
                            <th>: 9876543467</th>
                            <td>Email</td>
                            <th>: Thahakp@gmail.com</th>
                            <td>District</td>
                            <th>: Kannur</th>
                          </tr>
                          <tr>
                            <td>Lead ID </td>
                            <th>: SMOT01028</th>
                            <td>Source</td>
                            <th>: Website</th>
                            <td>Campaign</td>
                            <th>: Facebook Ads</th>
                            <td>Date&Time</td>
                            <th>: 11-01-2024 , 5.30PM</th>
                          </tr>
                          <tr>
                            <td>Branch </td>
                            <th>: BLR01</th>
                            <td>Product Category</td>
                            <th>: Passport</th>
                            <td>Product</td>
                            <th colSpan={3}>
                              : New/Fresh Normal Travel Document{" "}
                            </th>
                          </tr>
                          <tr>
                            <td>Priority</td>
                            <th>: 1/CL</th>
                            <td>Staff</td>
                            <th>: Admin</th>
                            <td>Payment </td>
                            <th>: </th>

                            <td></td>
                            <th></th>
                            <td></td>
                            <th></th>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tabId="2" className="p-3">
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <p>
                          First Name :{" "}
                          <AvField
                            name="wea"
                            onChange={e => setMasterObject(e.target.value)}
                            value={masterObject.firstname}
                            type="text"
                          />
                        </p>
                      </Col>

                      <Col md="3">
                        <p>
                          Last Name :{" "}
                          <AvField
                            name="lastname"
                            onChange={e => setMasterObject(e.target.value)}
                            value={masterObject.lastname}
                            type="text"
                          />
                        </p>
                      </Col>
                      <Col md="3">
                        <p>
                          DOB :{" "}
                          <AvField
                            name="dob"
                            onChange={e => setMasterObject(e.target.value)}
                            type="date"
                          />
                        </p>
                      </Col>
                      {/* <Col md="3">
                      <p className=''>
                        Gender :
                        <Select className=""
                        options={[{label:"Male",value:"0"},{label:"Female",value:"1"}]}
                           
                        />
                      </p>
                    </Col> */}
                      <Col md="3">
                        <p>
                          Mobile :{" "}
                          <AvField
                            name="mobile"
                            onChange={e => setMasterObject(e.target.value)}
                            value={masterObject.mobile}
                            type="text"
                          />
                        </p>
                      </Col>
                      <Col md="3">
                        <p>
                          Email ID :{" "}
                          <AvField
                            name="email"
                            value={masterObject.email}
                            type="text"
                          />
                          {/* <b>Shaji@gmail.com</b> */}
                        </p>
                      </Col>
                      <Col md="3">
                        <p>
                          Address :{" "}
                          <AvField
                            name="address"
                            type="textarea"
                            rows={1}
                            onChange={e => setMasterObject(e.target.value)}
                          />
                        </p>
                      </Col>
                      {/* <Col md="3">
                      <p className=''>
                        Country :
                        <Select className=""
                        options={[{label:"India",value:"0"},{label:"Germany",value:"1"}]}
                          
                        />
                      </p>
                    </Col> */}
                      <Col md="3">
                        <p className="">
                          State :
                          <Select
                            className=""
                            options={[
                              { label: "Kerala", value: "0" },
                              { label: "Karnataka", value: "1" },
                            ]}
                          />
                        </p>
                      </Col>
                      <Col md="3">
                        <p className="">
                          District :
                          <Select />
                        </p>
                      </Col>
                      <Col md="3">
                        <p>
                          PIN :{" "}
                          <AvField
                            name="pin"
                            onChange={e => setMasterObject(e.target.value)}
                            type="text"
                          />
                        </p>
                      </Col>
                    </Row>
                  </AvForm>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col md="12" lg="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="description"
                            type="text"
                            onChange={selected => {
                              handleSelectChange(selected, "status")
                            }}
                            options={[
                              {
                                label: "Service",
                                value: 0,
                              },
                              {
                                label: "0",
                                value: 1,
                              },
                              {
                                label: "1",
                                value: 2,
                              },
                              {
                                label: "2",
                                value: 3,
                              },
                              {
                                label: "3",
                                value: 4,
                              },
                              {
                                label: "4",
                                value: 5,
                              },
                              {
                                label: "5",
                                value: 6,
                              },
                            ]}
                            errorMessage="Enter service"
                          />
                        </div>
                      </Col>

                      {selectedStatus?.value === 0 ? (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Service</Label>
                              <Select
                                name="description"
                                type="text"
                                options={[
                                  {
                                    label: "Passport",
                                    value: 0,
                                  },
                                  {
                                    label: "PAN Card",
                                    value: 1,
                                  },
                                  {
                                    label: "Aadhaar",
                                    value: 2,
                                  },
                                  {
                                    label: "Voter ID",
                                    value: 3,
                                  },
                                  {
                                    label: "OCI",
                                    value: 4,
                                  },
                                  {
                                    label: "Visa",
                                    value: 5,
                                  },
                                  {
                                    label: "Foreign Passport",
                                    value: 6,
                                  },
                                ]}
                                errorMessage="Enter service"
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Service Type</Label>
                              <Select
                                name="description"
                                type="text"
                                onChange={selected => {
                                  handleSelectChange(selected, "servicetype")
                                }}
                                options={[
                                  {
                                    label:
                                      "Fresh-Normal-36 Pages-35 to 40 days",
                                    value: 0,
                                  },
                                  {
                                    label:
                                      "Fresh-SuperFast-36 Pages-Below 20 days",
                                    value: 1,
                                  },
                                  {
                                    label:
                                      "Fresh-SuperFast-36 Pages-Below 10 days",
                                    value: 2,
                                  },
                                  {
                                    label:
                                      "Fresh-SuperFast-36 Pages-Below 5 days",
                                    value: 3,
                                  },
                                  {
                                    label:
                                      "Fresh-Tatkal-36 Pages-Below 10 days",
                                    value: 4,
                                  },
                                  {
                                    label:
                                      "Reissue-Normal-36 Pages-35 to 40 days",
                                    value: 5,
                                  },
                                  {
                                    label:
                                      "Reissue-Normal-36 Pages-35 to 40 days",
                                    value: 6,
                                  },
                                ]}
                                errorMessage="Enter service"
                              />
                            </div>
                          </Col>

                          <Col md="2">
                            <div className="mb-3">
                              <Label>Rate</Label>
                              <AvField
                                value={masterObject.rate}
                                name="description"
                                type="text"
                              />
                            </div>
                          </Col>

                          <Col md="3">
                            <div className="mb-3">
                              <Label>Comapany Name</Label>
                              <AvField
                                name="Domain Code"
                                type="text"
                                errorMessage="Enter Category"
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>GST No.</Label>
                              <AvField
                                name="Domain Code"
                                type="text"
                                errorMessage="Enter Category"
                              />
                            </div>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col md="3">
                            <div className="mb-3">
                              <Label>Next Followup </Label>
                              <AvField
                                name="description"
                                type="date"
                                placeholder="Service Code"
                                errorMessage="Enter Service Code"
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Comments</Label>
                          <AvField
                            name="Comments"
                            rows={1}
                            type="textarea"
                            placeholder="Comments"
                            errorMessage="Enter Description"
                          />
                        </div>
                      </Col>

                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                          <Button
                            style={{ marginRight: "3%" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
            <Col md="12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id=""
                    responsive
                    bordered
                    data={viewdetail_data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  )
}

export default Leads1
