import React from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Select from "react-select"
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap"
import PieChart from "./PieChart"
import grinningface from "../../../assets/fotos/grinningface.svg"
import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
import Neutralface from "../../../assets/fotos/neutralface.svg"
import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"

function Graphicalreport() {
  const customStyles = {
    lineHeight: "1.8",
  }

  return (
    <div>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Question wise report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="4">
                        <div className="mb-3">
                          <Label>Survey Form</Label>
                          <Select
                            style={customStyles}
                            name="Surveyform"
                            options={[
                              {
                                label: "Maha Cyber Jaagarukta Diwas",
                                value: 0,
                              },
                            ]}
                            type="text"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From date</Label>
                          <AvField
                            style={customStyles}
                            name="Surveytitle"
                            className="form-control"
                            type="date"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>To date</Label>
                          <AvField
                            style={customStyles}
                            name="Surveytitle"
                            className="form-control"
                            type="date"
                            placeholder=""
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      {/* <Col md="4">
                        <div className="mb-3">
                          <Label>State</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Zone</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            options={[
                              {
                                label: "kerala",
                                value: 0,
                              },
                              {
                                label: "karnataka",
                                value: 0,
                              },
                            ]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col> */}

                      <Col md="3">
                        <div className="mb-3" style={{ paddingTop: "28px" }}>
                          <Button
                            color="primary"
                            style={{ marginRight: "10px", padding: "8px 30px" }}
                            type="submit"
                          >
                            Filter
                          </Button>

                          <Button
                            style={{ padding: "8px 30px" }}
                            color="danger"
                            type="submit"
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <h6>Survey Form : Maha Cyber Jaagarukta Diwas</h6>
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>
                          How can senior citizens Enhance their password
                          Security?
                        </p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                {/* <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span> */}
                                Use the same password for all accounts
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                {/* <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span> */}
                                share passwords with family members
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                {/* <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span> */}
                                Create strong unique passwords for each account
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                {/* <span style={{ fontSize: "18px" }}>⭐</span>
                                <span style={{ fontSize: "18px" }}>⭐</span> */}
                                Use Easily guessable password like birthdate
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            {/* <tr>
                              <td style={{ textAlign: "start" }}>
                                <span style={{ fontSize: "18px" }}>⭐</span>
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr> */}
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="5">
                        <p style={{ fontSize: ".9rem" }}>
                          How likely do you rate our staff behaviour ?
                        </p>
                        <table
                          style={{
                            borderRadius: "10px",
                            borderColor: "#80808017",
                          }}
                          className=" table table-bordered"
                        >
                          <tbody>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={grinningface} alt="" /> Grinning face
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={slightlysmile} alt="" /> Slightly
                                smiling face
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={Neutralface} alt="" /> Neutral face
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={confusedface} alt="" /> Confused face
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                            <tr>
                              <td style={{ textAlign: "start" }}>
                                <img src={cryingface} alt="" /> Crying face
                              </td>
                              <td>3000 Votes(33.30%)</td>
                            </tr>
                          </tbody>
                        </table>
                      </Col>
                      <Col md="7">
                        <PieChart />
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default Graphicalreport
