import React, { useState } from "react"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap"
import Select from "react-select"
import { MDBDataTable } from "mdbreact"
import grinningface from "../../../assets/fotos/grinningface.svg"
// import slightlysmile from "../../../assets/fotos/slightlysmileface.svg"
// import Neutralface from "../../../assets/fotos/neutralface.svg"
// import confusedface from "../../../assets/fotos/confusedface.svg"
import cryingface from "../../../assets/fotos/cryingface.svg"

// import "./Latestfeedback.scss"

function Latestfeedback() {
  const customStyles = {
    lineHeight: "1.8",
  }

  const [popupView, setPopupView] = useState(false)
  const handleCloseModal = () => setPopupView(false)

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },

      {
        label: "UniqueId",
        field: "uniqueid",
        width: 150,
      },

      {
        label: "Branch",
        field: "Branch",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        width: 150,
      },
      {
        label: "Mobile No.",
        field: "mobile",
        width: 150,
      },

      {
        label: "Action",

        field: "action",
        width: 150,
      },

      // {
      //     label: "Actions",
      //     field: "options",
      //     sort: "desc",
      //     width: 150,
      // },
    ],
    rows: [
      {
        id: "1",
        date: "14-01-2023",
        Time: "12:30pm",
        uniqueid: "FB2093829",
        Branch: "Chathannur",
        name: "Varun Kumar",
        mobile: "985748938",
        email: "Varun@gmail.com",

        action: (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                setPopupView(true)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "2",
        date: "14-01-2023",
        Time: "12:30pm",
        uniqueid: "FB2093829",
        Branch: "Vizhinjam",
        name: "Sravya Ratheesh",
        mobile: "985748938",
        email: "Varun@gmail.com",

        action: (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                setPopupView(true)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
      {
        id: "3",
        date: "14-01-2023",
        Time: "12:30pm",
        uniqueid: "FB2093829",
        Branch: "Chathannur",
        name: "Sreedarsh",
        mobile: "985748938",
        email: "Varun@gmail.com",
        action: (
          <div>
            <i
              className="fas fa-eye"
              onClick={() => {
                setPopupView(true)
              }}
              style={{
                fontSize: "1em",
                cursor: "pointer",
              }}
            ></i>
          </div>
        ),
      },
    ],
  }

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Lastest Survey" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Branch</Label>
                          <Select
                            name="Branch"
                            classNamePrefix="select2-selection"
                            options={[
                              // { label: "Headoffice", value: 0 },
                              { label: "VIZHINJAM", value: 1 },
                              { label: "KAZHAKKOOTTAM", value: 2 },
                              { label: "KULAKKADA", value: 3 },
                              { label: "KUNNAMTHANAM", value: 4 },
                              { label: "PAMPADY", value: 5 },
                              { label: "PERUMBAVOOR", value: 6 },
                              { label: "LAKKIDI", value: 4 },
                              { label: "KAZHAKKOOTTAM", value: 4 },
                              { label: "CHATHANNUR", value: 4 },
                              { label: "CHERIYA KALAVOOR", value: 4 },
                              { label: "KALAMSSERY", value: 4 },
                              { label: "KASARGOD", value: 4 },
                              { label: "KUNNAMKULAM", value: 4 },
                              { label: "MANANTHAVADY", value: 4 },
                              { label: "PALAYAD", value: 4 },
                              { label: "PANDIKKAD", value: 4 },
                              { label: "PERUMBAVOOR", value: 4 },
                              { label: "THAVANUR", value: 4 },
                              { label: "KIHRD NORTH PARAVOOR", value: 4 },
                            ]}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Survey Name</Label>
                          <Select
                            name="feedbackform"
                            options={[
                              {
                                label: "we'd love to hear from you",
                                value: 0,
                              },
                            ]}
                            type="text"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>From</Label>
                          <AvField
                            style={customStyles}
                            name="Document"
                            className="form-control"
                            type="date"
                            placeholder="Enter Documents"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label> To</Label>
                          <AvField
                            style={customStyles}
                            name="Document"
                            className="form-control"
                            type="date"
                            placeholder="Enter Documents"
                            errorMessage="Enter Documents"
                          />
                        </div>
                      </Col>
                      <Col>
                        <div className="mb-3" style={{ paddingTop: "25px" }}>
                          <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "10px" }}
                          >
                            Search
                          </Button>
                          <Button color="danger" type="submit">
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    id=""
                    bordered
                    responsive
                    searching={true}
                    entries={20}
                    disableRetreatAfterSorting={true}
                    data={data}
                  ></MDBDataTable>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
        className="lead-modal"
      >
        <div className="modal-header">
          <div className="modal-title">
            <h6 style={{ marginBottom: "0px" }} className="modal-title ">
              <h6>Survey Details</h6>
            </h6>
          </div>
          <button
            style={{ marginBottom: "2px" }}
            type="button"
            onClick={handleCloseModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Col md="12">
            <table className="table table-borderd lead-info table-striped">
              <tbody>
                <tr>
                  <td>Date</td>
                  <th>: 14-01-2023</th>
                  <td>Name</td>
                  <th>: Varun Kumar</th>
                </tr>
                <tr>
                  <td>UniqueId</td>
                  <th>: FB2093829</th>
                  <td>CSP</td>
                  <th>: CSP KANNUR</th>
                </tr>
                <tr>
                  <td>Mobile</td>
                  <th>: 985748938</th>
                  <td>Email</td>
                  <th>: Varun@gmail.com</th>
                </tr>
              </tbody>
            </table>
          </Col>

          <div>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: "120px", textAlign: "left" }}>Type</th>
                  <th style={{ width: "320px", textAlign: "left" }}>
                    Question
                  </th>

                  <th style={{ textAlign: "left" }}>Answer</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "left" }}>Rating</td>
                  <td style={{ textAlign: "left" }}>
                    How do you rate the overall experience
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <span style={{ fontSize: "15px" }}>⭐</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>
                    How likely do you rate our staff
                  </td>
                  <td style={{ textAlign: "left" }}>
                    <img src={cryingface} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Emoji Rating</td>
                  <td style={{ textAlign: "left" }}>
                    how do you feel about the class
                  </td>
                  <td style={{ textAlign: "left" }}>
                    {" "}
                    <img src={grinningface} alt="" />
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Yes/No</td>
                  <td style={{ textAlign: "left" }}>
                    would you recommend BOM to your friends
                  </td>
                  <td style={{ textAlign: "left" }}>yes</td>
                </tr>
                <tr>
                  <td style={{ textAlign: "left" }}>Fill in the blank</td>
                  <td style={{ textAlign: "left" }}>
                    Please provide your suggestions
                  </td>
                  <td style={{ textAlign: "left" }}>very friendly Staffs</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Latestfeedback
