import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import withRouter from "../../components/Common/withRouter"

import { logoutUser } from "../../store/actions"
import { getMenuSuccessful } from "../../store/menu/actions"
import { useLocation, useNavigate } from "react-router-dom"
import { APP_NAME } from "../../config"

const Logout = () => {
  document.title = ` Logout | ${APP_NAME} `

  const history = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  useEffect(() => {
    dispatch(logoutUser(history, location?.state?.refreshToken || false))
    dispatch(getMenuSuccessful({ data: null }))
    // eslint-disable-next-line
  }, [dispatch, history, location])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
}

export default withRouter(connect(null, { logoutUser })(Logout))
