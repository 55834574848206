import React, { useContext, useEffect, useState } from 'react'
import playstore from '../../../assets/fotos/Group.svg'
import google from '../../../assets/fotos/Group 8.svg'
import apple from '../../../assets/fotos/_Group_3.svg'
import business from '../../../assets/fotos/Group 31.svg'
import bing from '../../../assets/fotos/Group 35.svg'
import comment from '../../../assets/fotos/Group 36.svg'
import star from '../../../assets/fotos/Group 37.svg'

import { Button } from "reactstrap";

import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom'

// import { ReviewsContext } from './Reviews'
function Reviewbar(props) {

    const [open, setOpen] = useState(1)
    // const { setMain } = useContext(ReviewsContext);
    // const { reviews } = props; 

    // const [currentPage, setCurrentPage] = useState(1);
    // const reviewsPerPage = 5; // Display 5 reviews per page
    // const totalReviews = reviews?.reviews?.length || 0;
    // const totalPages = Math.ceil(totalReviews / reviewsPerPage);

    // const getPageNumbers = () => {
    //     const pageNumbers = [];
    //     const maxPagesToShow = 5;

    //     const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    //     const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    //     for (let i = startPage; i <= endPage; i++) {
    //         pageNumbers.push(i);
    //     }

    //     return pageNumbers;
    // };

    // const currentReviews = reviews?.reviews?.slice(
    //     (currentPage - 1) * reviewsPerPage,
    //     currentPage * reviewsPerPage
    // );

    function openClick(num) {
        setOpen(num);
        // props.setMain(num);
    }

    // const handlePaginationChange = (pageNumber) => {
    //     setCurrentPage(pageNumber);
    //     props.setMain(open);
    // };
 


    return (
        <div>


            <div className='reviews'>
                <ul className='platforms'  >
 
                    <li >
                        <div>
                            <span className='fa fa-globe'></span>
                            <span className='mainfont'>All</span>

                            <ul className='ikon'>    

                                <li>
                                    <img className='subikon' src={comment} alt="" /> <span> {props.reviews.totalReviewCount}</span>
                                </li>
                                <li><img className='subikon' src={star} alt="" />   <span>  {props.reviews.averageRating}</span>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li >
                        <div >


                            <span className='fa'><img style={{ height: "20px" }} src={google} alt="" /></span>
                            <span className='mainfont'>Google Reviews</span>


                            <ul className='ikon'>

                                <li>
                                    <img className='subikon' src={comment} alt="" /> {props.reviews.totalReviewCount}
                                </li>
                                <li><img className='subikon' src={star} alt="" />   {props.reviews.averageRating}
                                </li>
                            </ul>


                        </div>
                    </li>

                    <li >
                        <div >
                            <span className='fa'><img style={{ height: "20px" }} src={playstore} alt="" /></span>
                            <span className='mainfont'>Play Store</span>


                            <ul className='ikon'>

                                <li>
                                    <img className='subikon' src={comment} alt="" /> 371
                                </li>
                                <li><img className='subikon' src={star} alt="" /> 4.9
                                </li>
                            </ul>


                        </div>
                    </li>

                    <li >
                        <div>
                            <span className='fa'><img style={{ height: "20px" }} src={apple} alt="" /></span>
                            <span className='mainfont'>App Store</span>


                            <ul className='ikon'>

                                <li>
                                    <img className='subikon' src={comment} alt="" /> 371
                                </li>
                                <li><img className='subikon' src={star} alt="" /> 4.9
                                </li>
                            </ul>


                        </div>
                    </li>

                    <li >
                        <div>
                            <span className='fa'><img style={{ height: "20px" }} src={business} alt="" /></span>
                            <span className='mainfont'>Google Business</span>


                            <ul className='ikon'>

                                <li>
                                    <img className='subikon' src={comment} alt="" /> 371
                                </li>
                                <li><img className='subikon' src={star} alt="" /> 4.9
                                </li>
                            </ul>


                        </div>
                    </li>

                    <li >
                        <div>
                            <span className='fa'><img style={{ height: "20px" }} src={bing} alt="" /></span>
                            <span className='mainfont'>Bing</span>


                            <ul className='ikon'>

                                <li>
                                    <img className='subikon' src={comment} alt="" /> 371
                                </li>
                                <li><img className='subikon' src={star} alt="" /> 4.9
                                </li>
                            </ul>
 
   
                        </div> 
                    </li>






                </ul>







                <div className='reviewbar '>

                    <Dropdown className='dropdown0'>
                        <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                            <span class="fa fa-angle-down"></span> Recent First
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <div class="input-group" style={{ width: "auto" }}>
                        <input type="text" className='form-control' placeholder='Search For Reviews.' />
                        <Button color="info" type="submit" style={{ backgroundColor: "#1a71b5" }}>
                            Search
                        </Button>
                    </div>

          

           
                    {/* <div className='revnextpage'>
                        <div className="pagination">
                            <Link to="#" onClick={() => handlePaginationChange(Math.max(currentPage - 1, 1))}>&laquo;</Link>
                            {getPageNumbers().map((pageNumber) => (
                                <Link
                                    to="#"
                                    key={pageNumber}
                                    onClick={() => handlePaginationChange(pageNumber)}
                                    className={`pagination-link ${currentPage === pageNumber ? 'active' : ''}`}
                                >
                                    {pageNumber}
                                </Link>
                            ))}
                            <Link to="#" onClick={() => handlePaginationChange(Math.min(currentPage + 1, totalPages))}>&raquo;</Link>
                        </div>
                    </div>

                    <div className='revpagecontent'>
                        Page <div>{currentPage}</div> of {totalPages}
                    </div> */}
                    <div className='revpagelist' >
                        <div>Show items</div>

                        <Dropdown className=''>
                            <Dropdown.Toggle className='Dropdown1' style={{ backgroundColor: "white", color: "#1a71b5" }} variant="info" id="dropdown-basic">
                                <span class="fa fa-angle-down"></span> 0
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>


                    </div>


                </div>







            </div>
        </div>

    )
}

export default Reviewbar