import { takeEvery, put, call } from "redux-saga/effects"

//Account Redux states
import { GET_MENU } from "./actionTypes"
import { getMenuSuccessful, getMenuFailed } from "./actions"

//Include Both Helper File with needed methods
import { getMenu } from "../../helpers/backend_helper"

// Is user register successfull then direct plot user in redux.
function* fetchMenu({ payload }) {
  try {
    const response = yield call(getMenu, payload.user, payload.module)
    yield put(getMenuSuccessful(response))
  } catch (error) {
    yield put(getMenuFailed(error))
  }
}
function* moduleSaga() {
  yield takeEvery(GET_MENU, fetchMenu)
}

export default moduleSaga
