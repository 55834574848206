import moment from "moment"
import { APP_NAME, PRIVILEGES } from "../config"

import * as XLSX from "xlsx"

import _ from "lodash"

export const setBrowserToken = token => {
  localStorage.setItem("browserToken", token)
}

export const jsonToExcel = (
  jsonArray,
  sheetName = moment().format("DDMMYYhhmmss")
) => {
  // Create a worksheet
  const ws = XLSX.utils.json_to_sheet(jsonArray)

  // Create a workbook
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, sheetName)

  // Save to a file
  XLSX.writeFile(wb, `${sheetName}.xlsx`)
}

export const getFirstday = date =>
  moment().startOf("month").format("YYYY-MM-DD")

export const getDate = () => moment().format("YYYY-MM-DD")
export const timeSince = (date, now_date) => {
  var seconds = Math.floor((now_date - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " yrs"
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " months"
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " hrs"
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " min"
  }
  return Math.floor(seconds) + " sec"
}