import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Bar, Pie, Line } from "react-chartjs-2"
import accessToken from "../../../helpers/jwt-token-access/accessToken"
import axios from "axios"
import moment from "moment"
import PieChart from "./PieChart"
import MiniWidget from "./mini-widget"
import LineApexChart from "./chartapex"

const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/"

function VolumeSummary() {
  const [volumeCount, setVolumeCount] = useState()
  const [volumeCountTotal, setVolumeCountTotal] = useState()
  const [volumeCountBySocialMedia, setVolumeCountBySocialMedia] = useState()

  function fetchVolumeCount() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/volume/count?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setVolumeCount(result)
      })
  }

  function fetchVolumeCountTotal() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/volume/countTotal?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data
        setVolumeCountTotal(result)
      })
  }

  function fetchVolumeCountBySocialMedia() {
    let fromDate = moment(startDate).format("YYYY-MM-DD")
    let ToDate = moment(endDate).format("YYYY-MM-DD")
    var url = `${API_URL}report/v2/volume/countByDate?fromDate=${fromDate}&toDate=${ToDate}`
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then(res => {
        let result = res?.data?.data
        setVolumeCountBySocialMedia(result)
      })
  }

  useEffect(() => {
    fetchVolumeCount()
    fetchVolumeCountTotal()
    fetchVolumeCountBySocialMedia()
  }, [])

  // date
  const formatDate = (date, fullMonth = false) => {
    const options = fullMonth
      ? {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }
      : {
          day: "numeric",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        }

    return new Date(date).toLocaleString("en-US", options)
  }

  const [startDate, setStartDate] = useState(() =>
    new Date().setDate(new Date().getDate() - 30)
  )
  const [endDate, setEndDate] = useState(new Date())
  const [showFullMonth, setShowFullMonth] = useState(false)

  const toggleShowFullMonth = () => {
    setShowFullMonth(!showFullMonth)
  }

  // bar chart1
  const options1 = {
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true, // Set this to false if you don't want the y-axis to always start at zero
        },
        scaleLabel: {
          display: true,
          labelString: "Volume",
        },
      },
    },
  }

  const backgroundColors = [
    "#5E9DE6",
    "#46CFB0",
    "#052c65",
    "rgb(151,120,209)",
    "#e91e63",
    "#009688",
    "#f1b44c",
    "#50a5f1",
    "rgb(202,248,128)",
    "rgb(255,206,236)",
  ]

  const data1 = {
    labels: ["Kial"],

    datasets: volumeCount?.map((item, index) => ({
      label: item.label,
      data: item.data,
      backgroundColor: backgroundColors[index],
      stack: "Stack 0",
      barPercentage: "0.5",
    })),
  }

  // pie chart1
  const pie1 = {
    labels: ["Kial"],
    datasets: [
      {
        label: "# of Votes",
        data: [volumeCount?.reduce((total, item) => total + item.data[0], 0)],
        backgroundColor: ["#4FC2E5"],
        borderColor: ["#4FC2E5"],
        borderWidth: 1,
      },
    ],
  }

  // line chart1
  const lineData1 = {
    labels: volumeCountTotal?.labels,
    datasets: [
      {
        label: "Kial",
        data: volumeCountTotal?.data,
        fill: false,
        borderColor: "#742774",
      },
    ],
  }

  const lineOption1 = {
    scales: {
      xAxes: [
        {
          barPercentage: 0.1,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true, // Start the y-axis at -500
            // max: 200,
            stepSize: 50, // Set the interval between y-axis ticks
          },
        },
      ],
    },
  }

  const series11 = [70]

  const options11 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }

  const series12 = [70]

  const options12 = {
    fill: {
      colors: ["#34c38f"],
    },
    chart: {
      sparkline: {
        enabled: !0,
      },
    },
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: !1,
        },
      },
    },
  }
  const reports = [
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: "Total",
      value: 198,
      prefix: "",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series11,
      options: options11,
    },
    {
      id: 1,
      icon: "mdi mdi-arrow-up-bold",
      title: " Facebook",
      value: 97,
      prefix: "",
      suffix: "",
      badgeValue: "2.65%",
      decimal: 0,
      charttype: "radialBar",
      chartheight: 40,
      chartwidth: 70,
      color: "success",
      desc: "since last week",
      series: series11,
      options: options11,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Twitter",
      value: 521,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series12,
      options: options12,
    },
    {
      id: 2,
      icon: "mdi mdi-arrow-down-bold",
      title: "Instagram",
      value: 521,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: "0.82%",
      color: "danger",
      desc: "since last week",
      series: series12,
      options: options12,
    },
  ]
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div>
            <p className="titletit">Volume Summary</p>
            <h6 className="dateText" onClick={toggleShowFullMonth}>
              {formatDate(startDate, showFullMonth)} -{" "}
              {formatDate(endDate, showFullMonth)}
            </h6>
          </div>
          <Row>
            <MiniWidget reports={reports} />
          </Row>
          <Row className="d-none" style={{ rowGap: "20px" }}>
            {volumeCount?.map(item => (
              <Col md="3">
                <Card>
                  <CardBody>
                    <div class="contbox">
                      <span class="mainval">{item.data[0]}</span>
                    </div>
                    <div class="hdbox">
                      <h4 class="title1">
                        <i class={"fab icon-dashboard-2"}></i> {item.label}
                      </h4>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>

          <Row>
            <Col xl={6}>
              <Card>
                <CardBody>
                  <div className="float-end">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="text-reset"
                        id="dropdownMenuButton5"
                        caret
                        href="#"
                      >
                        <span className="fw-semibold">Sort By:</span>{" "}
                        <span className="text-muted">
                          Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Monthly</DropdownItem>
                        <DropdownItem href="#">Yearly</DropdownItem>
                        <DropdownItem href="#">Weekly</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                  {/* <h4 className="card-title mb-4">DAily Followers Count</h4> */}
                  <LineApexChart />
                </CardBody>
              </Card>
            </Col>

            {/* Bar chart1 */}
            <Col md="6">
              <Card>
                <CardBody>
                  <div className="float-end">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="text-reset"
                          id="dropdownMenuButton5"
                          caret
                          href="#"
                        >
                          <span className="fw-semibold">Sort By:</span>{" "}
                          <span className="text-muted">
                            Yearly<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Monthly</DropdownItem>
                          <DropdownItem href="#">Yearly</DropdownItem>
                          <DropdownItem href="#">Weekly</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  <h5>Brand Volume by Source</h5>
                  <Bar options={options1} data={data1} />
                </CardBody>
              </Card>
            </Col>

            {/* pie chart 1 */}
            <Col md="6" className="d-none">
              <Card>
                <CardBody>
                  <h5>Share of Voice</h5>
                  <div style={{ height: "400", width: "600" }}>
                    <Pie data={pie1} />
                  </div>
                </CardBody>
              </Card>
            </Col>

            {/* Line chart1 */}
            <Col md="12" className="d-none">
              <Card>
                <CardBody>
                  <h5>Total Volume</h5>
                  <Line
                    width={474}
                    height={100}
                    data={lineData1}
                    options={lineOption1}
                  />
                </CardBody>
              </Card>
            </Col>

            {volumeCountBySocialMedia?.map(item => (
              <Col md="12" className="d-none">
                <Card>
                  <CardBody>
                    <h5>{item.socialMedia} Volume</h5>
                    <Line
                      width={474}
                      height={100}
                      data={{
                        labels: item.labels,
                        datasets: [
                          {
                            label: "Kial",
                            data: item.data,
                            fill: false,
                            borderColor: "#742774",
                          },
                        ],
                      }}
                      options={{
                        scales: {
                          xAxes: [
                            {
                              barPercentage: 0.1,
                            },
                          ],
                          yAxes: [
                            {
                              ticks: {
                                beginAtZero: true, // Start the y-axis at -500
                                // max: 80,
                                stepSize: 20, // Set the interval between y-axis ticks
                              },
                            },
                          ],
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </>
  )
}

export default VolumeSummary
