import React from "react"
import ReactApexChart from "react-apexcharts"

const PieChart = ({ answers, type }) => {
  const series = answers
  const options = {
    labels:
      type === 0 ? ["Yes", "No"] : ["Excellent", "Best", "Good", "Average", "Need Improvement"],
    colors: ["#34c38f", "#5b73e8", "#f1b44c", "#50a5f1", "#f46a6a"],
    legend: {
      show: !0,
      position: "right",
      horizontalAlign: "right",
      verticalAlign: "middle",
      floating: !1,
      fontSize: "14px",
      offsetX: 0,
      itemMargin: {
        horizontal: 0, // Adjust the horizontal gap between labels
        vertical: 15, // Adjust the vertical gap between labels
      },
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: !1,
          },
        },
      },
    ],
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height="280"
      className="apex-charts"
    />
  )
}

export default PieChart
