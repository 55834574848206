import React, { useEffect, useRef, useState } from 'react'
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col } from "reactstrap";
import "./Messages.scss"
import srvlogo from '../../../assets/fotos/srv11.png'
import facebook from '../../../assets/fotos/f_logo_RGB-Blue_1024.png'
import whatsapp from '../../../assets/fotos/whatsapp-logo-1.png'
import twitter from '../../../assets/fotos/twitter-transparent-logo-social-media.png'
 
function Messages() { 
    const [open1, setOpen1] = useState(0)


    function chatClick(num) {
        setOpen1(num)

    }
    useEffect(() => {
       
        setOpen1(1);
      }, []);

      const chatboxRef = useRef(null);

      
    const [inputValue, setInputValue] = useState('');
    const [chatItems, setChatItems] = useState([

        // { type: 'me', message: 'hi Varun how are you??????????', timestamp: '16mins ago' },
        // { type: 'other', message: 'great here!', timestamp: '16mins ago' },


    ]);
    const chatContainerRef = useRef(null);

    const handleSendClick = () => {
        if (inputValue.trim() !== '') {
            const newChatItem = {
                type: 'me',
                message: inputValue,
                timestamp: 'just now',
            };
            setChatItems([...chatItems, newChatItem]);
            setInputValue('');
            // toTop()
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendClick();
        }
    };

    useEffect(() => {
        // Scroll to the bottom when chat items change
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatItems.length]);

    return (
        <>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumbs title="Home" breadcrumbItem="Media Tool" />
                    <div className='main_chatbody'>
                        <div className='main_chatlist'>
                            <Row>
                                <Col xl="12">
                                    <Row>
                                        <div className="inbox-categories">
                                            <Row className='inboxitem'>
                                                <Col xl="2">
                                                    <div
                                                        className="active-all"
                                                        data-toggle="tab"
                                                        data-target="#all"
                                                    >
                                                        {" "}
                                                        All{" "}
                                                    </div>
                                                </Col>
                                                <Col xl="2">
                                                    <div
                                                        className="whatsapp"
                                                        data-toggle="tab"
                                                        data-target="#whatsapp"
                                                    >
                                                        {" "}
                                                        <span className="fab fa-whatsapp"></span>{" "}
                                                    </div>
                                                </Col>
                                                <Col xl="2">
                                                    <div
                                                        className="facebook"
                                                        data-toggle="tab"
                                                        data-target="#facebook"
                                                    >
                                                        {" "}
                                                        <span className="fab fa-facebook"></span>{" "}
                                                    </div>
                                                </Col>
                                                <Col xl="2">
                                                    <div
                                                        className="instagram"
                                                        data-toggle="tab"
                                                        data-target="#instagram"
                                                    >
                                                        {" "}
                                                        <span className="fab fa-instagram"></span>{" "}
                                                    </div>
                                                </Col>
                                                <Col xl="2">
                                                    <div
                                                        className="twitter"
                                                        data-toggle="tab"
                                                        data-target="#twitter"
                                                    >
                                                        {" "}
                                                        <span className="fab fa-twitter"></span>{" "}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>

                            <div className="chatList__search">
                                <div className="search_wrap">
                                    <input type="text" placeholder="Search Here" required />
                                    <button className="search-btn">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </div>
                            </div>


                            <div className='chatlist_items'>  

                                <div
                                    onClick={() => chatClick(1)}

                                    className={`chatlist_item ${open1 === 1 ? "active" : ""}`}>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                            <img src={facebook} alt="#" /> 
                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>Varun das</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>

                                <div
                                    onClick={() => chatClick(2)}

                                    className={`chatlist_item ${open1 === 2 ? "active" : ""}`}>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                        <img src={whatsapp} alt="#" />                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>sreedarsh</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>
                                <div onClick={() => chatClick(3)}

                                    className={`chatlist_item ${open1 === 3 ? "active" : ""}`}>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                        <img src={twitter} alt="#" />                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>Sam</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>
                                <div onClick={() => chatClick(4)}

                                    className={`chatlist_item ${open1 === 4 ? "active" : ""}`}>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                        <img src={srvlogo} alt="#" />                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>Sujith</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>
                                <div onClick={() => chatClick(5)}

                                    className={`chatlist_item ${open1 === 5 ? "active" : ""}`}>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                        <img src={srvlogo} alt="#" />                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>Jamsheer</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>
                                <div className='chatlist_item'>
                                    <div className='avatar'>
                                        <div className='avatar_img'>

                                        <img src={srvlogo} alt="#" />                                        </div>
                                        <span className='isOnline active'></span>

                                    </div>

                                    <div className='userMeta'>

                                        <p>Leo</p>
                                        <span className='activeTime'>32 mins ago</span>

                                    </div>

                                </div>




                            </div>





                        </div>







                        {open1 === 1 ? (
                            <div className='main_chatcontent'>

                                <div className='content_header'>
                                    <div className='blocks'>
                                        <div className='current-chatting-user'>

                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={facebook} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>
                                            <p>Varun das</p>
                                        </div>
                                    </div>

                                    <div className='blocks'>
                                        <div className='settings'>
                                            
                                                <i style={{fontSize:'20px', cursor:"pointer"}} className="fas fa-info-circle"></i>
                                            
                                        </div>
                                    </div>
                                </div>


                                <div className='content_body' ref={chatContainerRef}>
                                    <div className='chat_items'>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> hi Varun how are you?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> great here!</div>
                                                <div className='chat_metame'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={facebook} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>
                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> How's it going with your latest project?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo}alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>It's going well, thanks, but I may need your help tomorrow evening. Will you be available ?</div>
                                                <div className='chat_metame'>
                                                    <span className='time'>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src="	https://1.bp.blogspot.com/-S8HTBQqmfcs/XN0ACIRD9PI/AAAAAAAAAlo/FLhccuLdMfIFLhocRjWqsr9cVGdTN_8sgCPcBGAYYCw/s1600/f_logo_RGB-Blue_1024.png" alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>Of course, just call me before that, in case I forget.</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>
                                                <img src={srvlogo}alt="#" />
                                                    {/* <img src="	https://i.postimg.cc/rpBKb3B6/srv.png" alt="#" /> */}
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>
{chatItems.map((item, index) => (

                                            <div className='chat_item me'>
                                                <div className='chat_item_content'>
                                                    <div className='chat_msg'>{item.message}</div>
                                                    <div className='chat_meta'>
                                                        {/* <span>16mins ago</span> */}
                                                        <span>{item.timestamp}</span>
                                                    </div>

                                                </div>
                                                <div className='avatar'>
                                                    <div className='avatar_img'>

                                                        <img src={item.type === 'me' ? srvlogo : facebook} alt="#" />
                                                    </div>
                                                    <span className='isOnline active'></span>

                                                </div>

                                            </div>
                                        ))}


                                    </div>
                                </div>

                                <div className='content_footer'>
                                    <div className='sendNewMessage'>
                                        <button className='addFilees'><i class="fa fa-plus"></i></button>
                                        <input type="text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                            placeholder="Type a message here" />
                                        <button onClick={handleSendClick} class="btnSendMsg" id="sendMsgBtn"><i class="fa fa-paper-plane"></i></button>
                                    </div>
                                </div>

                            </div>
                        ) : null}


                        {open1 === 2 ? (
                            <div className='main_chatcontent'>

                                <div className='content_header'>
                                    <div className='blocks'>
                                        <div className='current-chatting-user'>

                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={whatsapp} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>
                                            <h5>sreedarsh</h5>
                                        </div>
                                    </div>

                                    <div className='blocks'>
                                        <div className='settings'>
                                            <button className='btn-'>
                                                <i className="fas fa-info-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div className='content_body'>
                                    <div className='chat_items'>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> hi Varun how are you?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> great here!</div>
                                                <div className='chat_metame'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={whatsapp} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>
                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> How's it going with your latest project?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo}alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>It's going well, thanks, but I may need your help tomorrow evening. Will you be available ?</div>
                                                <div className='chat_metame'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={whatsapp} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>Of course, just call me before that, in case I forget.</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo}alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>



                                    </div>
                                </div>

                                <div className='content_footer'>
                                    <div className='sendNewMessage'>
                                        <button className='addFilees'><i class="fa fa-plus"></i></button>
                                        <input type="text" placeholder="Type a message here" value=""></input>
                                        <button class="btnSendMsg" id="sendMsgBtn"><i class="fa fa-paper-plane"></i></button>
                                    </div>
                                </div>

                            </div>
                        ) : null}



                        {open1 === 3 ? (
                            <div className='main_chatcontent'>

                                <div className='content_header'>
                                    <div className='blocks'>
                                        <div className='current-chatting-user'>

                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={twitter} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>
                                            <p>Sam</p>
                                        </div>
                                    </div>

                                    <div className='blocks'>
                                        <div className='settings'>
                                            <button className='btn-'>
                                                <i className="fas fa-info-circle"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div className='content_body'>
                                    <div className='chat_items'>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> hi Varun how are you?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> great here!</div>
                                                <div className='chat_metame'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={twitter} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>
                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'> How's it going with your latest project?</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item other'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>It's going well, thanks, but I may need your help tomorrow evening. Will you be available ?</div>
                                                <div className='chat_metame'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={twitter} alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>

                                        <div className='chat_item me'>
                                            <div className='chat_item_content'>
                                                <div className='chat_msg'>Of course, just call me before that, in case I forget.</div>
                                                <div className='chat_meta'>
                                                    <span>16mins ago</span>
                                                    <span>seen 1.03pm</span>
                                                </div>

                                            </div>
                                            <div className='avatar'>
                                                <div className='avatar_img'>

                                                    <img src={srvlogo}alt="#" />
                                                </div>
                                                <span className='isOnline active'></span>

                                            </div>

                                        </div>



                                    </div>
                                </div>

                                <div className='content_footer'>
                                    <div className='sendNewMessage'>
                                        <button className='addFilees'><i class="fa fa-plus"></i></button>
                                        <input type="text" placeholder="Type a message here" value=""></input>
                                        <button class="btnSendMsg" id="sendMsgBtn"><i class="fa fa-paper-plane"></i></button>
                                    </div>
                                </div>

                            </div>
                        ) : null}






                    </div>





                </div>
            </div>
        </>
    )
}

export default Messages